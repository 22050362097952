import { useRef, useEffect } from "react";

import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/i18n/ko-kr";
import axios from "axios";
import { useAxiosWithAuthorization } from "../../utils/api";

const EditorBox = (props) => {
  const editorRef = useRef();
  console.log(props?.initialValue);
  const onChange = () => {
    const data = editorRef.current.getInstance().getMarkdown();
    console.log(data);
  };

  //파일 저장 URL
  const SAVE_UPLOADFILE_URL = "/file/uploadEditorFile.do";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SAVE_UPLOADFILE_URL,
    },
    { manual: true }
  );

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    editorRef.current.getInstance().setMarkdown(props?.data);
  }, [props?.data]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().removeHook("addImageBlobHook");
      editorRef.current
        .getInstance()
        .addHook("addImageBlobHook", (blob, callback) => {
          (async () => {
            let formData = new FormData();
            formData.append("file", blob);

            axios.defaults.withCredentials = true;
            const { data: data } = await fetchData({
              url: SAVE_UPLOADFILE_URL,
              header: { "content-type": "multipart/formdata" },
              data: formData,
              method: "POST",
            });

            console.log(data.url + ".do");

            callback(data.url + ".do");
          })();

          return false;
        });
    }

    return () => {};
  }, [editorRef]);

  return (
    <div>
      <Editor
        height={props.height}
        name={props.name}
        id={props.id}
        // /prviewStyle="vertical"
        initialValue={props.data}
        // data={props.data}
        initialEditType={props.initialEditType}
        usageStatistics={false}
        disabled={props.disabled}
        ref={editorRef}
        hideModeSwitch={true}
        language={"ko-KR"}
        onBlur={(event) => {
          props.onChange({
            target: {
              value: editorRef.current.getInstance().getHTML(),
              name: props.name,
              type: "textarea",
            },
          });
        }}
      />
    </div>
  );
};
export default EditorBox;
