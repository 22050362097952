import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <Spinner
      animation="border"
      role="status"
      variant="primary"
      style={{
        display: "inherit",
        margin: "10px auto",
      }}
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
};

export default Loading;
