import React, { useState, useCallback, useEffect, useRef } from "react";
import SearchBar from "./SearchBar";
import ButtonBar from "../../../components/Layout/ButtonBar";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./conlumnLayout";
import useAppContext from "../../../utils/Reducers";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import moment from "moment";

const UseHistory = (props) => {
  const myGrid = useRef();

  const [state, setState] = useState("normaled");

  const {
    store: { userInfo, menuKey },
  } = useAppContext();

  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");
  }, [menuKey]);

  const initItem = {
    logSeq: "",
    userId: "",
    nmKor: "",
    menuId: "",
    menuNmKor: "",
    useHisCntn: "",
    inptDt: "",
  };
  const [fieldValues, setFieldValues] = useState(initItem);

  //상차지 검색 URL
  const SERARCH_USEHISTORY_URL =
    "/resultinquery/usehistory/searchUseHistoryList";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_USEHISTORY_URL,
    },
    { manual: true }
  );

  const [primaryParameters, setPrimaryParameters] = useState({
    userId: "",
    useHisCntn: "",
    startDt: moment().format("YYYY-MM-DD"),
    endDt: moment().format("YYYY-MM-DD"),
  });

  //검색조건
  const setSearchParameters = (values) => {
    console.log(values.schSwgtDt);
    setPrimaryParameters({
      ...primaryParameters,
      userId: values.userId,
      useHisCntn: values.useHisCntn,
      startDt: values.startDt,
      endDt: values.endDt,
    });
  };

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.bind("cellClick", function (event) {
      gridCellClick(event.item);
    });

    //
  }, []);

  const gridCellClick = (item) => {
    // 추가 : editable, 수정 : edtiable, 삭제 : readonly
    // read
    // setFieldsEnabled(auiGrid, item, disabledFields);
    console.log(item);
    setFieldValues(item);
  };

  const handleMasterSearch = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_USEHISTORY_URL,
      params: primaryParameters,
    });
    auiGrid.setGridData(data);
    setFieldValues(initItem);
    auiGrid.removeAjaxLoader();
  };

  const handleChangeField = useCallback((event) => {
    const auiGrid = myGrid.current;
    const { name, value } = event.target;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;
    console.log("name === " + name + " || value === " + value);

    item[name] = value;
    setFieldValues({ ...fieldValues, [name]: value });

    auiGrid.updateRow(item, "selectedIndex");
  });

  const handleMasterExcel = useCallback(() => {
    let exceptColumnFields = [];
    for (let idx in columnLayout) {
      if (columnLayout[idx].visible === false) {
        exceptColumnFields.push(columnLayout[idx].dataField);
      }
    }

    let today = new Date();
    let todayStr = today.getFullYear();
    todayStr +=
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : "" + (today.getMonth() + 1);
    todayStr +=
      today.getDate() + 1 < 10 ? "0" + today.getDate() : "" + today.getDate();

    const auiGrid = myGrid.current;
    auiGrid.exportToXlsx({
      fileName: props.menuInfo.menuNmKor + " 리스트_" + todayStr,
      exceptColumnFields: exceptColumnFields,
    });
  });

  return (
    <>
      <ButtonBar
        menuInfo={props.menuInfo}
        handleMasterSearch={handleMasterSearch}
        // handleMasterNew={handleMasterNew}
        // handleMasterDelete={handleMasterDelete}
        // handleMasterSave={handleMasterSave}
        handleMasterExcel={handleMasterExcel}
      />
      <SearchBar
        setSearchParameters={setSearchParameters}
        systemCd={userInfo.systemCd}
      />

      <div className="grid_wrap">
        <AUIGrid
          ref={myGrid}
          columnLayout={columnLayout}
          gridProps={defaultGridProps}
        />
      </div>
      <ul className="t_form">
        <li style={{ height: "128px" }}>
          <div className="title">로그내용</div>
          <div className="formbox">
            <textarea
              rows={1}
              type="text"
              id="useHisCntn"
              name="useHisCntn"
              value={fieldValues.useHisCntn}
              onChange={handleChangeField}
              disabled="true"
              className="ip2"
              style={{ height: "100px", verticalAlign: "middle" }}
            />
          </div>
        </li>
      </ul>
    </>
  );
};

export default UseHistory;
