import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";

const Confirm = ({
  onResolve,
  title,
  description,
  labelCancel = "닫기",
  labelOk = "확인",
  type = "confirm",
}) => {
  const [show, setShow] = useState(true);

  const handleClose = (returnData) => {
    onResolve(returnData);
    setShow(false);
  };

  return (
    <>
      <Modal
        className="msg_modal"
        show={show}
        onHide={() => handleClose(false)}
        size="lx"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="msg_modal_content">
            {description?.split("\n").map((line) => {
              return <span>{line}</span>;
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {labelCancel}
          </Button>
          {type === "confirm" && (
            <Button variant="primary" onClick={() => handleClose(true)}>
              {labelOk}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalConfirm = createModal(Confirm);

export default Confirm;
