import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAppContext, { actions } from "../../../utils/Reducers";
import { axiosInstance } from "../../../utils/api";
import useFieldValues from "../../../utils/useFieldValues";
import axios from "axios";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import loginLogo from "../../../images/login_logo.png";
import styled from "styled-components";
import Idcon from "../../../images/icon_id.png";
import Pwcon from "../../../images/icon_pw.png";
import { Input } from "postcss";
const Login = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const LS_KEY_ID = "LS_KEY_ID";
  const LS_KEY_PW = "LS_KEY_PW";
  const LS_KEY_SAVE_ID_FLAG = "LS_KEY_SAVE_ID_FLAG";
  const [saveIDFlag, setSaveIDFlag] = useState(false);

  const INITIAL_LOGIN_FIELDS = {
    userId: "",
    password: "",
  };
  const [loginFields] = useFieldValues(INITIAL_LOGIN_FIELDS);
  const LOGIN_APP_URL = "/accounts/login";

  useEffect(() => {
    let idFlag = JSON.parse(localStorage.getItem(LS_KEY_SAVE_ID_FLAG));
    console.log(idFlag);
    if (idFlag !== null) setSaveIDFlag(idFlag);
    if (idFlag === false) {
      localStorage.setItem(LS_KEY_ID, "");
      localStorage.setItem(LS_KEY_PW, "");
    }
    let dataId = localStorage.getItem(LS_KEY_ID);
    let dataPw = localStorage.getItem(LS_KEY_PW);
    console.log(dataId + " / " + dataPw);
    if (dataId !== null) setUserId(dataId);
    if (dataPw !== null) setPassword(dataPw);
  }, []);

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: LOGIN_APP_URL,
    },
    { manual: true }
  );

  const INITIAL_FIELD_RULES = {
    username: {
      rules: [
        {
          max_length: 30,
          message: "최대 30글자까지 입력 가능합니다.",
        },
      ],
    },
    password: {
      rules: [
        {
          max_length: 50,
          message: "최대 50글자까지 입력 가능합니다.",
        },
      ],
    },
  };
  const { dispatch } = useAppContext();
  const submit = async () => {
    loginFields["userId"] = userId;
    loginFields["password"] = password;

    if (userId === "") {
      alert("아이디를 입력하세요");
      return;
    }

    if (password === "") {
      alert("패스워드를 입력하세요");
      return;
    }

    try {
      const response = await axiosInstance.post(LOGIN_APP_URL, loginFields);

      const {
        data: { token, userInfo, code, result },
      } = response;
      console.log(code);
      console.log(result);
      if (code === "400") {
        // await ModalConfirm({
        //   title: "로그인 실패",
        //   description: result,
        //   type: "alert",
        // });
        alert(result);
        return;
      } else {
        //alert(token)
        dispatch(actions.setToken(token));
        dispatch(actions.setUserInfo(userInfo));

        if (true /* login success */) {
          if (saveIDFlag) localStorage.setItem(LS_KEY_ID, userId);
          if (saveIDFlag) localStorage.setItem(LS_KEY_PW, password);
        }

        navigate("/", { replace: true });
      }
    } catch (error) {
      if (error.response) {
        const { data: errors } = error.response;
        const _responseErrors = [];
        errors.messages &&
          errors.messages.map(({ message }) => {
            Object.entries(message).map(([key, errorMessage]) => {
              _responseErrors.push(errorMessage);
            });
          });

        for (const _responseError of _responseErrors.reverse()) {
          // eslint-disable-next-line no-restricted-globals
          confirm(_responseError);
          return;
        }
      }
    }

    // const {
    //   data: { data },
    // } = await fetchData({
    //   url: LOGIN_APP_URL,
    //   data: loginFields,
    //   method: "POST",
    // });
    // dispatch(actions.setToken(data.token));
    // dispatch(actions.setUserInfo(data.userInfo.userId));

    // navigate("/", { replace: true });
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const handleSaveIDFlag = (event) => {
    if (event.target.checked) {
      localStorage.setItem(LS_KEY_SAVE_ID_FLAG, true);
      setSaveIDFlag(true);
    } else {
      localStorage.setItem(LS_KEY_SAVE_ID_FLAG, false);
      setSaveIDFlag(false);
    }
  };
  return (
    <Form100>
      <LoginBox>
        <p style={{ textAlign: "center" }}>
          <img src={loginLogo} alt="LOGO" />
        </p>
        <MiddleTitle>무인계량시스템</MiddleTitle>
        <InputBox style={{ marginTop: "58px" }}>
          <LoginId
            type="text"
            value={userId}
            onChange={(event) => setUserId(event.target.value)}
          />
          <Tag>아이디</Tag>
        </InputBox>
        <InputBox style={{ marginTop: "12px" }}>
          <LoginPw
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={onKeyPress}
          />
          <Tag>비밀번호</Tag>
        </InputBox>
        <BtnLogin type="button" onClick={submit}>
          로그인
        </BtnLogin>
        <div style={{ marginTop: "20px" }}>
          <SaveAccount
            type="checkbox"
            id="id_save"
            value={password}
            checked={saveIDFlag}
            onChange={handleSaveIDFlag}
          />
          <SaveAccountText htmlFor="id_save" style={{ marginRight: "15px" }}>
            ID/PW 저장
          </SaveAccountText>
        </div>
      </LoginBox>
    </Form100>
  );
};

const Form100 = styled.form`
  background: #f2f5fb;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;
const LoginBox = styled.div`
  background: #ffffff;
  width: 461px;
  height: 608px;
  box-shadow: 10px 10px 20px rgba(109, 153, 162, 0.1);
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding: 84px 32px 0px 32px;
`;
const MiddleTitle = styled.h3`
  margin-top: 12px;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #2e2e2e;
`;
const InputBox = styled.div`
  width: 100%;
  position: relative;
  height: 63px;
`;
const Tag = styled.div`
  position: absolute;
  top: 18px;
  left: 55px;
  font-size: 16px;
  font-weight: 600;
  color: #a1a1a1;
  width: 70px;
  height: 30px;
  border-right: 1px solid rgba(191, 191, 191, 0.44);
`;
const LoginInput = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  padding-left: 150px;
  font-size: 16px;
  font-weight: 600;

  &:focus {
    border: 2px solid #96bcf2;
  }
  $:focus-visible {
    border: 0;
  }
`;
const LoginId = styled(LoginInput)`
  background: url(${Idcon}) 15px center no-repeat;
`;
const LoginPw = styled(LoginInput)`
  background: url(${Pwcon}) 15px center no-repeat;
`;
const BtnLogin = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 63px;
  border-radius: 5px;
  border: 0;
  background: #f8623a;
  font-size: 22px;
  font-weight: 700;
  color: white;
  box-shadow: 6px 6px 6px rgba(193, 80, 30, 0.16);
`;
const SaveAccount = styled.input`
  margin-left: 0;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 9px;
`;
const SaveAccountText = styled.label`
  position: relative;
  font-size: 18px;
  vertical-align: middle;
`;

export default Login;
