import React, { useState, useEffect, useCallback, useRef } from "react";
import SearchBar from "./SearchBar";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import ButtonBar from "../../../components/Layout/ButtonBar";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import {
  createAuthColumnData,
  createMenuColumnData,
  createMenuAuthColumnData,
} from "./conlumnLayout";

import { saveGridCheckData } from "../../../utils/validators";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import useAppContext from "../../../utils/Reducers";

const AuthMenuMng = (props) => {
  const myGrid = useRef();
  const myGridMenuAuth = useRef();
  const myGridMenu = useRef();

  const {
    store: { userInfo, menuKey },
  } = useAppContext();

  useEffect(() => {
    const auiAuthGrid = myGrid.current;
    const auiMenuAuthGrid = myGridMenuAuth.current;
    const auiMenuGrid = myGridMenu.current;

    auiAuthGrid.resize("100%", "100%");
    auiMenuAuthGrid.resize("100%", 300);
    auiMenuGrid.resize("100%", 250);
  }, [menuKey]);

  //그리드 초기 설정
  useEffect(() => {
    const auiMenuAuthGrid = myGridMenuAuth.current;
    const auiAuthGrid = myGrid.current;

    auiAuthGrid.bind("cellClick", function (event) {
      searchAuthMenuList(event.item.authId);
    });
    // 에디팅 시작 이벤트 바인딩
    auiMenuAuthGrid.bind("cellEditBegin", function (event) {
      // 추가된 행 아이템들(배열)은 수정이 가능해야
      if (auiMenuAuthGrid.isAddedById(event.item._$uid)) {
        return true;
      }
      // 디테일 그리드의 디테일코드 편집 불가능
      if (event.menuId === "menuId" || event.menuNmKor === "menuNmKor") {
        return false;
      }
    });

    handleMenuSearch();
  }, []);

  const [primaryParameters, setPrimaryParameters] = useState({
    schAuthId: "",
    schAuthNm: "",
  });
  //권한정보 검색 URL
  const SERARCH_AUTH_URL = "/common/auth/searchAuthList";
  //권한별메뉴정보 검색 URL
  const SERARCH_AUTH_MENU_URL = "/common/auth/searchAuthMenuList";
  //권한별메뉴정보 저장 URL
  const SAVE_AUTH_MENU_URL = "/common/auth/saveAuthMenuList";
  //권한별메뉴정보 삭제 URL
  const DELETE_AUTH_MENU_URL = "/common/auth/deleteAuthMenuList";
  //메뉴정보 검색 URL
  const SERARCH_MENU_URL = "/common/menu/searchMenuList";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_AUTH_URL,
    },
    { manual: true }
  );

  const [menuFieldValues, setMenuFieldValues] = useState({
    menuId: "",
    menuNmKor: "",
  });

  const handleMenuSearch = async () => {
    const auiMenuGrid = myGridMenu.current;
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_MENU_URL,
      params: menuFieldValues,
    });

    auiMenuGrid.setGridData(data);
  };

  const handleMasterSearch = async () => {
    const auiAuthGrid = myGrid.current;
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_AUTH_URL,
      params: primaryParameters,
    });
    auiAuthGrid.setGridData(data);
  };

  const searchAuthMenuList = async (authId) => {
    const auiMenuAuthGrid = myGridMenuAuth.current;
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_AUTH_MENU_URL,
      params: { schAuthId: authId },
    });

    auiMenuAuthGrid.setGridData(data);
  };

  const handleMasterDelete = async () => {
    const auiMenuAuthGrid = myGridMenuAuth.current;
    auiMenuAuthGrid.removeRow("selectedIndex");
  };
  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      const auiAuthGrid = myGrid.current;
      const auiMenuAuthGrid = myGridMenuAuth.current;

      const addItems = auiMenuAuthGrid.getAddedRowItems();
      const editItems = auiMenuAuthGrid.getEditedRowItems();
      const removeItems = auiMenuAuthGrid.getRemovedItems();
      const menuId = menuKey;
      let param = saveGridCheckData(addItems, editItems, removeItems, menuId);

      const {
        data: { data },
      } = await fetchData({
        url: SAVE_AUTH_MENU_URL,
        data: param,
        method: "POST",
      });

      const authItem = auiAuthGrid.getSelectedItems()[0].item;

      searchAuthMenuList(authItem.authId);
    }
  };

  //권한별메뉴 그리드에 검색한 메뉴 추가
  const handleMenuAuthAdd = () => {
    const auiAuthGrid = myGrid.current;
    const auiMenuAuthGrid = myGridMenuAuth.current;
    const auiMenuGrid = myGridMenu.current;

    const menuItem = auiMenuGrid.getSelectedItems()[0].item;
    console.log(menuItem);
    const authItem = auiAuthGrid.getSelectedItems()[0].item;
    console.log(authItem);

    var rows = auiMenuAuthGrid.getRowsByValue("menuId", menuItem.menuId);
    console.log(rows);
    if (rows.length > 0) {
      ModalConfirm({
        title: "메뉴존재",
        description:
          "선택한 메뉴는 그리드에 있습니다. 다시 선택하시기 바랍니다. ",
        type: "alert",
      });
    } else {
      const newData = {
        menuId: menuItem.menuId,
        authId: authItem.authId,
        menuNmKor: menuItem.menuNmKor,
        searchAuthYn: "N",
        saveAuthYn: "N",
        newAuthYn: "N",
        delAuthYn: "N",
        printAuthYn: "N",
        excelAuthYn: "N",
      };
      console.log(newData);
      auiMenuAuthGrid.addRow(newData, "last");
    }
  };

  //메뉴검색 항목이 변경될때 사용되는 함수
  const handleMenuChangeField = useCallback((event) => {
    const { name, value } = event.target;

    setMenuFieldValues({ ...menuFieldValues, [name]: value });
  });

  return (
    <>
      <ButtonBar
        menuInfo={props.menuInfo}
        handleMasterSearch={handleMasterSearch}
        handleMasterDelete={handleMasterDelete}
        handleMasterSave={handleMasterSave}
      />
      <SearchBar setSearchParameters={setPrimaryParameters} />

      <div className="grid_wrap col_2">
        <div className="half">
          <AUIGrid
            ref={myGrid}
            columnLayout={createAuthColumnData()}
            gridProps={defaultGridProps}
          />
        </div>
        <div className="half">
          <div className="row_2">
            <AUIGrid
              ref={myGridMenuAuth}
              columnLayout={createMenuAuthColumnData()}
              gridProps={defaultGridProps}
            />
            <div className="searchdata" style={{ marginTop: "10px" }}>
              <ul className="displays">
                <li style={{ width: "180px" }}>
                  <div style={{ width: "80px" }}>
                    <span className="dot">⦁</span>
                    메뉴아이디
                  </div>
                  <input
                    type="text"
                    className="datainput"
                    name="menuId"
                    style={{ width: "100px" }}
                    onChange={handleMenuChangeField}
                  />
                </li>
                <li style={{ width: "180px" }}>
                  <div style={{ width: "80px" }}>
                    <span className="dot">⦁</span> 메뉴명
                  </div>
                  <input
                    type="text"
                    className="datainput"
                    name="menuNmKor"
                    style={{ width: "100px" }}
                    onChange={handleMenuChangeField}
                  />
                </li>
                <li
                  style={{
                    width: "auto",
                    float: "right",
                    paddingRight: "10px",
                  }}
                >
                  <button
                    type="button"
                    className="btn_greystyle"
                    onClick={handleMenuSearch}
                  >
                    검색
                  </button>
                  <button
                    type="button"
                    className="btn_greystyle"
                    onClick={handleMenuAuthAdd}
                    style={{ marginLeft: "5px" }}
                  >
                    추가
                  </button>
                </li>
              </ul>
            </div>
            <AUIGrid
              ref={myGridMenu}
              columnLayout={createMenuColumnData()}
              gridProps={defaultGridProps}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthMenuMng;
