import React, { useState, useCallback, useEffect } from "react";
import { useAxiosWithAuthorization } from "../../../../utils/api";
import osq from "../../../../images/osq.png";
import download_file from "../../../../images/download_file.png";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import { API_HOST } from "../../../../utils/Constants";

const BoardView = (props) => {
  const SERARCH_BOARDINFO_URL = "/common/board/searchBoardInfo.do";
  const SEARCH_BOARD_FILELIST_URL = "/common/board/searchBoardFileList.do";

  const [primaryParameters, setPrimaryParameters] = useState({
    boardId: props.boardId,
  });

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_BOARDINFO_URL,
    },
    { manual: true }
  );

  const initItem = {
    boardTitle: "",
    boardContent: "",
    regNm: "",
    regDt: "",
  };

  const [fieldValues, setFieldValues] = useState(initItem);
  const [fileList, setFileList] = useState([]);
  var eviewer;
  const searchBoardInfo = async () => {
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_BOARDINFO_URL,
      params: primaryParameters,
    });

    setFieldValues(data[0]);
    eviewer.setMarkdown(data[0].boardContent);

    const response = await fetchData({
      url: SEARCH_BOARD_FILELIST_URL,
      params: {
        boardId: props.boardId,
      },
    });

    console.log(response.data.data);
    var fList = [];
    response.data.data.forEach((file) => {
      var fileProp = {
        uid: file.fileId,
        name: file.orgFileNm,
        status: "done",
        url: API_HOST + file.url,
      };
      fList.push(fileProp);
    });

    setFileList(fList);
  };

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    console.log(props.boardId);

    //viewer();
    eviewer = new Viewer({
      el: document.querySelector("#viewer"),
      height: "100%",
      initialValue: fieldValues?.boardContent,
    });
    if (props.boardId !== "") {
      searchBoardInfo();
    }
  }, []);

  const handleMasterList = () => {
    props.setStatus("list");
  };

  return (
    <>
      <div className="flexible_button_box">
        <div className="title">
          <h2>
            {props?.menuInfo?.menuNmKor}
            <button type="button" className="page_favorite">
              즐겨찾기
            </button>
          </h2>
        </div>
        <div className="btnbox">
          <ul className="select_db">
            <li>
              <button
                type="button"
                className="btn_greystyle"
                onClick={handleMasterList}
              >
                목록
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="databox">
        <h5>
          <img src={osq} /> &nbsp;게시판정보 &nbsp;
          <br />
        </h5>
        <ul className="t_form board">
          <li>
            <div className="title">
              <span className="red">*</span>게시판 제목
            </div>
            <div className="formbox">{fieldValues.boardTitle}</div>
          </li>
          <li className="doublehalf">
            <div className="title">
              <span className="red">*</span>등록일
            </div>
            <div className="ip_textandbutton">
              <div>{fieldValues.regDt}</div>
            </div>
          </li>
          <li className="doublehalf">
            <div className="title">
              <span className="red">*</span>작성자
            </div>
            <div className="formbox">{fieldValues.regNm}</div>
          </li>
          {fileList.length > 0 ? (
            <li style={{ height: "100%" }}>
              <div className="formfile">
                <strong>첨부파일</strong>{" "}
                <span class="filecount">({fileList && fileList.length}개)</span>
                <div>
                  {fileList &&
                    fileList.map((file, index) => (
                      <div>
                        <a href={file.url} target="_blank" key={index}>
                          <img src={download_file} />
                        </a>
                        {file.name}
                        <span key={index}></span>
                      </div>
                    ))}
                </div>
              </div>
            </li>
          ) : (
            ""
          )}
          <li style={{ height: "100%" }}>
            <div className="formbox">
              <div id="viewer" style={{ height: "100%" }}></div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default BoardView;
