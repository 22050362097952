import React, { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
const { kakao } = window;

const Address = ({ setAddress }) => {
  console.log(1);
  const [show, setShow] = useState(true);

  // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
  const handlePostCode = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(fullAddress, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === kakao.maps.services.Status.OK) {
        var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

        setAddress({
          address: fullAddress,
          lont: result[0].x,
          latt: result[0].y,
        });
      } else {
        setAddress({ address: "", lont: "", latt: "" });
      }
    });

    handleClose(false);
  };

  const postCodeStyle = {
    width: "600px",
    height: "600px",
  };

  const handleClose = (returnData) => {
    setShow(false);
  };

  return (
    <>
      <Modal
        dialogClassName="modal-90w"
        centered
        show={show}
        onHide={() => handleClose(false)}
        size="clg"
      >
        <Modal.Header closeButton id="example-custom-modal-styling-title">
          <Modal.Title>주소검색</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 620 }}>
          <p>
            <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalAddress = createModal(Address);

export default Address;
