import React, { useCallback, useState } from "react";
import SelectBox from "../../../components/SelectBox";
import useAppContext from "../../../utils/Reducers";

const SearchBar = (props) => {
	// SerachBar 기본 필드

	const {
		store: { userInfo },
	} = useAppContext();

	const [initFields, setInitFields] = useState({
		menuId: "",
		menuNmKor: "",
	});

	const handleChangeField = useCallback((event) => {
		const { name, value } = event.target;

		setInitFields({ ...initFields, [name]: value });
		props.setSearchParameters({ ...initFields, [name]: value });
	});

	return (
		<>
			<div className="searchdata">
				<ul>
					<li>
						<div className="divide50">
							<span className="dot">⦁</span> 메뉴아이디
						</div>
						<input
							type="text"
							className="datainput"
							name="menuId"
							onChange={handleChangeField}
							value={initFields.schMenuId}
						/>
					</li>
					<li>
						<div className="divide50">
							<span className="dot">⦁</span> 메뉴명
						</div>
						<input
							type="text"
							className="datainput"
							name="menuNmKor"
							onChange={handleChangeField}
							value={initFields.schMenuNmKor}
						/>
					</li>
				</ul>
			</div>
		</>
	);
};

export default React.memo(SearchBar);
