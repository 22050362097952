import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import SearchBar from "./SearchBar";
import {
  getGridValidateCheck,
  saveGridCheckData,
  isFieldDisabled,
} from "../../../utils/validators";
import ButtonBar from "../../../components/Layout/ButtonBar";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./conlumnLayout";
import { INITIAL_FIELD_RULES } from "./validateFields";
import osq from "../../../images/osq.png";
import SelectBox from "../../../components/SelectBox";
import useAppContext from "../../../utils/Reducers";
import { ajusta } from "../../../utils/validators";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
//import PhoneInput from "react-phone-number-input/input";
import { ModalDeptSearchPop } from "../../Popup/DeptPop";

const UserReg = (props) => {
  const myGrid = useRef();

  const [state, setState] = useState("normaled");

  const {
    store: { userInfo, menuKey },
  } = useAppContext();

  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");
  }, [menuKey]);

  //추가, 수정일때 항목의 disabled 결정
  const disabledFields = {
    created: [],
    updated: ["systemCd", "userId"],
  };

  const initItem = {
    systemCd: "",
    userId: "",
    empNo: "",
    nmKor: "",
    nmEng: "",
    nmChn: "",
    email: "",
    dptCd: "",
    dptNm: "",
    telNo: "",
    mobileNo: "",
    rsofCd: "",
    rsofNm: "",
    plbsCd: "",
    pwd: "",
    pwdChngDt: "",
    gender: "",
    etcoDt: "",
    useYn: "",
  };
  const [fieldValues, setFieldValues] = useState(initItem);

  const [oldValues, setOldValues] = useState({
    dptNm: "",
  });

  //상차지 검색 URL
  const SERARCH_USER_URL = "/common/user/searchUserList";
  //상차지 저장 URL
  const SAVE_USER_URL = "/common/user/saveUserList";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_USER_URL,
    },
    { manual: true }
  );

  const [primaryParameters, setPrimaryParameters] = useState({
    userId: "",
    nmKor: "",
  });

  //검색조건
  const setSearchParameters = (values) => {
    setPrimaryParameters(values);
  };

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.bind("cellClick", function (event) {
      gridCellClick(event.item);
    });

    //
  }, []);

  const updateGridData = (key, value) => {
    const auiGrid = myGrid.current;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;
    item[key] = value;
    auiGrid.updateRow(item, "selectedIndex");
  };

  const gridCellClick = (item) => {
    // 추가 : editable, 수정 : edtiable, 삭제 : readonly
    //read
    //setFieldsEnabled(auiGrid, item, disabledFields);
    const auiGrid = myGrid.current;
    if (auiGrid.isAddedById(item._$uid)) {
      setState("created");
    } else if (auiGrid.isEditedById(item._$uid)) {
      setState("updated");
    } else if (auiGrid.isRemovedById(item._$uid)) {
      setState("deleted");
      //console.log("삭제 하는 항목");
    } else {
      setState("updated");
    }

    let initMap = {};
    for (var name in initItem) {
      initMap[name] = item[name];

      if (item[name] === undefined) {
        initMap[name] = "";
      }
    }

    setFieldValues(initMap);
  };
  const handleMasterSearch = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_USER_URL,
      params: primaryParameters,
    });
    auiGrid.setGridData(data);
    setFieldValues(initItem);
    auiGrid.removeAjaxLoader();
  };

  const handleMasterNew = () => {
    const auiGrid = myGrid.current;
    auiGrid.addRow(initItem, "last");
    setFieldValues(initItem);
    const items = auiGrid.getSelectedItems()[0];

    gridCellClick(items.item);
  };

  const handleMasterDelete = () => {
    const auiGrid = myGrid.current;
    auiGrid.removeRow("selectedIndex");
  };
  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      let validator = [];
      const auiGrid = myGrid.current;
      var items = auiGrid.getGridData();

      const result = getGridValidateCheck(auiGrid, items, INITIAL_FIELD_RULES);
      if (result.isValidate) {
        //검색조건과 입력필드의 name이 동일할 경우가 있다
        if (document.getElementsByName(result.column).length > 1) {
          document.getElementsByName(result.column)[1].focus();
        } else {
          document.getElementsByName(result.column)[0].focus();
        }
        ModalConfirm({
          title: "필수항목체크",
          description: result.message,
          type: "alert",
        });
        auiGrid.setSelectionByIndex(result.rowIndex);
        gridCellClick(result.rowItem);
        return;
      }
      const addItems = auiGrid.getAddedRowItems();
      const editItems = auiGrid.getEditedRowItems();
      const removeItems = auiGrid.getRemovedItems();
      const menuId = menuKey;
      if (addItems.length > 0) {
        for (let i = 0; i < addItems.length; i++) {
          if (addItems[i].pwd === undefined || addItems[i].pwd === "") {
            ModalConfirm({
              title: "필수항목체크",
              description: "비밀번호는 필수 항목입니다",
              type: "alert",
            });
            return;
          }
        }
      }

      let param = saveGridCheckData(addItems, editItems, removeItems, menuId);
      if (param.length > 0) {
        const {
          data: { data },
        } = await fetchData({
          url: SAVE_USER_URL,
          data: param,
          method: "POST",
        });

        handleMasterSearch();
      } else {
        ModalConfirm({
          title: "필수항목체크",
          description: "저장할 항목이 없습니다. ",
          type: "alert",
        });
      }
    }
  };

  const handleChangeField = useCallback((event) => {
    const auiGrid = myGrid.current;
    const { name, value } = event.target;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;
    //console.log("name === " + name + " || value === " + value);

    if (name === "telNo" || name === "mobileNo") {
      //전화번호 관련 함수 정의
      const regex = /^[0-9\b -]{0,13}$/;
      if (regex.test(value)) {
        const phone = ajusta(value);
        item[name] = phone;
        setFieldValues({ ...fieldValues, [name]: phone });
      }
    } else {
      item[name] = value;
      setFieldValues({ ...fieldValues, [name]: value });
    }
    auiGrid.updateRow(item, "selectedIndex");
  });

  const handleMasterExcel = useCallback(() => {
    const auiGrid = myGrid.current;
    auiGrid.exportToXlsx({
      fileName: props.menuInfo.menuNmKor + " 리스트",
    });
  });

  const handleSearchDept = () => {
    ModalDeptSearchPop({
      searchTxt: fieldValues.dptNm,
      callbackFunc: setDeptInfo,
    });
  };

  const setDeptInfo = (deptInfo) => {
    //console.log(deptInfo);
    if (deptInfo !== undefined) {
      setFieldValues({
        ...fieldValues,
        dptCd: deptInfo.detlCd,
        dptNm: deptInfo.cdNmKor,
      });

      setOldValues({ dptNm: deptInfo.cdNmKor });

      updateGridData("dptCd", deptInfo.detlCd);
      updateGridData("dptNm", deptInfo.cdNmKor);
    }

    // setFieldValues({
    // 	...fieldValues,
    // 	custCd: custInfo.custCd,
    // 	custNm: custInfo.custNm,
    //   });

    //   setOldValues({ ...oldValues, custNm: custInfo.custNm });

    //   updateGridData("custCd", custInfo.custCd);
    //   updateGridData("custNm", custInfo.custNm);
  };

  const setDeptInfoTxt = (evt) => {
    if (evt.keyCode === 13) {
      evt.target.blur();
    }
  };

  const chkDeptData = (evt) => {
    if (evt.target.value !== undefined && evt.target.value !== "") {
      ModalDeptSearchPop({
        callbackFunc: setDeptInfo,
        searchTxt: evt.target.value,
        autoSelect: true,
        failCallbackFunc: restoreDeptData,
      });
    }
  };

  const restoreDeptData = () => {
    setFieldValues({ ...fieldValues, dptNm: oldValues.dptNm });
  };

  return (
    <>
      <ButtonBar
        menuInfo={props.menuInfo}
        handleMasterSearch={handleMasterSearch}
        handleMasterNew={handleMasterNew}
        handleMasterDelete={handleMasterDelete}
        handleMasterSave={handleMasterSave}
        handleMasterExcel={handleMasterExcel}
      />
      <SearchBar
        setSearchParameters={setSearchParameters}
        systemCd={userInfo.systemCd}
      />

      <div className="grid_wrap">
        <AUIGrid
          ref={myGrid}
          columnLayout={columnLayout}
          gridProps={defaultGridProps}
        />
      </div>
      <div className="data_wrap">
        <div className="subdatabox">
          <h5>사용자정보</h5>
          <ul className="t_form">
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>회사구분
              </div>
              <div className="formbox">
                <SelectBox
                  mastCd="SYSTEM_CD"
                  id="systemCd"
                  name="systemCd"
                  topSelected="선택"
                  disabled={isFieldDisabled(state, "systemCd", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.systemCd}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>사용자아이디
              </div>
              <div className="formbox">
                <input
                  id="userId"
                  name="userId"
                  disabled={isFieldDisabled(state, "userId", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.userId}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>암호
              </div>
              <div className="formbox">
                <input
                  id="pwd"
                  name="pwd"
                  disabled={isFieldDisabled(state, "pwd", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.pwd}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>사원번호
              </div>
              <div className="formbox">
                <input
                  id="empNo"
                  name="empNo"
                  disabled={isFieldDisabled(state, "empNo", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.empNo}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>성명_한글
              </div>
              <div className="formbox">
                <input
                  id="nmKor"
                  name="nmKor"
                  disabled={isFieldDisabled(state, "nmKor", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.nmKor}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">성명_영문</div>
              <div className="formbox">
                <input
                  id="nmEng"
                  name="nmEng"
                  disabled={isFieldDisabled(state, "nmEng", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.nmEng}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">성명_한자</div>
              <div className="formbox">
                <input
                  id="nmChn"
                  name="nmChn"
                  disabled={isFieldDisabled(state, "nmChn", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.nmChn}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">이메일주소</div>
              <div className="formbox">
                <input
                  id="email"
                  name="email"
                  disabled={isFieldDisabled(state, "email", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.email}
                  className="ip2"
                />
              </div>
            </li>
            <li style={{ width: "50%" }}>
              <div className="title">부서</div>
              <div className="ip_sup2">
                <div>
                  <input
                    id="dptCd"
                    name="dptCd"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.dptCd}
                    className="ip2"
                  />
                </div>
                <div>
                  <input
                    id="dptNm"
                    name="dptNm"
                    disabled={isFieldDisabled(state, "dptNm", disabledFields)}
                    onChange={handleChangeField}
                    onKeyDown={setDeptInfoTxt}
                    onBlur={chkDeptData}
                    value={fieldValues.dptNm}
                    className="ip2"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    disabled={isFieldDisabled(
                      state,
                      "dptCd",
                      disabledFields,
                      fieldValues.isForceDisable
                    )}
                    onClick={handleSearchDept}
                    className="btn_ip"
                  >
                    검색
                  </button>
                </div>
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">전화번호</div>
              <div className="formbox">
                <input
                  id="telNo"
                  name="telNo"
                  disabled={isFieldDisabled(state, "telNo", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.telNo}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">모바일번호</div>
              <div className="formbox">
                <input
                  id="mobileNo"
                  name="mobileNo"
                  disabled={isFieldDisabled(state, "mobileNo", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.mobileNo}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">직책코드</div>
              <div className="formbox">
                <input
                  id="rsofCd"
                  name="rsofCd"
                  disabled={isFieldDisabled(state, "rsofCd", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.rsofCd}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">직책명</div>
              <div className="formbox">
                <input
                  id="rsofNm"
                  name="rsofNm"
                  disabled={isFieldDisabled(state, "rsofNm", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.rsofNm}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">사업장코드</div>
              <div className="formbox">
                <input
                  id="plbsCd"
                  name="plbsCd"
                  disabled={isFieldDisabled(state, "plbsCd", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.plbsCd}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">암호변경일</div>
              <div className="formbox">
                <input
                  id="pwdChngDt"
                  name="pwdChngDt"
                  disabled={true}
                  onChange={handleChangeField}
                  value={fieldValues.pwdChngDt}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">성별</div>
              <div className="formbox">
                <SelectBox
                  mastCd="GENDER"
                  id="gender"
                  name="gender"
                  topSelected="전체"
                  disabled={isFieldDisabled(state, "gender", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.gender}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">입사일</div>
              <div className="formbox">
                <input
                  type="date"
                  id="etcoDt"
                  name="etcoDt"
                  disabled={isFieldDisabled(state, "etcoDt", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.etcoDt}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>사용여부
              </div>
              <div className="formbox">
                <SelectBox
                  mastCd="USE_YN"
                  id="useYn"
                  name="useYn"
                  topSelected="전체"
                  disabled={isFieldDisabled(state, "useYn", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.useYn}
                  className="ip2"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserReg;
