export const columnLayout = (searchBoardInfo) => {
  const gridColumnLayOut = [
    {
      dataField: "boardId",
      headerText: "게시판아이디",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "boardTitle",
      headerText: "제목",
      width: 450,
      style: "my-left-column",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
      renderer: {
        type: "LinkRenderer",
        baseUrl: "javascript", // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          searchBoardInfo(item);
        },
      },
    },
    {
      dataField: "regDt",
      headerText: "등록일자",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "regNm",
      headerText: "등록자명",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
  ];
  return gridColumnLayOut;
};
