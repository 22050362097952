import React, { useState, useEffect, useCallback } from "react";
import SearchBar from "./SearchBar";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import SubButtonBar1 from "../../../components/Layout/ButtonBar/SubButtonBar1";
import SubButtonBar2 from "../../../components/Layout/ButtonBar/SubButtonBar2";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import useAuiGridMethods from "../../../hooks/AuiGrid/useAuiGridMethods";
import {
  createMasterColumnData,
  createDetailColumnData,
} from "./conlumnLayout";
import gridLayout from "./gridLayout";
import { useId } from "react-id-generator";
import { saveGridCheckData } from "../../../utils/validators";
import {
  setStorageItem,
  getStorageItem,
} from "../../../hooks/Storage/useLocalStorage";
import useAppContext from "../../../utils/Reducers";

let primaryParameters = {
  mastCd: "",
  cdNmKor: "",
  useYn: "",
};

let auiMasterGrid;
let auiDetailGrid;
const CodeMng = (props) => {
  const {
    store: { userInfo, menuKey },
  } = useAppContext();
  const [masterGrid] = useId(1, "aui-grid-");
  const [detailGrid] = useId(1, "aui-grid-");

  auiMasterGrid = useAuiGridMethods(masterGrid);
  auiDetailGrid = useAuiGridMethods(detailGrid);

  const [favorite, setFavorite] = useState("");

  //그리드 초기 설정
  useEffect(() => {
    auiMasterGrid.create(createMasterColumnData(), {
      ...defaultGridProps,
      editable: true,
    });
    auiDetailGrid.create(createDetailColumnData(), {
      ...defaultGridProps,
      editable: true,
    });

    // masterGrid와 detailGrid 에 클래스 추가
    const masterGridElement = document.getElementById(masterGrid);
    const detailGridElement = document.getElementById(detailGrid);
    if (masterGridElement) {
      masterGridElement.classList.add("custom-auigrid-class");
    }

    if (detailGridElement) {
      detailGridElement.classList.add("custom-auigrid-class");
    }

    // 생성 후 그리드 크기를 100%로 조정
    auiMasterGrid.resize("100%", "100%");
    auiDetailGrid.resize("100%", "100%");

    // 리사이즈 이벤트 핸들러 정의
    const handleResize = () => {
      auiMasterGrid.resize("100%", "100%");
      auiDetailGrid.resize("100%", "100%");
    };

    // 리사이즈 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    auiMasterGrid.bind("cellClick", function (event) {
      searchDetailList(event.item.mastCd);
    });
    // hidden 컬럼 정의
    auiDetailGrid.hideColumnByDataField("mastCd");
    // 에디팅 시작 이벤트 바인딩
    auiMasterGrid.bind("cellEditBegin", function (event) {
      // 추가된 행 아이템들(배열)은 수정이 가능해야

      if (auiMasterGrid.isAddedById(event.item._$uid)) {
        return true;
      }
      // 마스터 그리드의 마스터코드 편집 불가능
      if (event.dataField === "mastCd") {
        return false;
      }
    });

    // 에디팅 시작 이벤트 바인딩
    auiDetailGrid.bind("cellEditBegin", function (event) {
      // 추가된 행 아이템들(배열)은 수정이 가능해야
      if (auiDetailGrid.isAddedById(event.item._$uid)) {
        return true;
      }
      // 디테일 그리드의 디테일코드 편집 불가능
      if (event.dataField === "detailCd") {
        return false;
      }
    });

    //초기 Local Storage에 저장되어 있는 즐겨찾기 메뉴중 현재 메뉴가 있는지 체크
    let favorList = getStorageItem("favorites");
    if (favorList !== undefined) {
      const foundMenu = favorList.find(
        (element) => element.menuId === props?.menuInfo?.menuId
      );

      //현재 메뉴가 있으면 즐겨찾기 상태를 on
      if (foundMenu !== undefined) {
        setFavorite("on");
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [codeFieldValues, setcodeFieldValues] = useState({
    mastCd: "",
    cdNmKor: "",
  });
  //공통코드 검색 URL
  const SEARCH_MASTER_CODE_URL = "/common/code/searchMasterCode";
  //공통코드 저장 URL
  const SAVE_MASTER_CODE_URL = "/common/code/saveMasterCode";

  //공통세부코드 URL
  const SEARCH_DETAIL_CODE_URL = "/common/code/searchDetailCode";
  //공통세부코드 저장 URL
  const SAVE_DETAIL_CODE_URL = "/common/code/saveDetailCode";

  const [, fetchCodeData] = useAxiosWithAuthorization(
    {
      url: SEARCH_MASTER_CODE_URL,
    },
    { manual: true }
  );

  const [, fetchDetailCodeData] = useAxiosWithAuthorization(
    {
      url: SEARCH_DETAIL_CODE_URL,
    },
    { manual: true }
  );

  const handleMasterSearch = async () => {
    const {
      data: { data },
    } = await fetchCodeData({
      url: SEARCH_MASTER_CODE_URL,
      params: primaryParameters,
    });
    auiMasterGrid.setGridData(data);
  };

  const searchDetailList = async (mastCd) => {
    const {
      data: { data },
    } = await fetchDetailCodeData({
      url: SEARCH_DETAIL_CODE_URL,
      params: { mastCd: mastCd },
    });

    auiDetailGrid.setGridData(data);
  };

  const handleMasterNew = () => {
    const item = new Object();
    auiMasterGrid.addRow(item, "last");
  };
  const handleMasterDelete = async () => {
    auiMasterGrid.removeRow("selectedIndex");
  };
  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      const addItems = auiMasterGrid.getAddedRowItems();
      const editItems = auiMasterGrid.getEditedRowItems();
      const removeItems = auiMasterGrid.getRemovedItems();
      const menuId = menuKey;
      let param = saveGridCheckData(addItems, editItems, removeItems, menuId);

      const {
        data: { data },
      } = await fetchCodeData({
        url: SAVE_MASTER_CODE_URL,
        data: param,
        method: "POST",
      });

      handleMasterSearch();
    }
  };

  const handleDetailNew = () => {
    const item = new Object();
    const items = auiMasterGrid.getSelectedItems();

    for (var i = 0; i < items.length; i++) {
      var rowItem = items[i];

      item.mastCd = rowItem.item.mastCd;
    }

    auiDetailGrid.addRow(item, "last");
  };
  const handleDetailDelete = async () => {
    auiDetailGrid.removeRow("selectedIndex");
  };
  const handleDetailSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      const addItems = auiDetailGrid.getAddedRowItems();
      const editItems = auiDetailGrid.getEditedRowItems();
      const removeItems = auiDetailGrid.getRemovedItems();
      const menuId = menuKey;
      let param = saveGridCheckData(addItems, editItems, removeItems, menuId);

      const {
        data: { data },
      } = await fetchCodeData({
        url: SAVE_DETAIL_CODE_URL,
        data: param,
        method: "POST",
      });
      const items = auiMasterGrid.getSelectedItems()[0];
      const item = items.item;
      searchDetailList(item.mastCd);
    }
  };

  const setSearchParameters = (values) => {
    primaryParameters = values;
  };

  const handleMasterExcel = useCallback(() => {
    auiMasterGrid.exportToXlsx({
      fileName: props.menuInfo.menuNmKor + " 리스트",
    });
  });

  const handleDetailExcel = useCallback(() => {
    auiDetailGrid.exportToXlsx({
      fileName: props.menuInfo.menuNmKor + " 셍세코드 리스트",
    });
  });

  const onClickFavorite = () => {
    let favorMenuList = getStorageItem("favorites");
    console.log(favorMenuList);
    if (favorMenuList === undefined) {
      favorMenuList = [];
    }

    if (favorite === "") {
      setFavorite("on");
      favorMenuList.push(props?.menuInfo);
      setStorageItem("favorites", favorMenuList);
    } else {
      let menuList = favorMenuList.filter(
        (element) => element.menuId !== props?.menuInfo?.menuId
      );
      setFavorite("");
      setStorageItem("favorites", menuList);
    }
  };

  return (
    <>
      <div className="flexible_button_box">
        <div className="title">
          <h2>
            {props?.menuInfo?.menuNmKor}
            <button
              type="button"
              className={"page_favorite " + favorite}
              onClick={onClickFavorite}
            >
              즐겨찾기
            </button>
          </h2>
        </div>
      </div>
      <SearchBar setSearchParameters={setSearchParameters} />

      <div className="grid_wrap col_2">
        <div className="half">
          <SubButtonBar1
            menuInfo={props.menuInfo}
            handleMasterSearch={handleMasterSearch}
            handleMasterNew={handleMasterNew}
            handleMasterDelete={handleMasterDelete}
            handleMasterSave={handleMasterSave}
            handleMasterExcel={handleMasterExcel}
          />
          <div id={masterGrid} />
        </div>
        <div className="half">
          <SubButtonBar2
            menuInfo={props.menuInfo}
            handleMasterNew={handleDetailNew}
            handleMasterDelete={handleDetailDelete}
            handleMasterSave={handleDetailSave}
            handleMasterExcel={handleDetailExcel}
          />
          <div id={detailGrid} />
        </div>
      </div>
    </>
  );
};

export default React.memo(CodeMng);
