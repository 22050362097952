import React, { useCallback, useEffect, useState } from "react";

const SearchBar = (props) => {
  // SerachBar 기본 필드
  const [initFields, setInitFields] = useState({
    schBoardTitle: "",
    schBoardConent: "",
  });

  useEffect(() => {
    setInitFields({
      schBoardTitle: "",
      schBoardContent: "",
    });
  }, [props.isInitSearch]);

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    console.log("name === " + name + " || value === " + value);

    setInitFields({ ...initFields, [name]: value });
    props.setSearchParameters({ ...initFields, [name]: value });
  });

  return (
    <>
      <div className="searchdata">
        <ul>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 제목
            </div>
            <input
              type="text"
              name="schBoardTitle"
              onChange={handleChangeField}
              className="datainput"
              value={initFields.schBoardTitle}
            />
          </li>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 내용
            </div>
            <input
              type="text"
              name="schBoardContent"
              onChange={handleChangeField}
              className="datainput"
              value={initFields.schBoardContent}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(SearchBar);
