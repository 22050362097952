import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./columnLayout";

const ItemSearchPop = ({ itemType, callbackFunc, onResolve }) => {
  const [show, setShow] = useState(true);
  console.log(itemType);
  const myGrid = useRef();
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");
  }, []);

  const handleClose = (returnData) => {
    if (returnData) {
      const auiGrid = myGrid.current;
      let selItem = auiGrid.getSelectedItems()[0];
      if (selItem) {
        onResolve(returnData); //왠진 모르지만 이거 안해주면 에러남..
        callbackFunc(selItem.item);
      }
    } else {
      onResolve(returnData);
    }

    setShow(false);
  };

  const SEARCH_CUST_URL = "/common/code/searchERPCode";
  const [, fetchCustData] = useAxiosWithAuthorization(
    {
      url: SEARCH_CUST_URL,
    },
    { manual: true }
  );

  const INITIAL_SEARCH_FIELDS = {
    mastCd: itemType, //팝업 파라메터로 받은것
    searchTxt: "", //품번/품명 검색어
  };

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    console.log(name, value);
    INITIAL_SEARCH_FIELDS[name] = value;
    console.log(INITIAL_SEARCH_FIELDS);
  });

  const searchItem = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchCustData({
      url: SEARCH_CUST_URL,
      params: {
        mastCd: INITIAL_SEARCH_FIELDS.mastCd,
        searchTxt: INITIAL_SEARCH_FIELDS.searchTxt,
      },
    });
    auiGrid.setGridData(data);
    //setFieldValues(initItem);
    auiGrid.removeAjaxLoader();
  };

  // const handleChangeField = useCallback((event) => {
  //   const auiGrid = myGrid.current;
  //   const { name, value } = event.target;
  //   const items = auiGrid.getSelectedItems()[0];
  //   const item = items.item;
  //   console.log("name === " + name + " || value === " + value);

  //   if (name === "telNo" || name === "mobileNo") {
  //     //전화번호 관련 함수 정의
  //     const regex = /^[0-9\b -]{0,13}$/;
  //     if (regex.test(value)) {
  //       const phone = ajusta(value);
  //       item[name] = phone;
  //       setFieldValues({ ...fieldValues, [name]: phone });
  //     }
  //   } else {
  //     item[name] = value;
  //     setFieldValues({ ...fieldValues, [name]: value });
  //   }
  //   auiGrid.updateRow(item, "selectedIndex");
  // });

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ERP품번 팝업</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="divide50">
              <font color="grey">⦁</font> 품번/품명
            </div>
            <div className="ip_textandbutton">
              <div>
                <input
                  type="text"
                  name="searchTxt"
                  disabled={false}
                  onChange={handleChangeField}
                  className="ip1"
                />
              </div>
              <div>
                <button type="button" onClick={searchItem} className="btn_ip">
                  검색
                </button>
              </div>
            </div>
            <div className="flexible_contents_box">
              <div className="databox">
                <div style={{ width: "100%", height: "100%" }}>
                  <AUIGrid
                    ref={myGrid}
                    columnLayout={columnLayout}
                    gridProps={defaultGridProps}
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {"닫기"}
          </Button>
          <Button variant="primary" onClick={() => handleClose(true)}>
            {"확인"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalItemSearchPop = createModal(ItemSearchPop);

export default ItemSearchPop;
