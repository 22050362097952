import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./columnLayout";

const SalesSearchPop = ({
  callbackFunc,
  searchTxt,
  searchCust,
  autoSelect,
  failCallbackFunc,
  onResolve,
}) => {
  const [show, setShow] = useState(true);

  const myGrid = useRef();
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");

    auiGrid.bind("cellDoubleClick", function (event) {
      gridCellDBClick(event.item);
    });

    if (searchTxt !== undefined && searchTxt !== "") {
      searchItem();
    }
  }, []);

  const gridCellDBClick = (item) => {
    onResolve(true); //왠진 모르지만 이거 안해주면 에러남..
    callbackFunc(item);
    setShow(false);
  };

  const [searchCnt, setSearchCnt] = useState(1);

  const handleClose = (returnData) => {
    if (returnData) {
      const auiGrid = myGrid.current;
      let selItem = auiGrid.getSelectedItems()[0];
      if (selItem) {
        onResolve(returnData); //왠진 모르지만 이거 안해주면 에러남..
        callbackFunc(selItem.item);
      }
    } else {
      onResolve(returnData);
      if (failCallbackFunc !== undefined) {
        failCallbackFunc();
      }
    }

    setShow(false);
  };

  const SEARCH_CUST_URL = "/common/code/searchSales";
  const [, fetchCustData] = useAxiosWithAuthorization(
    {
      url: SEARCH_CUST_URL,
    },
    { manual: true }
  );

  const getTodayStr = () => {
    let today = new Date();
    let todayStr = today.getFullYear();
    todayStr +=
      today.getMonth() + 1 < 10
        ? "-0" + (today.getMonth() + 1)
        : "-" + (today.getMonth() + 1);
    todayStr +=
      today.getDate() + 1 < 10 ? "-0" + today.getDate() : "-" + today.getDate();

    return todayStr;
  };

  const [INITIAL_SEARCH_FIELDS, setSearchFields] = useState({
    fromDt: getTodayStr(),
    toDt: getTodayStr(),
    jno: searchTxt,
    custSearchTxt: searchCust,
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    //INITIAL_SEARCH_FIELDS[name] = value;
    setSearchFields({ ...INITIAL_SEARCH_FIELDS, [name]: value });
  });

  const searchItem = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchCustData({
      url: SEARCH_CUST_URL,
      params: {
        fromDt: INITIAL_SEARCH_FIELDS.fromDt,
        toDt: INITIAL_SEARCH_FIELDS.toDt,
        jno: INITIAL_SEARCH_FIELDS.jno,
        custSearchTxt: INITIAL_SEARCH_FIELDS.custSearchTxt,
      },
    });
    auiGrid.setGridData(data);
    //setFieldValues(initItem);
    auiGrid.removeAjaxLoader();

    if (data.length > 0) {
      const auiGrid = myGrid.current;
      auiGrid.setSelectionByIndex(0, 0);
    }
    setSearchCnt(searchCnt + 1);
    if (
      searchTxt !== undefined &&
      data.length === 1 &&
      autoSelect === true &&
      searchCnt === 1
    ) {
      handleClose(true);
    }
  };

  // const handleChangeField = useCallback((event) => {
  //   const auiGrid = myGrid.current;
  //   const { name, value } = event.target;
  //   const items = auiGrid.getSelectedItems()[0];
  //   const item = items.item;
  //   console.log("name === " + name + " || value === " + value);

  //   if (name === "telNo" || name === "mobileNo") {
  //     //전화번호 관련 함수 정의
  //     const regex = /^[0-9\b -]{0,13}$/;
  //     if (regex.test(value)) {
  //       const phone = ajusta(value);
  //       item[name] = phone;
  //       setFieldValues({ ...fieldValues, [name]: phone });
  //     }
  //   } else {
  //     item[name] = value;
  //     setFieldValues({ ...fieldValues, [name]: value });
  //   }
  //   auiGrid.updateRow(item, "selectedIndex");
  // });

  const handleKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      searchItem();
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>수주 검색 팝업</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="searchdata">
              <ul>
                <li>
                  <div className="divide50">
                    <font color="grey">⦁</font> 조회기간
                  </div>
                  <input
                    type="date"
                    name="fromDt"
                    onChange={handleChangeField}
                    className="datainput"
                    value={INITIAL_SEARCH_FIELDS.fromDt}
                  />
                  &nbsp; ~ &nbsp;
                  <input
                    type="date"
                    name="toDt"
                    onChange={handleChangeField}
                    className="datainput"
                    value={INITIAL_SEARCH_FIELDS.toDt}
                  />
                </li>
                <li>
                  <div className="divide50">
                    <font color="grey">⦁</font> 거래처
                  </div>
                  <input
                    type="text"
                    name="custSearchTxt"
                    onChange={handleChangeField}
                    className="datainput"
                    value={INITIAL_SEARCH_FIELDS.custSearchTxt}
                  />
                </li>
                <li>
                  <div className="divide50">
                    <font color="grey">⦁</font> 수주번호
                  </div>
                  <div className="ip_textandbutton">
                    <div>
                      <input
                        type="text"
                        name="jno"
                        disabled={false}
                        onChange={handleChangeField}
                        className="ip1"
                        value={INITIAL_SEARCH_FIELDS.jno}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={searchItem}
                        className="btn_ip"
                      >
                        검색
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="databox">
                <div style={{ width: "100%", height: "100%" }}>
                  <AUIGrid
                    ref={myGrid}
                    columnLayout={columnLayout}
                    gridProps={defaultGridProps}
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {"닫기"}
          </Button>
          <Button variant="primary" onClick={() => handleClose(true)}>
            {"확인"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalSalesSearchPop = createModal(SalesSearchPop);

export default SalesSearchPop;
