export const columnLayout = [
  {
    dataField: "sg",
    headerText: "사업장코드",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "sgNm",
    headerText: "사업장명",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "salDt",
    headerText: "날짜",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "salJno",
    headerText: "전표번호",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "custCd",
    headerText: "거래처코드",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "custNm",
    headerText: "상호",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },

  {
    dataField: "carNo",
    headerText: "차량번호",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "sendCd",
    headerText: "직송처코드",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "sendNm",
    headerText: "직송처명",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "prodNm",
    headerText: "품명",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "prodCs",
    headerText: "재질",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "prodGg",
    headerText: "규격",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },

  {
    dataField: "prodCd",
    headerText: "제품코드",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "bdQty",
    headerText: "BD",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "psQty",
    headerText: "PS",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "rmk",
    headerText: "비고",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
];
