import React, { useState, useCallback, useEffect, useRef } from "react";
import SearchBar from "./SearchBar";
import {
  getGridValidateCheck,
  saveGridCheckData,
  isFieldDisabled,
} from "../../../utils/validators";
import ButtonBar from "../../../components/Layout/ButtonBar";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./conlumnLayout";
import { INITIAL_FIELD_RULES } from "./validateFields";
import osq from "../../../images/osq.png";
import locationImg from "../../../images/location_pin.png";
import SelectBox from "../../../components/SelectBox";
import { ModalAddress } from "../../../components/Address";
import MaskedInput from "react-maskedinput";
import useAppContext from "../../../utils/Reducers";
import { ajusta } from "../../../utils/validators";
import { ModalVendorPop } from "../../Popup/VendorPop";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";

var map;
var marker;
var popup;

var approvalCnt = 0;
const ApprovalMng = (props) => {
  const [state, setState] = useState("normaled");
  const [research, setResearch] = useState(0);

  const myGrid = useRef();

  const {
    store: { userInfo, menuKey },
  } = useAppContext();

  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", 960);
  }, [menuKey]);

  //추가, 수정일때 항목의 disabled 결정
  const disabledFields = {
    created: ["addr"],
    updated: ["rSystemCd", "rVndrCd", "rVndrNm", "rBsnsNo"],
  };
  const initItem = {
    state: "",
    lAddr: "",
    lAddrDesc: "",
    lLatt: "",
    lLoadAreaCd: "",
    lLoadAreaNm: "",
    lLont: "",
    lNote: "",
    lRvndrCd: "",
    lStatusCd: "",
    lStatusCdNm: "",
    lSystemCd: "",
    lUseYn: "",
    rAddr: "",
    rAddrDesc: "",
    rBsnsNo: "",
    rLatt: "",
    rLont: "",
    rMngrNm: "",
    rMngrTel: "",
    rNote: "",
    rRvndrCd: "",
    rRvndrNm: "",
    rStatusCd: "",
    rStatusCdNm: "",
    rSystemCd: "",
    rTelNo: "",
    rUseYn: "",
    rVndrCd: "",
    rVndrNm: "",
    regDt: "",
    statusCdNm: "",
    spDist: "",
    nsDist: "",
    gpDist: "",
  };
  const [fieldValues, setFieldValues] = useState(initItem);

  //실공급업체/상차지승인 검색 URL
  const SERARCH_APPROVAL_URL = "/company/approval/searchApprovalList.do";
  //실공급업체/상차지승인 저장 URL
  const SAVE_APPROVAL_URL = "/company/approval/saveApproval.do";

  //실공급업체/상차지승인 승인 URL
  const CHANGE_APPROVAL_URL = "/company/approval/changeApproval.do";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_APPROVAL_URL,
    },
    { manual: true }
  );

  const [primaryParameters, setPrimaryParameters] = useState({
    schRvndrNm: "",
    schVndrNm: "",
    schLoadAreaNm: "",
    schAddr: "",
    schStatusCd: "",
  });

  //검색조건
  const setSearchParameters = (values) => {
    setPrimaryParameters(values);
  };

  const { Tmapv2 } = window;

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    const auiGrid = myGrid.current;

    auiGrid.bind("cellClick", function (event) {
      gridCellClick(event.item);
    });

    const initMap = () => {
      map = new window.Tmapv2.Map("map_div", {
        center: new window.Tmapv2.LatLng(35.0838475629019, 128.989000847098), // 지도 초기 좌표
        // width: "100%",
        height: "300px",
        zoom: 17,
      });

      marker = new Tmapv2.Marker({
        position: new Tmapv2.LatLng(35.0838475629019, 128.989000847098),
        map: map,
      });
      var content =
        "<div style='font-size:14px'>" + "<span> YK스틸</span>" + "</div>"; // 라벨 내용 지정

      popup = new Tmapv2.InfoWindow({
        position: new Tmapv2.LatLng(35.0838475629019, 128.989000847098), //Popup 이 표출될 맵 좌표
        content: content, //Popup 크기는 스타일로 지정, Popup 표시될 text
        type: 2,
        map: map, // 지도 객체
      });
    };

    initMap();
  }, []);

  useEffect(() => {
    if (research !== 0) {
      handleMasterSearch();
    }
  }, [research]);

  //메시지 전송을 하고 나서 배송상태값을 입차대기 T 로 변경
  const onRowButtonClick = async (event) => {
    console.log(event);
    var item = event.item;

    if (
      await ModalConfirm({
        title: "승인",
        description: "승인하겠습니까?",
      })
    ) {
      item.lStatusCd = "Y";
      item.rStatusCd = "Y";
      item.status = "U";
      const {
        data: { data },
      } = await fetchData({
        url: SAVE_APPROVAL_URL,
        data: [item],
        method: "POST",
      });

      approvalCnt = approvalCnt + 1;
      setResearch(approvalCnt);
    }
  };

  const setMapCenter = (lat, lon, label) => {
    console.log(
      "lat === " + lat + " || lon === " + lon + " || label === " + label
    );
    var content =
      "<div style='font-size:14px'><span>" + label + "</span></div>"; // 라벨 내용 지정

    map.setCenter(new Tmapv2.LatLng(lat, lon));
    map.setZoom(17);
    marker.setPosition(new Tmapv2.LatLng(lat, lon));
    popup.setPosition(new Tmapv2.LatLng(lat, lon));
    popup.setContent(content);
  };

  const gridCellClick = (item) => {
    //그리드 셀을 클릭했을 때 데이터 상태
    // 추가 : editable, 수정 : edtiable, 삭제 : readonly
    //read
    //setFieldsEnabled(auiGrid, item, disabledFields);
    const auiGrid = myGrid.current;
    if (auiGrid.isAddedById(item._$uid)) {
      setState("created");
    } else if (auiGrid.isEditedById(item._$uid)) {
      setState("updated");
    } else if (auiGrid.isRemovedById(item._$uid)) {
      setState("deleted");
      console.log("삭제 하는 항목");
    } else {
      setState("updated");
    }

    console.log(item);
    setFieldValues(item);
    setMapCenter(item.lLatt, item.lLont, item.lLoadAreaNm);
  };
  const handleMasterSearch = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_APPROVAL_URL,
      params: primaryParameters,
    });
    auiGrid.setGridData(data);
    setFieldValues(initItem);
    auiGrid.removeAjaxLoader();
  };

  const handleMasterNew = () => {
    const auiGrid = myGrid.current;
    auiGrid.addRow(initItem, "last");
    setFieldValues(initItem);
    const items = auiGrid.getSelectedItems()[0];

    gridCellClick(items.item);
  };

  const handleMasterDelete = () => {
    const auiGrid = myGrid.current;
    auiGrid.removeRow("selectedIndex");
  };
  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      let validator = [];
      const auiGrid = myGrid.current;
      var items = auiGrid.getGridData();

      const result = getGridValidateCheck(auiGrid, items, INITIAL_FIELD_RULES);
      if (result.isValidate) {
        document.getElementsByName(result.column)[0].focus();
        ModalConfirm({
          title: "필수항목체크",
          description: result.message,
          type: "alert",
        });
        auiGrid.setSelectionByIndex(result.rowIndex);
        gridCellClick(result.rowItem);
        return;
      }
      const addItems = auiGrid.getAddedRowItems();
      const editItems = auiGrid.getEditedRowItems();
      const removeItems = auiGrid.getRemovedItems();
      let param = saveGridCheckData(addItems, editItems, removeItems);
      if (param.length > 0) {
        const {
          data: { data },
        } = await fetchData({
          url: SAVE_APPROVAL_URL,
          data: param,
          method: "POST",
        });
        handleMasterSearch();
      } else {
        ModalConfirm({
          title: "필수항목체크",
          description: "저장할 항목이 없습니다. ",
          type: "alert",
        });
      }
    }
  };

  const handleChangeField = useCallback((event) => {
    const auiGrid = myGrid.current;
    const { name, value } = event.target;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;
    console.log("name === " + name + " || value === " + value);
    //사업자번호와 공급업체코드는 동일
    if (name === "rbsnsNo") {
      if (auiGrid.isCreated(item._$uid)) {
        var vndrCd = value.replace(/[^0-9a-z]/gi, "");
        setFieldValues({ ...fieldValues, vndrCd: vndrCd });
        item.vndrCd = vndrCd;
      }
      item[name] = value.replace(/[^0-9-a-z]/gi, "");
      setFieldValues({ ...fieldValues, [name]: value });
    } else if (name === "rTelNo" || name === "rMngrTel") {
      //전화번호 관련 함수 정의
      const regex = /^[0-9\b -]{0,13}$/;
      if (regex.test(value)) {
        const phone = ajusta(value);
        item[name] = phone;
        setFieldValues({ ...fieldValues, [name]: phone });
      }
    } else if (name === "rStatusCd") {
      item.rStatusCdNm = event.target.options[event.target.selectedIndex].text;
      item[name] = value;
      setFieldValues({
        ...fieldValues,
        [name]: value,
        rStatusCd: item.rStatusCdNm,
      });

      console.log(item);
    } else if (name === "lStatusCd") {
      item.lStatusCd = event.target.options[event.target.selectedIndex].text;
      item[name] = value;
      setFieldValues({
        ...fieldValues,
        [name]: value,
        lStatusCd: item.lStatusCd,
      });

      console.log(item);
    } else {
      item[name] = value;
      setFieldValues({ ...fieldValues, [name]: value });
    }
    auiGrid.updateRow(item, "selectedIndex");
  });

  // 실공급업체 주소 팝업창 열기
  const handleSearchRAddress = () => {
    ModalAddress({
      setAddress: setRAddress,
    });
  };
  //주소항목에 데이터 넣기
  const setRAddress = useCallback((addrInfo) => {
    if (addrInfo.address !== "") {
      setFieldValues({
        ...fieldValues,
        rAddr: addrInfo.address,
        rLatt: addrInfo.latt,
        rLont: addrInfo.lont,
      });
      const auiGrid = myGrid.current;

      const items = auiGrid.getSelectedItems()[0];
      const item = items.item;
      item.rAddr = addrInfo.address;
      item.rLatt = addrInfo.latt;
      item.rLont = addrInfo.lont;
      console.log(item);
      auiGrid.updateRow(item, "selectedIndex");
    } else {
      ModalConfirm({
        title: "주소오류",
        description: "주소 검색이 잘못되었습니다. 다시 검색하시기 바랍니다. ",
        type: "alert",
      });
    }
  });

  // 상차지 주소 팝업창 열기
  const handleSearchLAddress = () => {
    ModalAddress({
      setAddress: setLAddress,
    });
  };
  //주소항목에 데이터 넣기
  const setLAddress = useCallback((addrInfo) => {
    if (addrInfo.address !== "") {
      setFieldValues({
        ...fieldValues,
        lAddr: addrInfo.address,
        lLatt: addrInfo.latt,
        lLont: addrInfo.lont,
      });

      const auiGrid = myGrid.current;

      const items = auiGrid.getSelectedItems()[0];
      const item = items.item;
      item.lAddr = addrInfo.address;
      item.lLatt = addrInfo.latt;
      item.lLont = addrInfo.lont;

      console.log(item);
      auiGrid.updateRow(item, "selectedIndex");
      setMapCenter(item.lLatt, item.lLont, item.lLoadAreaNm);
    } else {
      ModalConfirm({
        title: "주소오류",
        description: "주소 검색이 잘못되었습니다. 다시 검색하시기 바랍니다. ",
        type: "alert",
      });
    }
  });

  //공급업체 검색 팝업
  const handleSearchVender = () => {
    ModalVendorPop({ onSearchInfo: searchVendorInfo });
  };
  const searchVendorInfo = (vendorInfo) => {
    const auiGrid = myGrid.current;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;

    if (vendorInfo) {
      item.rSystemCd = vendorInfo.systemCd;
      item.rVndrCd = vendorInfo.vndrCd;
      item.rVndrNm = vendorInfo.vndrNm;

      setFieldValues({
        ...fieldValues,
        rSystemCd: vendorInfo.systemCd,
        rVndrCd: vendorInfo.vndrCd,
        rVndrNm: vendorInfo.vndrNm,
      });
      auiGrid.updateRow(item, "selectedIndex");
    }
  };

  const handleMasterExcel = useCallback(() => {
    const auiGrid = myGrid.current;
    auiGrid.exportToXlsx({
      fileName: props.menuInfo.menuNmKor + " 리스트",
    });
  });
  return (
    <>
      <ButtonBar
        menuInfo={props.menuInfo}
        handleMasterSearch={handleMasterSearch}
        handleMasterNew={handleMasterNew}
        handleMasterDelete={handleMasterDelete}
        handleMasterSave={handleMasterSave}
        handleMasterExcel={handleMasterExcel}
      />
      <SearchBar
        setSearchParameters={setPrimaryParameters}
        systemCd={userInfo.systemCd}
      />

      <div className="flexible_contents_box2">
        <div className="databox" style={{ width: "100%", height: "100%" }}>
          <div className="scrollbox">
            <div style={{ width: "100%", height: "100%" }}>
              <AUIGrid
                ref={myGrid}
                columnLayout={columnLayout(onRowButtonClick)}
                gridProps={defaultGridProps}
                height={600}
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="databox">
          <div className="subdatabox">
            <h2>
              <img src={osq} /> &nbsp;상차지지도
              <br /> &nbsp;
            </h2>
            <table className="t_garo">
              <tr>
                <th style={{ textAlign: "center", padding: "0" }}>
                  <img src={locationImg} style={{ verticalAlign: "middle" }} />
                  상세지도
                </th>
              </tr>
              <tr>
                <td style={{ padding: "0" }}>
                  <div id="map_div" style={{ height: "100%" }}></div>
                </td>
              </tr>
            </table>

            <h5>
              <img src={osq} /> &nbsp;실공급업체정보 &nbsp;
              <br />
            </h5>
            <ul className="t_form">
              <li className="half">
                <div className="title">
                  <span className="red">*</span>공급업체
                </div>
                <div className="ip_sup2">
                  <div>
                    <input
                      id="rVndrCd"
                      name="rVndrCd"
                      size="10"
                      disabled={true}
                      value={
                        userInfo.isAdmin === "N"
                          ? userInfo.userId
                          : fieldValues.rVndrCd
                      }
                      className="ip1"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      id="rVndrNm"
                      name="rVndrNm"
                      style={{ width: "108" }}
                      disabled={true}
                      onChange={handleChangeField}
                      value={
                        userInfo.isAdmin === "N"
                          ? userInfo.nmKor
                          : fieldValues.rVndrNm
                      }
                      className="ip1"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={isFieldDisabled(
                        state,
                        "rVndrCd",
                        disabledFields
                      )}
                      onClick={handleSearchVender}
                      className="btn_ip"
                    >
                      검색
                    </button>
                  </div>
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>실공급업체코드
                </div>
                <div className="formbox">
                  <input
                    id="rRvndrCd"
                    name="rRvndrCd"
                    size="10"
                    disabled={true}
                    value={fieldValues.rRvndrCd}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>실공급업체명
                </div>
                <div className="formbox">
                  <input
                    type="text"
                    id="rRvndrNm"
                    name="rRvndrNm"
                    style={{ width: "108px" }}
                    disabled={isFieldDisabled(
                      state,
                      "rRvndrNm",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.rRvndrNm}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="half">
                <div className="title">
                  <span className="red">*</span>주소1
                </div>
                <div className="ip_textandbutton">
                  <div>
                    <input
                      type="text"
                      id="rAddr"
                      name="rAddr"
                      disabled={true}
                      onChange={handleChangeField}
                      value={fieldValues.rAddr}
                      className="ip2"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={isFieldDisabled(state, "rAddr", disabledFields)}
                      onClick={handleSearchRAddress}
                      className="btn_ip"
                    >
                      주소검색
                    </button>
                  </div>
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">주소2</div>
                <div className="formbox">
                  <input
                    type="text"
                    id="rAddrDesc"
                    name="rAddrDesc"
                    disabled={isFieldDisabled(
                      state,
                      "rAddrDesc",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.rAddrDesc}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">위도</div>
                <div className="formbox">
                  <input
                    id="rLatt"
                    name="rLatt"
                    size="10"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.rLatt}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">경도</div>
                <div className="formbox">
                  <input
                    name="rLont"
                    id="rLont"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.rLont}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>사업자번호
                </div>
                <div className="formbox">
                  <MaskedInput
                    mask="111-11-11111"
                    id="rBsnsNo"
                    name="rBsnsNo"
                    size="10"
                    disabled={isFieldDisabled(state, "rBsnsNo", disabledFields)}
                    onChange={handleChangeField}
                    value={fieldValues.rBsnsNo}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>전화번호
                </div>
                <div className="formbox">
                  <input
                    name="rTelNo"
                    id="rTelNo"
                    disabled={isFieldDisabled(state, "rTelNo", disabledFields)}
                    onChange={handleChangeField}
                    value={fieldValues.rTelNo}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>관리자
                </div>
                <div className="formbox">
                  <input
                    type="text"
                    id="rMngrNm"
                    name="rMngrNm"
                    disabled={isFieldDisabled(state, "rMngrNm", disabledFields)}
                    onChange={handleChangeField}
                    value={fieldValues.rMngrNm}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>관리자전화번호
                </div>
                <div className="formbox">
                  <input
                    mask="11111111111"
                    size="11"
                    id="rMngrTel"
                    name="rMngrTel"
                    length={13}
                    disabled={isFieldDisabled(
                      state,
                      "rMngrTel",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.rMngrTel}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">승인여부</div>
                <div className="formbox">
                  <SelectBox
                    mastCd="STATUS_CD"
                    id="rStatusCd"
                    name="rStatusCd"
                    topSelected="선택"
                    disabled={isFieldDisabled(
                      state,
                      "rStatusCd",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.rStatusCd}
                    className="ip2"
                  />
                </div>
              </li>
              <li style={{ height: "75px" }}>
                <div className="title">비고</div>
                <div className="formbox">
                  <textarea
                    rows={1}
                    type="text"
                    id="rNote"
                    name="rNote"
                    disabled={isFieldDisabled(state, "rNote", disabledFields)}
                    onChange={handleChangeField}
                    value={fieldValues.rNote}
                    className="ip2"
                    style={{ height: "64px", verticalAlign: "middle" }}
                  />
                </div>
              </li>
            </ul>
            <h5>
              <img src={osq} /> &nbsp;상차지정보 &nbsp;
              <br />
            </h5>
            <ul className="t_form">
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>상차지코드
                </div>
                <div className="formbox">
                  <input
                    id="lLoadAreaCd"
                    name="lLoadAreaCd"
                    size="16"
                    disabled={true}
                    value={fieldValues.lLoadAreaCd}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">
                  <span className="red">*</span>상차지명
                </div>
                <div className="formbox">
                  <input
                    type="text"
                    id="lLoadAreaNm"
                    name="lLoadAreaNm"
                    disabled={isFieldDisabled(
                      state,
                      "lLoadAreaNm",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.lLoadAreaNm}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="half">
                <div className="title">
                  <span className="red">*</span>주소1
                </div>
                <div className="ip_textandbutton">
                  <div>
                    <input
                      type="text"
                      id="lAddr"
                      name="lAddr"
                      disabled={true}
                      onChange={handleChangeField}
                      value={fieldValues.lAddr}
                      className="ip2"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={isFieldDisabled(state, "lAddr", disabledFields)}
                      onClick={handleSearchLAddress}
                      className="btn_ip"
                    >
                      주소검색
                    </button>
                  </div>
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">주소2</div>
                <div className="formbox">
                  <input
                    type="text"
                    id="lAddrDesc"
                    name="lAddrDesc"
                    disabled={isFieldDisabled(
                      state,
                      "lAddrDesc",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.lAddrDesc}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">위도</div>
                <div className="formbox">
                  <input
                    id="lLatt"
                    name="lLatt"
                    size="10"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.lLatt}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">경도</div>
                <div className="formbox">
                  <input
                    name="lLont"
                    id="lLont"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.lLont}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">승인여부</div>
                <div className="formbox">
                  <SelectBox
                    mastCd="STATUS_CD"
                    id="lStatusCd"
                    name="lStatusCd"
                    topSelected="선택"
                    disabled={isFieldDisabled(
                      state,
                      "lStatusCd",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                    value={fieldValues.lStatusCd}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">신평</div>
                <div className="formbox">
                  <input
                    name="spDist"
                    id="spDist"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.spDist}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">녹산</div>
                <div className="formbox">
                  <input
                    name="nsDist"
                    id="nsDist"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.nsDist}
                    className="ip2"
                  />
                </div>
              </li>
              <li className="doublehalf">
                <div className="title">구평</div>
                <div className="formbox">
                  <input
                    name="gpDist"
                    id="gpDist"
                    disabled={true}
                    onChange={handleChangeField}
                    value={fieldValues.gpDist}
                    className="ip2"
                  />
                </div>
              </li>
              <li style={{ height: "75px" }}>
                <div className="title">비고</div>
                <div className="formbox">
                  <textarea
                    rows={1}
                    type="text"
                    id="lNote"
                    name="lNote"
                    disabled={isFieldDisabled(state, "lNote", disabledFields)}
                    onChange={handleChangeField}
                    value={fieldValues.lNote}
                    className="ip2"
                    style={{ height: "64px", verticalAlign: "middle" }}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovalMng;
