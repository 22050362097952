import React, { useCallback, useState } from "react";
import { ModalCustomerSearchPop } from "../../Popup/CustomerSearchPop";

const SearchBar = (props) => {
  // SerachBar 기본 필드
  const [searchValues, setSearchValues] = useState({
    reduceCd: "",
    custNm: "",
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    let copyMap = searchValues;
    copyMap[name] = value;
    setSearchValues(copyMap);
    props.setSearchParameters(searchValues);
  });

  const handleSearchCust = () => {
    ModalCustomerSearchPop({
      callbackFunc: setCustInfo,
      isContainAll: "Y",
    });
  };

  const setCustInfo = (custInfo) => {
    let copyMap = searchValues;
    copyMap.custNm = custInfo.custNm;
    copyMap.custCd = custInfo.custCd;

    setSearchValues(copyMap);

    props.setSearchParameters(searchValues);
  };

  const setCustTxt = (evt) => {
    if (evt.keyCode === 8 || evt.keyCode === 46) {
      setSearchValues({
        ...searchValues,
        custCd: "",
        custNm: "",
      });
    } else if (evt.keyCode === 13 || evt.keyCode == 9) {
      //enter key or tab key
      ModalCustomerSearchPop({
        callbackFunc: setCustInfo,
        searchTxt: searchValues.custNm,
      });
    }
  };

  return (
    <>
      <div className="searchdata">
        <ul>
          <li>
            <div className="divide50">
              <span className="dot">⦁</span> 거래처
            </div>
            <div className="ip_textandbutton">
              <div>
                <input
                  type="text"
                  name="custNm"
                  className="ip1"
                  onChange={handleChangeField}
                  onKeyDown={setCustTxt}
                  value={searchValues.custNm}
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleSearchCust}
                  className="btn_ip"
                >
                  검색
                </button>
              </div>
            </div>
          </li>
          <li>
            <div className="divide50">
              <span className="dot">⦁</span> 감량코드
            </div>
            <input
              type="text"
              className="datainput"
              name="reduceCd"
              onChange={handleChangeField}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(SearchBar);
