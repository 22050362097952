import React, { useState, useEffect, useCallback, createRef } from "react";
import BoardList from "./BoardList";
import BoardView from "./BoardView";

const BoardInfo = (props) => {
  const [status, setStatus] = useState("list");
  const [boardId, setBoardId] = useState("");

  return (
    <>
      {status === "list" ? (
        <BoardList
          menuInfo={props.menuInfo}
          status={status}
          setStatus={setStatus}
          setBoardId={setBoardId}
        />
      ) : (
        <BoardView
          menuInfo={props.menuInfo}
          status={status}
          setStatus={setStatus}
          boardId={boardId}
        />
      )}
    </>
  );
};

export default BoardInfo;
