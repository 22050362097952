import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { columnLayout } from "./conlumnLayout";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";

const VendorPop = ({ onResolve, onSearchInfo }) => {
  const myGrid = useRef();
  const [show, setShow] = useState(true);

  const [searchParameters, setSearchParameters] = useState({
    systemCd: "",
    schVndrNm: "",
    schAddr: "",
    schBsnsNo: "",
    schVndrTypeCd: "",
    schUseYn: "",
  });

  //공급업체 검색 URL
  const SERARCH_VENDOR_URL = "/company/vendor/searchVendorList.do";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_VENDOR_URL,
    },
    { manual: true }
  );

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.bind("cellDoubleClick", function (event) {
      gridCellDBClick(event.item);
    });

    //
  }, []);

  const gridCellDBClick = (item) => {
    console.log(item);
    onResolve(item);
    onSearchInfo(item);
    setShow(false);
  };
  const handleClose = (returnData) => {
    setShow(false);
  };

  const handleOk = (returnData) => {
    const auiGrid = myGrid.current;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;

    onResolve(item);
    onSearchInfo(item);
    setShow(false);
  };
  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;

    setSearchParameters({ ...searchParameters, [name]: value });
  });

  const handleSearch = async () => {
    const auiGrid = myGrid.current;
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_VENDOR_URL,
      params: searchParameters,
    });
    auiGrid.setGridData(data);
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="clg">
        <Modal.Header closeButton>
          <Modal.Title>공급업체 검색</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="searchdata">
            <ul className="displays">
              <li className="percent100">
                <div className="divide50">
                  <font color="grey">⦁</font> 공급업체명
                </div>
                <input
                  type="text"
                  style={{ width: 128 }}
                  name="schVndrNm"
                  onChange={handleChangeField}
                  className="datainput center"
                />
              </li>
              <li className="percent100">
                <button
                  type="button"
                  className="btn_greystyle updown0"
                  onClick={handleSearch}
                >
                  검색
                </button>
              </li>
            </ul>
          </div>
          <div style={{ width: "100%" }}>
            <AUIGrid
              ref={myGrid}
              columnLayout={columnLayout}
              gridProps={defaultGridProps}
              height={300}
              width="100%"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            취소
          </Button>

          <Button variant="primary" onClick={() => handleOk(true)}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalVendorPop = createModal(VendorPop);

export default VendorPop;
