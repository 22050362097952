import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { API_HOST } from "../../utils/Constants";
import { useAxiosWithAuthorization } from "../../utils/api";

const DSFileUpload = (props) => {
  const SEARCH_BOARD_FILELIST_URL = "/common/board/searchBoardFileList.do";

  const [, fetchData] = useAxiosWithAuthorization({}, { manual: true });

  const [fileList, setFileList] = useState([]);

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    if (props.boardId) {
      searchBoardFileList();
    }
  }, []);

  const searchBoardFileList = async () => {
    const { data: data } = await fetchData({
      url: SEARCH_BOARD_FILELIST_URL,
      params: {
        boardId: props.boardId,
      },
    });

    console.log(data);
    var fList = [];
    data.data.forEach((file) => {
      var fileProp = {
        uid: file.fileId,
        name: file.orgFileNm,
        status: "done",
        url: API_HOST + file.url,
      };
      fList.push(fileProp);
    });

    setFileList(fList);
    props.setFileList(fList);
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    //newFileList = fileList.slice(-2);

    // 2. Read from response and show file link
    newFileList = info.fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = API_HOST + file.response.url;
        file.uid = file.response.uuid;
      }
      return file;
    });

    setFileList(newFileList);
    props.setFileList(newFileList);
  };

  const handlRemove = async (info) => {
    console.log(info);
    const DELETE_BOARDFILE_URL = "/file/deleteBoardFile/" + info.uid + ".do";
    const { data: data } = await fetchData({
      url: DELETE_BOARDFILE_URL,
    });
  };

  const prop = {
    action: "/file/uploadBoardFile.do",

    // onChange({ file, fileList }) {
    //   if (file.status !== "uploading") {
    //     console.log(file, fileList);
    //   }
    // },

    defaultFileList: fileList,
    multiple: true,
    onChange: handleChange,
    onRemove: handlRemove,
  };

  return (
    <>
      <Upload {...prop} fileList={fileList}>
        <Button icon={<UploadOutlined />}>첨부파일</Button>
      </Upload>
    </>
  );
};

export default DSFileUpload;
