import React, { useState, useEffect, useRef, createRef } from "react";
import SearchBar from "./SearchBar";
import { useAxiosWithAuthorization } from "../../../../utils/api";
import ButtonBar from "../../../../components/Layout/ButtonBar";
import useAppContext, { actions } from "../../../../utils/Reducers";
import { defaultGridProps } from "../../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./conlumnLayout";
import "moment/locale/ko";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AUIGrid from "../../../../lib/AuiGrid/AUIGridReact";

const BoardList = (props) => {
	const myGrid = useRef();

	const [state, setState] = useState("normaled");

	const navigate = useNavigate();

	const {
		store: { topMenuKey, menuKey, menuActionList, menuList, userInfo },
		dispatch,
	} = useAppContext();

	useEffect(() => {
		const auiGrid = myGrid.current;
		auiGrid.resize("100%", 600);
	}, [menuKey]);

	const [primaryParameters, setPrimaryParameters] = useState({
		schBoardTitle: "",
		schBoardContent: "",
	});

	//추가, 수정일때 항목의 disabled 결정
	const disabledFields = {
		created: [""],
		updated: ["boardId", "popupStartDt", "popupEndDt"],
	};

	const initItem = {
		boardId: "",
		boardTypeCd: "",
		boardTitle: "",
		boardContent: "",
		regId: userInfo.userId,
		regNm: userInfo.nmKor,
		regDt: moment().format("YYYY-MM-DD"),
		popupYn: "",
		popupStartDt: moment().format("YYYY-MM-DD"),
		popupEndDt: moment().format("YYYY-MM-DD"),
		isChecked: false,
	};

	//검색조건
	const setSearchParameters = (name, value) => {
		setPrimaryParameters({ ...primaryParameters, [name]: value });
	};

	const SERARCH_BOARDLIST_URL = "/common/board/searchBoardList.do";
	const DELETE_BOARD_URL = "/common/board/deleteBoard.do";

	const [, fetchData] = useAxiosWithAuthorization(
		{
			url: SERARCH_BOARDLIST_URL,
		},
		{ manual: true }
	);

	const [fieldValues, setFieldValues] = useState(initItem);

	//코드 및 그리드 초기화 및 생성
	useEffect(() => {
		const auiGrid = myGrid.current;
		auiGrid.bind("cellClick", function (event) {
			gridCellClick(event.item);
		});

		handleMasterSearch();
	}, []);

	const searchBoardInfo = (item) => {
		props.setBoardId(item.boardId);
		props.setStatus("update");
	};

	const gridCellClick = (item) => {};

	const handleMasterSearch = async (event) => {
		const auiGrid = myGrid.current;
		auiGrid.showAjaxLoader();
		const {
			data: { data },
		} = await fetchData({
			url: SERARCH_BOARDLIST_URL,
			params: primaryParameters,
		});

		auiGrid.setGridData(data);
		setFieldValues(initItem);
		auiGrid.removeAjaxLoader();
		setState("normaled");
	};

	const handleMasterNew = (event) => {
		props.setBoardId("");
		props.setStatus("create");
	};

	const handleMasterExcel = (event) => {
		const auiGrid = myGrid.current;
		auiGrid.exportToXlsx({
			fileName: props.menuInfo.menuNmKor + " 리스트",
		});
	};

	return (
		<>
			<ButtonBar
				menuInfo={props.menuInfo}
				handleMasterSearch={handleMasterSearch}
				handleMasterNew={handleMasterNew}
				handleMasterExcel={handleMasterExcel}
			/>
			<SearchBar
				setSearchParameters={setPrimaryParameters}
				systemCd={userInfo.systemCd}
				searchParameters={primaryParameters}
			/>
			<div style={{ width: "100%", height: "100%" }}>
				<AUIGrid
					ref={myGrid}
					columnLayout={columnLayout(searchBoardInfo)}
					gridProps={defaultGridProps}
					height={600}
					width="100%"
				/>
			</div>
		</>
	);
};

export default BoardList;
