import React, { useState, useCallback, useEffect, useRef } from "react";
import SearchBar from "./SearchBar";
import {
  getGridValidateCheck,
  saveGridCheckData,
  isFieldDisabled,
} from "../../../utils/validators";
import ButtonBar from "../../../components/Layout/ButtonBar";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./conlumnLayout";
import { INITIAL_FIELD_RULES } from "./validateFields";
import osq from "../../../images/osq.png";
import SelectBox from "../../../components/SelectBox";
import useAppContext from "../../../utils/Reducers";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";

const AuthMng = (props) => {
  const [state, setState] = useState("normaled");

  const myGrid = useRef();

  const {
    store: { userInfo, menuKey },
  } = useAppContext();

  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");
  }, [menuKey]);

  //추가, 수정일때 항목의 disabled 결정
  const disabledFields = {
    created: [],
    updated: ["authId"],
  };

  const initItem = {
    authId: "",
    authNm: "",
    authVldtStrtDt: "",
    authVldtEndDt: "",
    authDesc: "",
    rmks: "",
    useYn: "",
    status: "",
  };
  const [fieldValues, setFieldValues] = useState(initItem);

  //권한정보 검색 URL
  const SERARCH_AUTH_URL = "/common/auth/searchAuthList";
  //권한정보 저장 URL
  const SAVE_AUTH_URL = "/common/auth/saveAuthList";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_AUTH_URL,
    },
    { manual: true }
  );

  const [primaryParameters, setPrimaryParameters] = useState({
    schAuthId: "",
    schAuthNm: "",
  });

  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    const auiGrid = myGrid.current;

    auiGrid.bind("cellClick", function (event) {
      gridCellClick(event.item);
    });

    //
  }, []);

  const gridCellClick = (item) => {
    // 추가 : editable, 수정 : edtiable, 삭제 : readonly
    //read
    //setFieldsEnabled(auiGrid, item, disabledFields);
    const auiGrid = myGrid.current;
    if (auiGrid.isAddedById(item._$uid)) {
      setState("created");
    } else if (auiGrid.isEditedById(item._$uid)) {
      setState("updated");
    } else if (auiGrid.isRemovedById(item._$uid)) {
      setState("deleted");
      console.log("삭제 하는 항목");
    } else {
      setState("updated");
    }

    console.log(item);
    setFieldValues(item);
  };
  const handleMasterSearch = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_AUTH_URL,
      params: primaryParameters,
    });
    auiGrid.setGridData(data);
    setFieldValues(initItem);
    auiGrid.removeAjaxLoader();
  };

  const handleMasterNew = () => {
    const auiGrid = myGrid.current;

    auiGrid.addRow(initItem, "last");
    setFieldValues(initItem);
    const items = auiGrid.getSelectedItems()[0];

    gridCellClick(items.item);
  };

  const handleMasterDelete = () => {
    const auiGrid = myGrid.current;
    auiGrid.removeRow("selectedIndex");
  };
  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      const auiGrid = myGrid.current;
      var items = auiGrid.getGridData();

      const result = getGridValidateCheck(auiGrid, items, INITIAL_FIELD_RULES);
      if (result.isValidate) {
        document.getElementsByName(result.column)[0].focus();
        ModalConfirm({
          title: "필수항목체크",
          description: result.message,
          type: "alert",
        });
        auiGrid.setSelectionByIndex(result.rowIndex);
        gridCellClick(result.rowItem);
        return;
      }
      const addItems = auiGrid.getAddedRowItems();
      const editItems = auiGrid.getEditedRowItems();
      const removeItems = auiGrid.getRemovedItems();
      const menuId = menuKey;
      let param = saveGridCheckData(addItems, editItems, removeItems, menuId);
      if (param.length > 0) {
        const {
          data: { data },
        } = await fetchData({
          url: SAVE_AUTH_URL,
          data: param,
          method: "POST",
        });

        handleMasterSearch();
      } else {
        ModalConfirm({
          title: "필수항목체크",
          description: "저장할 항목이 없습니다. ",
          type: "alert",
        });
      }
    }
  };

  const handleChangeField = useCallback((event) => {
    const auiGrid = myGrid.current;
    console.log(event);
    const { name, value } = event.target;
    const items = auiGrid.getSelectedItems()[0];
    const item = items.item;
    console.log("name === " + name + " || value === " + value);

    item[name] = value;
    setFieldValues({ ...fieldValues, [name]: value });

    auiGrid.updateRow(item, "selectedIndex");
  });

  const handleMasterExcel = useCallback(() => {
    const auiGrid = myGrid.current;
    auiGrid.exportToXlsx({
      fileName: props.menuInfo.menuNmKor + " 리스트",
    });
  });
  return (
    <>
      <ButtonBar
        menuInfo={props.menuInfo}
        handleMasterSearch={handleMasterSearch}
        handleMasterNew={handleMasterNew}
        handleMasterDelete={handleMasterDelete}
        handleMasterSave={handleMasterSave}
        handleMasterExcel={handleMasterExcel}
      />
      <SearchBar
        setSearchParameters={setPrimaryParameters}
        systemCd={userInfo.systemCd}
      />
      <div className="grid_wrap">
        <AUIGrid
          ref={myGrid}
          columnLayout={columnLayout}
          gridProps={defaultGridProps}
        />
      </div>
      <div className="data_wrap">
        <div className="subdatabox">
          <h5>권한상세정보</h5>
          <ul className="t_form">
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>권한아이디
              </div>
              <div className="formbox">
                <input
                  id="authId"
                  name="authId"
                  disabled={isFieldDisabled(state, "authId", disabledFields)}
                  value={fieldValues.authId}
                  onChange={handleChangeField}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>권한명
              </div>
              <div className="formbox">
                <input
                  type="text"
                  id="authNm"
                  name="authNm"
                  style={{ width: "108px" }}
                  disabled={isFieldDisabled(state, "authNm", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.authNm}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">권한유효시작일시</div>
              <div className="formbox">
                <input
                  type="date"
                  name="authVldtStrtDt"
                  id="authVldtStrtDt"
                  onChange={handleChangeField}
                  disabled={isFieldDisabled(
                    state,
                    "authVldtStrtDt",
                    disabledFields
                  )}
                  value={fieldValues.authVldtStrtDt}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">권한유효종일일시</div>
              <div className="formbox">
                <input
                  type="date"
                  name="authVldtEndDt"
                  id="authVldtEndDt"
                  onChange={handleChangeField}
                  disabled={isFieldDisabled(
                    state,
                    "authVldtEndDt",
                    disabledFields
                  )}
                  value={fieldValues.authVldtEndDt}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>사용여부
              </div>
              <div className="formbox">
                <SelectBox
                  mastCd="USE_YN"
                  id="useYn"
                  name="useYn"
                  topSelected="전체"
                  disabled={isFieldDisabled(state, "useYn", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.useYn}
                  className="ip2"
                />
              </div>
            </li>
            <li style={{ height: "75px" }}>
              <div className="title">설명</div>
              <div className="formbox">
                <textarea
                  rows={1}
                  type="text"
                  id="authDesc"
                  name="authDesc"
                  disabled={isFieldDisabled(state, "authDesc", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.authDesc}
                  className="ip2"
                  style={{
                    height: "64px",
                    verticalAlign: "middle",
                  }}
                />
              </div>
            </li>
            <li style={{ height: "75px" }}>
              <div className="title">비고</div>
              <div className="formbox">
                <textarea
                  rows={1}
                  type="text"
                  id="rmks"
                  name="rmks"
                  disabled={isFieldDisabled(state, "rmks", disabledFields)}
                  onChange={handleChangeField}
                  value={fieldValues.rmks}
                  className="ip2"
                  style={{
                    height: "64px",
                    verticalAlign: "middle",
                  }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AuthMng;
