import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import useAppContext from "../../../utils/Reducers";

const ChangePwdPop = () => {
  //차량 검색 URL
  const CHANGE_USER_PASSWORD_URL = "/common/user/changePwd";
  const [password, setPassword] = useState();
  const [show, setShow] = useState(true);
  const {
    store: { userInfo },
  } = useAppContext();
  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: CHANGE_USER_PASSWORD_URL,
    },
    { manual: true }
  );

  const handleClose = (returnData) => {
    setShow(false);
  };

  const handleOk = (returnData) => {
    changePassword();
    //setShow(false);
  };
  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;

    setPassword(value);
  });

  const changePassword = async () => {
    const {
      data: { data },
    } = await fetchData({
      url: CHANGE_USER_PASSWORD_URL,
      data: { userId: userInfo.userId, userPwd: password },
      method: "POST",
    });
    console.log(data);
    if (data === "success") {
      alert("비밀번호 변경했습니다. ");
      setShow(false);
    } else {
      alert(data);
      setShow(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>비밀번호 변경</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="searchdata">
            <ul>
              <li style={{ width: "300px" }}>
                <div className="divide50">
                  <font color="grey">⦁</font> 아이디
                </div>
                <input
                  type="text"
                  className="fill"
                  name="userId"
                  disabled={true}
                  value={userInfo.userId}
                />
              </li>
              <li style={{ width: "300px" }}>
                <div className="divide50">
                  <font color="grey">⦁</font> 변경비밀번호
                </div>
                <input
                  type="password"
                  className="fill"
                  name="password"
                  onChange={handleChangeField}
                  value={password}
                />
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            취소
          </Button>

          <Button variant="primary" onClick={() => handleOk(true)}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalChangePwdPop = createModal(ChangePwdPop);

export default ChangePwdPop;
