import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { Close } from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";

import useAppContext, { actions } from "../../utils/Reducers";
import LeftMenuBar from "./MenuBar/LeftMenuBar";
import TopMenuBar from "./MenuBar/TopMenuBar";
import MainPage from "../../pages/MainPage";
import { useAxiosWithAuthorization } from "../../utils/api";
import ModalFactory from "react-modal-promise";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWindowDimensions from "../../utils/validators";
import ham1 from "../../images/ham1.png";

const Layout = ({ children }) => {
  const dashBoard = "/Main";
  const {
    dispatch,
    store: {
      userInfo,
      previousMenuKey,
      menuKey,
      menuActionList,
      isAuthenticated,
    },
  } = useAppContext();

  const [activeTab, setActiveTab] = useState(dashBoard);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true); // 왼쪽 버튼 활성화 상태
  const [isRightDisabled, setIsRightDisabled] = useState(false); // 오른쪽 버튼 활성화 상태

  const tabMenusRef = useRef(null); // tab-menus 컨테이너용 ref
  const tabScrollContainerRef = useRef(null); // 탭 스크롤 컨테이너용 ref
  let navigate = useNavigate(); //<-- this doesn't work!!!!

  // 탭을 좌우로 스크롤
  const scrollTabs = (direction) => {
    if (tabScrollContainerRef.current && tabMenusRef.current) {
      const scrollLeft = tabScrollContainerRef.current.scrollLeft;
      const visibleTabs = Array.from(tabMenusRef.current.children);

      // 현재 보이는 첫 번째 탭 찾기
      const firstVisibleTab = visibleTabs.find(
        (tab) => tab.offsetLeft >= scrollLeft
      );

      // 현재 보이는 첫 번째 탭의 너비
      const scrollDistance = firstVisibleTab ? firstVisibleTab.offsetWidth : 0;

      // 스크롤 가능한 남은 거리 계산
      const maxScrollableDistance =
        direction === "left"
          ? scrollLeft
          : tabScrollContainerRef.current.scrollWidth -
            tabScrollContainerRef.current.clientWidth -
            scrollLeft;

      // 남은 스크롤 거리와 현재 탭의 너비 비교
      const finalScrollDistance =
        Math.abs(maxScrollableDistance) < scrollDistance
          ? maxScrollableDistance
          : scrollDistance;

      // 최종적으로 계산된 거리만큼 스크롤
      tabScrollContainerRef.current.scrollBy({
        left: direction === "left" ? -finalScrollDistance : finalScrollDistance,
        behavior: "smooth",
      });
    }
  };

  //사용자메뉴정보 검색 URL
  const SERARCH_USER_MENU_URL = "/common/menu/searchUserMenuList";

  const [, fetchMenuList] = useAxiosWithAuthorization(
    {
      url: SERARCH_USER_MENU_URL,
    },
    { manual: true }
  );

  const [menuData, setMenuData] = useState(null);
  const getMenuList = async () => {
    const {
      data: { data },
    } = await fetchMenuList({
      params: {
        userId: userInfo.userId,
      },
    });

    dispatch(actions.setMenuList(data));
    dispatch(actions.setMenuOriginList(data));
    setMenuData(data);
  };

  useEffect(() => {
    getMenuList();

    dispatch(actions.setMenuKey(dashBoard));
    dispatch(actions.setTopMenuKey("0000001000"));
  }, []);

  const [key, setKey] = useState("bar");
  const [menuList, setMenuList] = useState([]);

  // ANCHOR: 메뉴 한개씩 닫기
  const handleTabCloseClick = (tabKey, event) => {
    event.stopPropagation();
    dispatch(
      actions.setMenuActionList(
        menuActionList
          .map((data) => data.menuId !== tabKey && data)
          .filter((data) => data !== false)
      )
    );

    const found = menuActionList.findIndex(
      (element) => element.menuId === menuKey
    );
    const found_prev = menuActionList.findIndex(
      (element) => element.menuId === previousMenuKey
    );
    console.log(tabKey, menuKey, previousMenuKey);
    if (menuActionList && menuActionList.length - 1 > 0) {
      if (found >= 0) {
        // NOTE: 현재 Focusing된 page가 열려있다면 현상 유지
        if (tabKey === menuKey) {
          if (found_prev >= 0 && tabKey !== previousMenuKey) {
            dispatch(actions.setMenuKey(previousMenuKey));
          } else {
            dispatch(
              actions.setMenuKey(
                menuActionList[menuActionList.length - 2].menuId
              )
            );
          }
        } else {
          dispatch(actions.setMenuKey(menuKey));
        }
      } else {
        if (found_prev >= 0) {
          dispatch(actions.setMenuKey(previousMenuKey));
        } else {
          dispatch(
            actions.setMenuKey(menuActionList[menuActionList.length - 1].menuId)
          );
        }
      }
    } else {
      // NOTE: 모든 Tab이 닫혔을 경우 dashBoard로 이동
      dispatch(actions.setMenuKey(dashBoard));
    }
  };

  const handleTabOpenClick = (tabKey) => {
    setActiveTab(tabKey); // 클릭된 탭을 활성화된 탭으로 설정
    console.log(tabKey);
    const foundMenu = menuActionList.find(
      (element) => element.menuId === tabKey
    );
    // ANCHOR: 열려질 Tab
    // ANCHOR: 이전 Tab 기억

    dispatch(actions.setPreviousMenuKey(menuKey));
    dispatch(actions.setMenuKey(tabKey));
    if (foundMenu) {
      dispatch(actions.setTopMenuKey(foundMenu.rootMenuId));
    }
  };

  useEffect(() => {
    setActiveTab(menuKey);
  }, [menuKey]);

  const ref = useRef(null);

  const { height, width } = useWindowDimensions();
  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    console.log(ref?.current?.offsetWidth);
    dispatch(actions.setTabSize(ref?.current?.offsetWidth - 62));
  }, [height, width]);

  //_this : 적용시킬 element
  //_class : 적용시킬 class
  //_type : 선택 타입
  function menuTab(_this, _class) {
    selectClass(_this, _class);
    toggleClass("menuList", "favorite");
  }

  //_index : 인덱스
  //_contents : 콘텐츠 id
  //_this : 적용시킬 element
  //_class : 적용시킬 class

  function selectTab(_index, _contents, _this, _class, _type) {
    var mobileTab = document.getElementById("mMenuTabs");
    var pcTab = document.getElementById("pcMenuTabs");

    selectClass(_this, _class);
    //pc또는모바일도 같이 활성화한다.
    if (_type == "pc") {
      selectClass(mobileTab.children[_index], _class);
    } else {
      selectClass(pcTab.children[_index], _class);
    }

    var contents = document.getElementById(_contents);
    selectClass(contents.children[_index], _class);
  }

  //_this : 적용시킬 element
  //_class : 적용시킬 class
  //_toggle : 토글여부
  function selectClass(_this, _class, _toggle) {
    if (_toggle) {
      if (hasClass(_this, "on") === false) {
        addClass(_this, "on");
      } else {
        removeClass(_this, "on");
      }
    } else {
      if (hasClass(_this, "on") === false) {
        addClass(_this, "on");
      }
    }

    var sb = siblings(_this);
    for (var i = 0; i < sb.length; i++) {
      removeClass(sb[i], "on");
    }
  }

  //_target : 적용시킬 element
  //_class : 적용시킬 class
  function toggleClass(_target, _class) {
    var menu = document.getElementById(_target);
    if (hasClass(menu, _class)) {
      removeClass(menu, _class);
    } else {
      addClass(menu, _class);
    }
    dispatch(actions.setTabSize(ref?.current?.offsetWidth - 62));
  }

  function addClass(element, className) {
    element.className += " " + className;
  }
  function removeClass(element, className) {
    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
    element.className = element.className.replace(check, " ").trim();
  }

  function hasClass(element, className) {
    if (element.className.indexOf(className) > -1) {
      return true;
    } else {
      return false;
    }
  }

  function siblings(element) {
    var children = element.parentElement.children;
    var tempArr = [];

    for (var i = 0; i < children.length; i++) {
      tempArr.push(children[i]);
    }

    return tempArr.filter(function (e) {
      return e != element;
    });
  }

  if (isAuthenticated) {
    return (
      <>
        <ModalFactory />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
        />
        {menuData && (
          <div className="screen" id="container">
            <LeftMenuBar handleTabOpenClick={handleTabOpenClick} />
            <div className="mainbox">
              <TopMenuBar />
              <div id="content" ref={ref}>
                <div className="box_greytap">
                  <div
                    className="ham"
                    onClick={() => toggleClass("container", "open")}
                  >
                    <img src={ham1} alt="Menu Toggle" />
                  </div>

                  {/* 탭 헤더 영역 */}
                  <div className="tab-container" ref={tabScrollContainerRef}>
                    <div className="tab-menus" ref={tabMenusRef}>
                      <div
                        className={`tab-menu ${
                          activeTab === dashBoard ? "active" : ""
                        }`}
                        onClick={() => handleTabOpenClick(dashBoard)}
                      >
                        대시보드
                      </div>
                      {menuActionList.map((menu) => (
                        <div
                          key={menu.menuId}
                          className={`tab-menu ${
                            activeTab === menu.menuId ? "active" : ""
                          }`}
                          onClick={() => handleTabOpenClick(menu.menuId)}
                        >
                          {menu.menuNmKor}
                          <span
                            onClick={(event) =>
                              handleTabCloseClick(menu.menuId, event)
                            }
                            className="x"
                          >
                            <Close />
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  {width > 768 && ( // 화면이 768px보다 클 때만 버튼 보이기
                    <>
                      <button
                        className="scroll-button left"
                        onClick={() => scrollTabs("left")}
                      >
                        {""}
                      </button>
                      <button
                        className="scroll-button right"
                        onClick={() => scrollTabs("right")}
                      >
                        {""}
                      </button>
                    </>
                  )}
                </div>

                {/* 탭 콘텐츠 영역 */}
                <div className="tab-content">
                  {/* MainPage 콘텐츠, 대시보드 탭에 해당 */}
                  <div
                    className="tab-content_visible"
                    style={{
                      display: activeTab === dashBoard ? "block" : "none",
                    }}
                  >
                    <div className="tab-content-wrap">
                      <MainPage
                        menuInfo={menuData?.find(
                          (element) => element.menuId === "0000004010"
                        )}
                      />
                    </div>
                  </div>

                  {/* 동적으로 추가된 탭 콘텐츠 */}
                  {menuActionList.map((menu) => (
                    <div
                      className="tab-content_visible"
                      key={menu.menuId}
                      style={{
                        display: activeTab === menu.menuId ? "block" : "none",
                      }}
                    >
                      <div className="tab-content-wrap">
                        <React.Suspense fallback={<Loading />}>
                          <menu.component menuInfo={menu} />
                        </React.Suspense>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    navigate("/login"); //<-- this doesn't work!!!!
    return null;
  }
};

export default Layout;
