import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { create } from "react-modal-promise";

const MenuListPop = ({ title, menuOriginList, menuList, onSelectMenu }) => {
  console.log(menuList);
  const [show, setShow] = useState(true);

  const handleClose = (returnData) => {
    setShow(false);
  };

  const handleOk = (returnData) => {
    setShow(false);
  };

  const onMenuClick = (menu) => {
    onSelectMenu(menu);
    handleClose(false);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose(false)}
        size="lg"
        className="sitemap"
      >
        <Modal.Body
          style={{
            padding: "0px",
            position: "relative",
          }}
        >
          <nav
            className="nav-box"
            style={{
              paddingLeft: "0px",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            <div class="modal-logo"></div>
            <button
              type="button"
              className="btn-close close-top"
              aria-label="Close"
              onClick={handleOk}
            ></button>
            <div
              style={{
                padding: "2.3rem 0",
                width: "110%",
                backgroundColor: "#f2f4f7",
              }}
            >
              <h4 style={{ marginLeft: "2rem" }}>{title}</h4>
            </div>
            <ul className="gnb-nav">
              {menuOriginList &&
                menuOriginList
                  .filter(
                    (item) => item.upperMenuId !== null && item.menuLvl === 1
                  )
                  .map((menu) => (
                    <>
                      <li>
                        <a href onClick={() => {}}>
                          {menu.menuNmKor}
                        </a>
                        <div className="depth2">
                          <ul>
                            {menuList &&
                              menuList
                                .filter(
                                  (subItem) =>
                                    subItem.upperMenuId === menu.menuId &&
                                    subItem.menuLvl === 2
                                )
                                .map((subMenu) => (
                                  <>
                                    <li>
                                      <a
                                        href
                                        onClick={(event) =>
                                          onMenuClick(subMenu)
                                        }
                                      >
                                        {subMenu.menuNmKor}
                                      </a>
                                    </li>
                                  </>
                                ))}
                          </ul>
                        </div>
                      </li>
                    </>
                  ))}
            </ul>
          </nav>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const ModalMenuListPop = create(MenuListPop);

export default MenuListPop;
