export const columnLayout = [
  {
    dataField: "vendorCd",
    headerText: "중상업체코드",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "vendorNm",
    headerText: "중상업체명",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
];
