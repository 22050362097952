import React, { useCallback, useState } from "react";
import SelectBox from "../../../components/SelectBox";
import useAppContext from "../../../utils/Reducers";

const SearchBar = (props) => {
  const {
    store: { userInfo },
  } = useAppContext();

  // SerachBar 기본 필드
  const [initFields, setInitFields] = useState({
    schRvndrNm: "",
    schVndrNm: "",
    schLoadAreaNm: "",
    schAddr: "",
    schStatusCd: "",
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;

    setInitFields({ ...initFields, [name]: value });
    props.setSearchParameters({ ...initFields, [name]: value });
  });

  return (
    <>
      <div className="searchdata">
        <ul>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 실공급업체명
            </div>
            <input
              type="text"
              className="datainput"
              name="schRvndrNm"
              onChange={handleChangeField}
              value={initFields.schRvndrNm}
            />
          </li>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 공급업체명
            </div>
            <input
              type="text"
              className="datainput"
              name="schVndrNm"
              onChange={handleChangeField}
              value={initFields.schVndrNm}
            />
          </li>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 상차지명
            </div>
            <input
              type="text"
              className="datainput"
              name="schLoadAreaNm"
              onChange={handleChangeField}
              value={initFields.schLoadAreaNm}
            />
          </li>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 주소
            </div>
            <input
              type="text"
              className="datainput"
              name="schAddr"
              onChange={handleChangeField}
              value={initFields.schAddr}
            />
          </li>
          <li>
            <div className="divide50">
              <font color="grey">⦁</font> 승인여부
            </div>
            <SelectBox
              mastCd="STATUS_CD"
              name="schStatusCd"
              topSelected="선택"
              onChange={handleChangeField}
              className="datainput"
              value={initFields.schStatusCd}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(SearchBar);
