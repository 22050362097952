import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./columnLayout";

const MidVendorSearchPop = ({
  callbackFunc,
  searchTxt,
  autoSelect,
  failCallbackFunc,
  onResolve,
}) => {
  const [show, setShow] = useState(true);

  const myGrid = useRef();
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");

    auiGrid.bind("cellDoubleClick", function (event) {
      gridCellDBClick(event.item);
    });

    //if (searchTxt !== undefined && searchTxt !== "") {
    searchCust();
    //}
  }, []);

  const gridCellDBClick = (item) => {
    onResolve(true); //왠진 모르지만 이거 안해주면 에러남..
    callbackFunc(item);
    setShow(false);
  };

  const [searchCnt, setSearchCnt] = useState(1);

  const handleClose = (returnData) => {
    if (returnData) {
      const auiGrid = myGrid.current;
      let selItem = auiGrid.getSelectedItems()[0];
      if (selItem) {
        onResolve(returnData); //왠진 모르지만 이거 안해주면 에러남..
        callbackFunc(selItem.item);
      }
    } else {
      onResolve(returnData);
      if (failCallbackFunc !== undefined) {
        failCallbackFunc();
      }
    }

    setShow(false);
  };

  const SEARCH_CUST_URL = "/company/customer/searchMidVendorList";
  const [, fetchCustData] = useAxiosWithAuthorization(
    {
      url: SEARCH_CUST_URL,
    },
    { manual: true }
  );

  // const INITIAL_SEARCH_FIELDS = {
  //   searchTxt: "", //검색어
  // };

  const [INITIAL_SEARCH_FIELDS, setSearchFields] = useState({
    searchTxt: searchTxt,
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    //console.log(name, value);
    //INITIAL_SEARCH_FIELDS[name] = value;
    //console.log(INITIAL_SEARCH_FIELDS);
    setSearchFields({ searchTxt: value });
  });

  const searchCust = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchCustData({
      url: SEARCH_CUST_URL,
      params: {
        searchTxt: INITIAL_SEARCH_FIELDS.searchTxt,
      },
    });
    auiGrid.setGridData(data);
    //setFieldValues(initItem);
    auiGrid.removeAjaxLoader();

    if (data.length > 0) {
      const auiGrid = myGrid.current;
      auiGrid.setSelectionByIndex(0, 0);
    }
    setSearchCnt(searchCnt + 1);
    if (
      searchTxt !== undefined &&
      data.length === 1 &&
      autoSelect === true &&
      searchCnt === 1
    ) {
      handleClose(true);
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      searchCust();
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>중상업체 팝업</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="searchdata">
              <ul>
                <li>
                  <div className="divide50">
                    <font color="grey">⦁</font> 중상업체명/코드
                  </div>
                  <div className="ip_textandbutton">
                    <div>
                      <input
                        type="text"
                        name="searchTxt"
                        disabled={false}
                        onChange={handleChangeField}
                        onKeyDown={handleKeyDown}
                        className="ip1"
                        value={INITIAL_SEARCH_FIELDS.searchTxt}
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={searchCust}
                        className="btn_ip"
                      >
                        검색
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="databox">
                <div style={{ width: "100%", height: "100%" }}>
                  <AUIGrid
                    ref={myGrid}
                    columnLayout={columnLayout}
                    gridProps={defaultGridProps}
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {"닫기"}
          </Button>
          <Button variant="primary" onClick={() => handleClose(true)}>
            {"확인"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalMidVendorSearchPop = createModal(MidVendorSearchPop);

export default MidVendorSearchPop;
