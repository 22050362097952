import React, { useState, useCallback, useEffect } from "react";
import { useAxiosWithAuthorization } from "../../../../utils/api";
import useAppContext from "../../../../utils/Reducers";
import osq from "../../../../images/osq.png";
import SelectBox from "../../../../components/SelectBox";
import { isFieldDisabled } from "../../../../utils/validators";
import EditorBox from "../../../../components/EditorBox";
import { ModalConfirm } from "../../../../components/Confirm";
import "moment/locale/ko";
import moment from "moment";
import DSFileUpload from "../../../../components/DSFileUpload";

const BoardReg = (props) => {
  const SERARCH_BOARDINFO_URL = "/common/board/searchBoardInfo.do";
  const SAVE_BOARD_URL = "/common/board/saveBoard.do";
  const DELETE_BOARD_URL = "/common/board/deleteBoard.do";

  const [primaryParameters, setPrimaryParameters] = useState({
    boardId: props.boardId,
  });
  const [state, setState] = useState("created");

  //추가, 수정일때 항목의 disabled 결정
  const disabledFields = {
    created: [""],
    updated: ["boardId", "popupStartDt", "popupEndDt"],
  };
  const {
    store: { userInfo },
  } = useAppContext();

  //검색조건
  const setSearchParameters = (name, value) => {
    setPrimaryParameters({ ...primaryParameters, [name]: value });
  };

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_BOARDINFO_URL,
    },
    { manual: true }
  );

  const initItem = {
    status: "C",
    boardId: "",
    boardTypeCd: "",
    boardTitle: "",
    boardContent: "",
    regId: userInfo.userId,
    regNm: userInfo.nmKor,
    regDt: moment().format("YYYY-MM-DD"),
    popupYn: "N",
    popupStartDt: moment().format("YYYY-MM-DD"),
    popupEndDt: moment().format("YYYY-MM-DD"),
    isChecked: false,
    fileList: [],
  };

  const [fieldValues, setFieldValues] = useState(initItem);
  const [fileList, setFileList] = useState([]);

  const searchBoardInfo = async () => {
    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_BOARDINFO_URL,
      params: primaryParameters,
    });
    //서버 호출시 array로 리턴되기 때문에 하나의 데이터를 가져오기 위해 data[0]을 해줘야함
    console.log(data[0]);
    data[0].status = "U";
    setFieldValues(data[0]);
    setState("updated");
  };
  //코드 및 그리드 초기화 및 생성
  useEffect(() => {
    console.log(props.boardId);
    if (props.boardId !== "") {
      searchBoardInfo();
    }
  }, []);

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;

    console.log("name === " + name + " || value === " + value);
    if (name === "popupYn") {
      //팝업여부를 체크하면 팝업게시일자를 선택할 수 있도록 활성화
      if (event.target.checked) {
        document.getElementById("popupStartDt").disabled = false;
        document.getElementById("popupEndDt").disabled = false;
        setFieldValues({ ...fieldValues, [name]: "Y", ["isChecked"]: true });
      } else {
        document.getElementById("popupStartDt").disabled = true;
        document.getElementById("popupEndDt").disabled = true;
        setFieldValues({ ...fieldValues, [name]: "N", ["isChecked"]: false });
      }
    } else {
      setFieldValues({
        ...fieldValues,
        [name]: value,
      });
    }
  });
  const handleMasterDelete = async () => {
    if (
      await ModalConfirm({
        title: "삭제",
        description: "데이터를 삭제하시겠습니까?",
      })
    ) {
      const {
        data: { data },
      } = await fetchData({
        url: DELETE_BOARD_URL,
        data: primaryParameters,
        method: "POST",
      });

      props.setStatus("list");
    }
  };

  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      console.log(fieldValues);
      if (fieldValues.boardTypeCd === "") {
        ModalConfirm({
          title: "필수항목체크",
          description: "게시판구분을 선택하지 않았습니다. ",
          type: "alert",
        });
        return;
      } else if (fieldValues.boardTitle === "") {
        ModalConfirm({
          title: "필수항목체크",
          description: "게시판제목을 입력하지 않았습니다. ",
          type: "alert",
        });
        return;
      } else if (fieldValues.boardContent === "") {
        ModalConfirm({
          title: "필수항목체크",
          description: "게시판내용을 입력하지 않았습니다. ",
          type: "alert",
        });
        return;
      }
      var params = fieldValues;
      params.fileList = fileList;
      const {
        data: { data },
      } = await fetchData({
        url: SAVE_BOARD_URL,
        data: params,
        method: "POST",
      });

      props.setStatus("list");
    }
  };
  const handleMasterList = () => {
    props.setStatus("list");
  };

  return (
    <>
      <div className="flexible_button_box">
        <div className="title">
          <h2>
            {props?.menuInfo?.menuNmKor}
            <button type="button" className="page_favorite">
              즐겨찾기
            </button>
          </h2>
        </div>
        <div className="btnbox">
          <ul className="select_db">
            <li>
              <button
                type="button"
                className="btn_greystyle"
                onClick={handleMasterList}
              >
                목록
              </button>
            </li>
            {props?.boardId !== "" ? (
              <li>
                <button
                  type="button"
                  className="btn_greystyle"
                  onClick={handleMasterDelete}
                >
                  삭제
                </button>
              </li>
            ) : (
              ""
            )}

            <li>
              <button
                type="button"
                className="btn_greystyle"
                onClick={handleMasterSave}
              >
                저장
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="flexible_contents_box">
        <div className="databox">
          <h5>
            <img src={osq} /> &nbsp;게시판정보 &nbsp;
            <br />
          </h5>
          <ul className="t_form">
            <li className="doublehalf">
              <div className="title">게시판아이디</div>
              <div className="formbox">
                <input
                  type="text"
                  name="boardId"
                  id="boardId"
                  onChange={handleChangeField}
                  disabled={true}
                  value={fieldValues.boardId}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>게시판구분
              </div>
              <div className="formbox">
                <SelectBox
                  mastCd="BOARD_TYPE_CD"
                  id="boardTypeCd"
                  name="boardTypeCd"
                  topSelected="선택"
                  disabled={isFieldDisabled(
                    state,
                    "boardTypeCd",
                    disabledFields
                  )}
                  onChange={handleChangeField}
                  value={fieldValues.boardTypeCd}
                  className="ip2"
                />
              </div>
            </li>
            <li className="half">
              <div className="title">
                <span className="red">*</span>게시판 제목
              </div>
              <div className="formbox">
                <input
                  type="text"
                  name="boardTitle"
                  id="boardTitle"
                  onChange={handleChangeField}
                  disabled={isFieldDisabled(
                    state,
                    "boardTitle",
                    disabledFields
                  )}
                  value={fieldValues.boardTitle}
                  className="ip2"
                />
              </div>
            </li>
            <li className="half" style={{ height: "350px" }}>
              <div className="title">
                <span className="red">*</span>게시판 내용
              </div>
              <div className="formbox">
                <div>
                  <EditorBox
                    height={"350px"}
                    name="boardContent"
                    id="boardContent"
                    data={fieldValues.boardContent}
                    initialEditType="wysiwyg"
                    disabled={isFieldDisabled(
                      state,
                      "boardTitle",
                      disabledFields
                    )}
                    onChange={handleChangeField}
                  />
                </div>
              </div>
            </li>
            <li className="half" style={{ height: "100%" }}>
              <div className="title">
                <span className="red">*</span>첨부파일
              </div>
              <div className="formbox">
                <div>
                  <DSFileUpload
                    setFileList={setFileList}
                    boardId={props.boardId}
                  />
                </div>
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>등록자명
              </div>
              <div className="formbox">
                <input
                  name="regNm"
                  id="regNm"
                  onChange={handleChangeField}
                  disabled={true}
                  value={fieldValues.regNm}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">
                <span className="red">*</span>등록자아이디
              </div>
              <div className="formbox">
                <input
                  name="regId"
                  id="regId"
                  disabled={true}
                  onChange={handleChangeField}
                  value={fieldValues.regId}
                  className="ip2"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">팝업여부</div>
              <div className="ip_textandbutton">
                <input
                  type="checkbox"
                  name="popupYn"
                  id="popupYn"
                  checked={fieldValues.isChecked}
                  disabled={isFieldDisabled(state, "popupYn", disabledFields)}
                  onChange={handleChangeField}
                  className="checker"
                />
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">팝업게시일자</div>
              <div className="ip_textandbutton">
                <div>
                  <input
                    type="date"
                    id="popupStartDt"
                    name="popupStartDt"
                    disabled={fieldValues.popupYn === "Y" ? false : true}
                    onChange={handleChangeField}
                    value={fieldValues.popupStartDt}
                    className="ip2"
                  />
                </div>
              </div>
            </li>
            <li className="doublehalf">
              <div className="title">팝업종료일자</div>
              <div className="formbox">
                <input
                  type="date"
                  id="popupEndDt"
                  name="popupEndDt"
                  disabled={fieldValues.popupYn === "Y" ? false : true}
                  //disabled={true}
                  onChange={handleChangeField}
                  value={fieldValues.popupEndDt}
                  className="ip2"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BoardReg;
