import { useEffect } from "react";
import "./appinfo.css";

const AppInfo = () => {
  const setMetaTags = () => {
    document.getElementsByTagName("META")[1].content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover";
  };

  const setMetaRestore = () => {
    document.getElementsByTagName("META")[1].content = "width=1280,height=1024";
  };

  useEffect(() => {
    setMetaTags();
    return () => {
      setMetaRestore();
    };
  }, []);

  const redireactApp = () => {
    exeDeepLink();
    checkInstallApp();
  };

  function checkInstallApp() {
    function clearTimers() {
      clearInterval(check);
      clearTimeout(timer);
    }

    function isHideWeb() {
      if (document.webkitHidden || document.hidden) {
        clearTimers();
      }
    }
    const check = setInterval(isHideWeb, 200);

    const timer = setTimeout(function () {
      redirectStore();
    }, 500);
  }

  const redirectStore = () => {
    //const ua = navigator.userAgent.toLowerCase();

    if (window.confirm("TMS 앱 안내 페이지로\n이동하시겠습니까?")) {
      // location.href =
      //   ua.indexOf("android") > -1
      //     ? "https://play.google.com/store/apps/details?id=xxx"
      //     : "https://apps.apple.com/kr/app/xxx";
      window.location.href = "http://wss.ekosco.com/";
    }
  };

  function exeDeepLink() {
    let url = "tmsapp://statusscreen";
    window.location.href = url;
  }

  return (
    <div className="appoutter">
      <div className="appmodal">
        <p className="apptitle">
          TMS 앱을 실행합니다
          <br />
        </p>
        <div className="appbutton-group">
          <button className="appopen appbtn" onClick={redireactApp}>
            실행
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppInfo;
