import "./App.css";

import DSRouter from "./DSRouter";
import { AppProvider } from "./utils/Reducers";
function App() {
  return (
    <AppProvider>
      <DSRouter />
    </AppProvider>
  );
}

export default App;
