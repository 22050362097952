import React, { useState, useEffect } from "react";
// import useAppContext, { actions } from "../../../utils/Reducers";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import logoutIcon from "../../../images/logout-icon.png";
import { useAxiosWithAuthorization } from "../../../utils/api";
import SelectBox from "../../../components/SelectBox";

const InHouseCarList = () => {
  const navigate = useNavigate();
  const [isShrink, setIsShrink] = useState(false);
  const [inHouseCars, setInHouseCars] = useState([]);
  const AUTO_LOGIN_FLAG = "AUTO_LOGIN_FLAG";
  const AUTO_LOGIN_FLAG_2 = "AUTO_LOGIN_FLAG_2";

  // const { dispatch } = useAppContext();

  const [fieldValues, setFieldValues] = useState({
    category: "",
    carNo: "",
  });

  const SERARCH_INHOUSECAR_URL = "/inhousecar/inhousecar/searchInHouseCarList";
  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_INHOUSECAR_URL,
    },
    { manual: true }
  );

  const handleSearch = async () => {
    try {
      const searchParams = {
        category: fieldValues.category,
        carNo: fieldValues.carNo,
      };
      const response = await fetchData({ params: searchParams });
      setInHouseCars(response.data.data);
    } catch (error) {
      console.error("데이터 조회 실패:", error);
    }
  };

  const handleScroll = () => setIsShrink(window.scrollY > 0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleChangeField = (event) => {
    const { name, value } = event.target;
    setFieldValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogout = () => {
    // dispatch(actions.logout());
    localStorage.setItem(AUTO_LOGIN_FLAG, "false");
    localStorage.setItem(AUTO_LOGIN_FLAG_2, "false");
    navigate("/Loginm", { replace: true });
  };

  return (
    <>
      <GlobalStyle />
      <FixedHeader className={isShrink ? "shrink" : ""}>
        <HeaderContent>
          <Title>사내차량조회</Title>
          <LogoutButton onClick={handleLogout} />
        </HeaderContent>

        <FormWrapper className={isShrink ? "shrink" : ""}>
          <FormGroup isShrink={isShrink}>
            <label htmlFor="category">업무구분</label>
            <SelectBox
              mastCd="CATEGORY_GBN_CD"
              name="category"
              topSelected="전체"
              onChange={handleChangeField}
              value={fieldValues.category}
            />
          </FormGroup>
          <FormGroup isShrink={isShrink}>
            <label htmlFor="carNo">차량번호</label>
            <input
              type="text"
              name="carNo"
              // placeholder="12가3456"
              onChange={handleChangeField}
              value={fieldValues.carNo}
            />
          </FormGroup>
        </FormWrapper>
        <SearchButton onClick={handleSearch}>검색</SearchButton>
      </FixedHeader>

      <ScrollableContent>
        {inHouseCars.map((car, index) => (
          <ResultCard key={index}>
            <div className="badge-wrapper">
              <Badge badgeType={`${car.category}`}>{car.categoryNm}</Badge>
            </div>
            <h2>{car.carNo}</h2>
            <ResultDetails>
              {car.rank && (
                <li>
                  <span>계근순번:</span>
                  <div>
                    {car.refSeq} / {car.rank}
                  </div>
                </li>
              )}
              <li>
                <span>거래처:</span> <div>{car.custNm}</div>
              </li>
              {car.areaNm && (
                <li>
                  <span>납품지:</span> <div>{car.areaNm}</div>
                </li>
              )}
              {car.vendorNm && (
                <li>
                  <span>중상업체:</span> <div>{car.vendorNm}</div>
                </li>
              )}
              <li>
                <span>품목:</span> <div>{car.prodNm}</div>
              </li>
              {car.gradeNm && (
                <li>
                  <span>등급:</span> <div>{car.gradeNm}</div>
                </li>
              )}
              <li>
                <span>중량:</span>
                <div>
                  {car.inWeight ? `${car.inWeight.toLocaleString()} KG` : ""}
                </div>
              </li>
              {car.cotNum && (
                <li>
                  <span>컨테이너번호:</span> <div>{car.cotNum}</div>
                </li>
              )}
            </ResultDetails>
          </ResultCard>
        ))}
      </ScrollableContent>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Pretendard', sans-serif;
    background-color: #EEEFF5;
    overflow-y: auto;
  }
`;

const FixedHeader = styled.div`
  background-color: white;
  padding: 40px 32px 32px 32px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: padding 0.3s ease;

  &.shrink {
    padding: 10px 32px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const LogoutButton = styled.button`
  width: 38px;
  height: 38px;
  background-image: url(${logoutIcon});
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: ${({ className }) =>
    className === "shrink" ? "row" : "column"};
  justify-content: ${({ className }) =>
    className === "shrink" ? "space-between" : "center"};
  align-items: ${({ className }) =>
    className === "shrink" ? "center" : "flex-start"};
  gap: 16px;
  transition: all 0.3s ease;
  width: 100%;
`;

const FormGroup = styled.div`
  width: ${({ isShrink }) => (isShrink ? "calc(50% - 8px)" : "100%")};
  transition: width 0.3s ease;

  label {
    font-size: ${({ isShrink }) => (isShrink ? "16px" : "18px")};
    font-weight: 600;
    transition: font-size 0.3s ease;
  }

  select,
  input {
    width: 100%;
    padding: ${({ isShrink }) => (isShrink ? "8px" : "10px")};
    font-size: ${({ isShrink }) => (isShrink ? "14px" : "18px")};
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
`;

const SearchButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  &:hover {
    background-color: #ff7043;
  }

  &:active {
    background-color: #e64a19;
  }
`;

const ScrollableContent = styled.div`
  padding: 420px 32px 15px;
`;

const ResultCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 20px;
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: ${({ badgeType }) => {
    switch (badgeType) {
      case "1":
        return "#ff7043";
      case "2":
        return "#4caf50";
      case "3":
        return "#2196f3";
      case "4":
        return "#ff9800";
      case "5":
        return "#f44336";
      case "6":
        return "#9c27b0";
      case "7":
        return "#3f51b5";
      case "8":
        return "#607d8b";
      case "9":
        return "#795548";
      default:
        return "#333";
    }
  }}};
`;

const ResultDetails = styled.ul`
  font-size: 18px;
  color: #333;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    margin-bottom: 5px;
    width: 100%;

    span {
      font-weight: 600;
      color: #9199a7;
      width: 100px;
    }

    div {
      flex: 1;
    }
  }
`;

export default InHouseCarList;
