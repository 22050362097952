import { useEffect, useState } from "react";
import { useAxiosWithAuthorization } from "../../utils/api";

const SelectBox = (props) => {
  //공통코드 검색 URL
  //const SEARCH_CODE_URL = "/common/code/searchDetailCode";

  const getCodeUrl = () => {
    let _codeUrl = "/common/code/searchDetailCode";
    if (props.erpCode) {
      _codeUrl = "/common/code/searchERPCode";
    }
    //console.log("_codeUrl : " + _codeUrl);
    return _codeUrl;
  };

  const SEARCH_CODE_URL = getCodeUrl();

  const [, fetchCodeData] = useAxiosWithAuthorization(
    {
      url: SEARCH_CODE_URL,
    },
    { manual: true }
  );

  const [codeData, setCodeData] = useState([]);

  const codeSearch = async () => {
    const {
      data: { data },
    } = await fetchCodeData({
      url: SEARCH_CODE_URL,
      params: {
        mastCd: props.mastCd,
        detlCd: props.detlCd,
        useYn: "Y",
        attr1: props.attr1 ? props.attr1 : "",
        attr2: props.attr2 ? props.attr2 : "",
        attr3: props.attr3 ? props.attr3 : "",
        attr4: props.attr4 ? props.attr4 : "",
        attr5: props.attr5 ? props.attr5 : "",
        attr6: props.attr6 ? props.attr6 : "",
        attr7: props.attr7 ? props.attr7 : "",
        attr8: props.attr8 ? props.attr8 : "",
        attr9: props.attr9 ? props.attr9 : "",
        attr10: props.attr10 ? props.attr10 : "",
      },
    });

    setCodeData(data);
  };

  useEffect(() => {
    // codeSearch();
  }, []);

  useEffect(() => {
    codeSearch();
  }, [
    props.mastCd,
    props.detlCd,
    props.attr1,
    props.attr2,
    props.attr3,
    props.attr4,
    props.attr5,
    props.attr6,
    props.attr7,
    props.attr8,
    props.attr9,
    props.attr10,
  ]);

  //console.log("props.label : " + props.label);
  return (
    <select
      onChange={props.onChange}
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      className={props.className}
    >
      {props.topSelected !== undefined ? (
        <option value="">{props.topSelected}</option>
      ) : (
        ""
      )}

      {codeData &&
        codeData.map((code) => (
          <option data-value={code.attr1} value={code.detlCd} key={code.detlCd}>
            {props.label === "" || props.label === undefined
              ? code.cdNmKor
              : props.label === "attr1"
              ? code.attr1
              : props.label === "attr2"
              ? code.attr2
              : props.label === "attr3"
              ? code.attr3
              : props.label === "attr4"
              ? code.attr4
              : props.label === "attr5"
              ? code.attr5
              : props.label === "attr6"
              ? code.attr6
              : props.label === "attr7"
              ? code.attr7
              : props.label === "attr8"
              ? code.attr8
              : props.label === "attr9"
              ? code.attr9
              : props.label === "attr10"
              ? code.attr10
              : ""}
          </option>
        ))}
    </select>
  );
};

export default SelectBox;
