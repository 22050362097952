import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./columnLayout";

const ItemTypeSearchPop = ({
  itemType,
  category,
  callbackFunc,
  searchTxt,
  autoSelect,
  failCallbackFunc,
  onResolve,
}) => {
  const [show, setShow] = useState(true);
  //console.log(itemType, category, callbackFunc, onResolve);
  const myGrid = useRef();
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");

    auiGrid.bind("cellDoubleClick", function (event) {
      gridCellDBClick(event.item);
    });
    console.log(category);
    if (category !== "1") {
      //국내고철빼고
      auiGrid.removeColumn(1);
    }
    //if (searchTxt !== undefined && searchTxt !== "") {
    searchItem();
    //}
  }, []);

  const gridCellDBClick = (item) => {
    onResolve(true); //왠진 모르지만 이거 안해주면 에러남..
    callbackFunc(item);
    setShow(false);
  };

  const [searchCnt, setSearchCnt] = useState(1);

  const handleClose = (returnData) => {
    if (returnData) {
      const auiGrid = myGrid.current;
      let selItem = auiGrid.getSelectedItems()[0];
      if (selItem) {
        onResolve(returnData); //왠진 모르지만 이거 안해주면 에러남..
        callbackFunc(selItem.item);
      }
    } else {
      onResolve(returnData);
      if (failCallbackFunc !== undefined) {
        failCallbackFunc();
      }
    }

    setShow(false);
  };

  const SEARCH_CUST_URL = "/common/code/searchERPCode";
  const [, fetchCustData] = useAxiosWithAuthorization(
    {
      url: SEARCH_CUST_URL,
    },
    { manual: true }
  );

  // const INITIAL_SEARCH_FIELDS = {
  //   mastCd: itemType, //팝업 파라메터로 받은것
  //   detlCd: category, //국내고철,수입고철,환경안전,구매,출하 구분
  //   searchTxt: searchTxt, //품번/품명 검색어
  // };

  const [INITIAL_SEARCH_FIELDS, setSearchFields] = useState({
    mastCd: itemType, //팝업 파라메터로 받은것
    detlCd: category, //국내고철,수입고철,환경안전,구매,출하 구분
    searchTxt: searchTxt, //품번/품명 검색어
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    //console.log(name, value);
    //INITIAL_SEARCH_FIELDS[name] = value;
    setSearchFields({ ...INITIAL_SEARCH_FIELDS, [name]: value });
    //console.log(INITIAL_SEARCH_FIELDS);
  });

  const searchItem = async () => {
    //console.log(INITIAL_SEARCH_FIELDS);

    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchCustData({
      url: SEARCH_CUST_URL,
      params: {
        mastCd: INITIAL_SEARCH_FIELDS.mastCd,
        detlCd:
          INITIAL_SEARCH_FIELDS.detlCd === undefined
            ? "0"
            : INITIAL_SEARCH_FIELDS.detlCd,
        searchTxt: INITIAL_SEARCH_FIELDS.searchTxt,
      },
    });
    auiGrid.setGridData(data);
    //setFieldValues(initItem);
    auiGrid.removeAjaxLoader();

    if (data.length > 0) {
      const auiGrid = myGrid.current;
      auiGrid.setSelectionByIndex(0, 0);
    }
    setSearchCnt(searchCnt + 1);
    //console.log(searchCnt, autoSelect, data.length, searchTxt);
    if (
      searchTxt !== undefined &&
      data.length === 1 &&
      autoSelect === true &&
      searchCnt === 1
    ) {
      handleClose(true);
    }
  };

  // const handleChangeField = useCallback((event) => {
  //   const auiGrid = myGrid.current;
  //   const { name, value } = event.target;
  //   const items = auiGrid.getSelectedItems()[0];
  //   const item = items.item;
  //   console.log("name === " + name + " || value === " + value);

  //   if (name === "telNo" || name === "mobileNo") {
  //     //전화번호 관련 함수 정의
  //     const regex = /^[0-9\b -]{0,13}$/;
  //     if (regex.test(value)) {
  //       const phone = ajusta(value);
  //       item[name] = phone;
  //       setFieldValues({ ...fieldValues, [name]: phone });
  //     }
  //   } else {
  //     item[name] = value;
  //     setFieldValues({ ...fieldValues, [name]: value });
  //   }
  //   auiGrid.updateRow(item, "selectedIndex");
  // });

  const handleKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      searchItem();
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ERP품목 팝업</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="searchdata">
              <ul>
                <li>
                  <div className="divide50">
                    <font color="grey">⦁</font> 품목코드/명
                  </div>
                  <div className="ip_textandbutton">
                    <div>
                      <input
                        type="text"
                        name="searchTxt"
                        disabled={false}
                        onChange={handleChangeField}
                        onKeyDown={handleKeyDown}
                        className="ip1"
                        value={INITIAL_SEARCH_FIELDS.searchTxt}
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={searchItem}
                        className="btn_ip"
                      >
                        검색
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <AUIGrid
                ref={myGrid}
                columnLayout={columnLayout}
                gridProps={defaultGridProps}
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {"닫기"}
          </Button>
          <Button variant="primary" onClick={() => handleClose(true)}>
            {"확인"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalItemTypeSearchPop = createModal(ItemTypeSearchPop);

export default ItemTypeSearchPop;
