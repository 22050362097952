import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import useAppContext, { actions } from "../../../../utils/Reducers";
import logo from "../../../../images/logo@2x.png";
import { lazy } from "react";
import { PageImportLazy } from "../../../../DSRouter";

import staricon from "../../../../images/star.png";
import hkslogo from "../../../../images/color_logo.png";
import yklogo from "../../../../images/logo_new.png";
import XMLID_15_ from "../../../../images/XMLID_15_.png";
import user from "../../../../images/user.png";
import colorLogo from "../../../../images/color_logo.png";
import ham1 from "../../../../images/ham1.png";
import hkslogo_o from "../../../../images/color_logo.png";
import greyturnoff from "../../../../images/greyturnoff.png";
import { ModalMenuListPop } from "../../../../pages/Popup/MenuListPop";
import { ModalChangePwdPop } from "../../../../pages/Popup/ChangePwdPop";
import { getStorageItem } from "../../../../hooks/Storage/useLocalStorage";
import { ModalConfirm } from "../../../Confirm";

const LeftMenuBar = ({ handleTabOpenClick }) => {
  //let navigate = useNavigate(); //<-- this doesn't work!!!!
  const [menuData, setMenuData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const {
    store: {
      topMenuKey,
      menuKey,
      menuActionList,
      menuList,
      menuOriginList,
      menuInfo,
      userInfo,
      isAuthenticated,
    },
    dispatch,
  } = useAppContext();

  const handleClick = (menu, topMenu) => {
    console.log(menu.menuUrl);
    const foundMenu = menuActionList.find(
      (element) => element.menuId === menu.menuId
    );
    console.log(foundMenu);
    console.log(menu?.menuUrl);
    if (foundMenu !== undefined || !menu?.menuUrl) {
      dispatch(actions.setPreviousMenuKey(menuKey));
      dispatch(actions.setMenuKey(menu.menuId));
      dispatch(actions.setTopMenuKey(topMenu));
      handleTabOpenClick(menu.menuId); // 활성화된 탭 반영
      return;
    }
    console.log(3);
    menu["component"] = PageImportLazy(menu.menuUrl);
    menuActionList.push(menu);
    dispatch(actions.setMenu(menu));
    dispatch(actions.setMenuActionList(menuActionList));
    dispatch(actions.setPreviousMenuKey(menuKey));
    dispatch(actions.setMenuKey(menu.menuId));
    dispatch(actions.setTopMenuKey(topMenu));
    handleTabOpenClick(menu.menuId); // 새 탭을 활성화
  };

  const handleLogout = async (e) => {
    let yn = await ModalConfirm({
      title: "저장",
      description: "로그아웃 하시겠습니까?",
    });
    if (yn === true) {
      dispatch(actions.logout());
    }
  };

  const handleChangePassword = (e) => {
    ModalChangePwdPop();
  };
  //_this : 적용시킬 element
  //_class : 적용시킬 class
  //_type : 선택 타입
  function menuTab(_this, _class) {
    selectClass(_this, _class);
    toggleClass("menuList", "favorite");
  }

  //_index : 인덱스
  //_contents : 콘텐츠 id
  //_this : 적용시킬 element
  //_class : 적용시킬 class

  function selectTab(_index, _contents, _this, _class, _type) {
    var mobileTab = document.getElementById("mMenuTabs");
    var pcTab = document.getElementById("pcMenuTabs");

    selectClass(_this, _class);
    //pc또는모바일도 같이 활성화한다.
    if (_type == "pc") {
      selectClass(mobileTab.children[_index], _class);
    } else {
      selectClass(pcTab.children[_index], _class);
    }

    var contents = document.getElementById(_contents);
    selectClass(contents.children[_index], _class);
  }

  //_this : 적용시킬 element
  //_class : 적용시킬 class
  //_toggle : 토글여부
  function selectClass(_this, _class, _toggle) {
    if (_toggle) {
      if (hasClass(_this, "on") === false) {
        addClass(_this, "on");
      } else {
        removeClass(_this, "on");
      }
    } else {
      if (hasClass(_this, "on") === false) {
        addClass(_this, "on");
      }
    }

    var sb = siblings(_this);
    for (var i = 0; i < sb.length; i++) {
      removeClass(sb[i], "on");
    }
  }

  //_target : 적용시킬 element
  //_class : 적용시킬 class
  function toggleClass(_target, _class) {
    var menu = document.getElementById(_target);
    if (hasClass(menu, _class)) {
      removeClass(menu, _class);
    } else {
      addClass(menu, _class);
    }
  }

  function addClass(element, className) {
    element.className += " " + className;
  }
  function removeClass(element, className) {
    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
    element.className = element.className.replace(check, " ").trim();
  }

  function hasClass(element, className) {
    if (element.className.indexOf(className) > -1) {
      return true;
    } else {
      return false;
    }
  }

  function siblings(element) {
    var children = element.parentElement.children;
    var tempArr = [];

    for (var i = 0; i < children.length; i++) {
      tempArr.push(children[i]);
    }

    return tempArr.filter(function (e) {
      return e != element;
    });
  }

  const onMainClick = () => {
    window.location.reload(false);
  };

  const onMenuList = () => {
    ModalMenuListPop({
      title: "메뉴목록",
      menuOriginList: menuOriginList,
      menuList: menuList,
      onSelectMenu: onSelectMenu,
    });
  };

  const onFavoriteMenuList = () => {
    let favorMenuList = getStorageItem("favorites");
    console.log(favorMenuList);
    if (favorMenuList === undefined) {
      favorMenuList = [];
    }
    ModalMenuListPop({
      title: "즐겨찾기",
      menuOriginList: menuOriginList,
      menuList: favorMenuList,
      onSelectMenu: onSelectMenu,
    });
  };

  //주소항목에 데이터 넣기
  const onSelectMenu = useCallback((menu) => {
    handleClick(menu, menu.upperMenuId);
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log(searchValue);
      const searchList = menuOriginList.filter(
        (menu) => menu.menuNmKor.indexOf(searchValue) >= 0
      );

      console.log(searchList);

      if (searchList !== undefined) {
        dispatch(actions.setMenuList(searchList));
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchValue(value);
  };

  if (menuList) {
    return (
      <>
        <div className="left_sidebox" id="leftmenu">
          <div className="menulogo">
            <Link to="#" onClick={onMainClick}>
              <img src={colorLogo} />
              <span className="logo">무인계량시스템</span>
            </Link>
            <div className="box_m">
              <div
                className="ham"
                onClick={(event) => toggleClass("leftmenu", "open")}
              >
                <img src={ham1} />
              </div>
              <div className="mobilelogo">
                <img src={hkslogo_o} />
              </div>
              <div className="btn_turnoff">
                <img src={greyturnoff} />
              </div>
            </div>
          </div>
          {/*
          <ul
						className="all_my_tapmenu"
						id="all_my_tapmenu"
						style={{
							paddingLeft: "0px",
						}}
					>
						<li className="on" onClick={onMenuList}>
							메뉴목록
						</li>
						<li className="on" onClick={onFavoriteMenuList}>
							즐겨찾기
						</li>
					</ul>
          */}
          <div className="m">
            <p>시스템관리팀</p>
            <h2>관리자</h2>
          </div>
          <div className="myinfo">
            <div>
              <p>
                {userInfo.dptNm}
                <span> {userInfo.nmKor}님</span>
              </p>
            </div>
            <div className="btns">
              <button
                type="button"
                className="user"
                onClick={handleChangePassword}
              >
                <img src={user} />
              </button>
              <button
                type="button"
                className="btn_turnoff"
                onClick={handleLogout}
              >
                <img src={XMLID_15_} />
              </button>
            </div>
          </div>
          <div className="thisMenu">
            <p>메뉴검색</p>
          </div>
          <div className="searchbox_menu">
            <input
              type="text"
              className="keyword_menu"
              placeholder="메뉴명을 입력하세요"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              variant="outlined"
              value={searchValue}
            />
          </div>
          <div className="mgrid_menu" id="menuList">
            <ul className="select_big" id="mMenuTabs">
              {menuOriginList && //JSON.stringify(menuData)
                menuOriginList.map((data, index) =>
                  data.menuLvl === 1 ? (
                    <li className="on" key={data.menuId}>
                      <button className="m_btnbig">{data.menuNmKor}</button>
                    </li>
                  ) : (
                    ""
                  )
                )}
            </ul>
            <div className="menu_box" id="mMenuContents">
              {menuOriginList && //JSON.stringify(menuData)
                menuOriginList.map((data, index) =>
                  data.menuLvl === 1 && topMenuKey === data.menuId ? (
                    <div className="left_menubox on" key={data.menuId}>
                      <div className="thisMenu">
                        <p>현재메뉴</p>
                      </div>
                      <div className="left_menubox_title">
                        <p>{data.menuNmKor}</p>
                        <button
                          className="btn_fav"
                          onClick={onFavoriteMenuList}
                        ></button>
                      </div>

                      <ul className="menu">
                        {menuList &&
                          menuList
                            .filter(
                              (item) =>
                                item.upperMenuId !== null &&
                                item.menuLvl === 2 &&
                                item.upperMenuId === data.menuId
                            )
                            .map((childData, index) => (
                              <li
                                id={childData.menuId}
                                className={
                                  childData.menuId === menuKey
                                    ? "link on"
                                    : "link"
                                }
                                key={childData.menuId}
                              >
                                <div className="dept_1">
                                  {childData.menuUrl !== null ? (
                                    <Link
                                      key={childData.muenuId}
                                      //to={childData.menuUrl}
                                      to="#"
                                      onClick={(event) => {
                                        console.log(childData.menuUrl);
                                        handleClick(childData, data.menuId);
                                      }}
                                    >
                                      {childData.menuNmKor}
                                    </Link>
                                  ) : (
                                    <Link
                                      key={childData.muenuId}
                                      onClick={(event) => {
                                        selectClass(
                                          event.target.parentElement
                                            .parentElement,
                                          "on",
                                          true
                                        );
                                      }}
                                      to="#"
                                    >
                                      {childData.menuNmKor}
                                    </Link>
                                  )}
                                </div>
                                {childData.menuUrl === null ? (
                                  <div className="submenu">
                                    <ul>
                                      {menuList &&
                                        menuList
                                          .filter(
                                            (subItem) =>
                                              subItem.upperMenuId !== null &&
                                              subItem.menuLvl === 3 &&
                                              subItem.upperMenuId ===
                                                childData.menuId
                                          )
                                          .map((subItemData, index) => (
                                            <li>
                                              {" "}
                                              <Link
                                                key={subItemData.menuId}
                                                to="#"
                                                onClick={(event) => {
                                                  handleClick(
                                                    subItemData,
                                                    data.menuId
                                                  );
                                                }}
                                              >
                                                {subItemData.menuNmKor}
                                              </Link>
                                            </li>
                                          ))}
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default React.memo(LeftMenuBar);
