import React from "react";

import "../../lib/AuiGrid/AUIGrid";
import "../../lib/AuiGrid/AUIGridLicense";
import "../../lib/AuiGrid/AUIGrid.pdfkit";
import "../../lib/AuiGrid/AUIGrid_modern_style.css";

const AUIGrid = window.AUIGrid;

const useAuiGridMethods = (gridId) =>
  React.useMemo(
    () => ({
      addRow(items, rowIndex) {
        AUIGrid.addRow.call(AUIGrid, gridId, items, rowIndex);
      },
      addTreeRow(item, parentRowId, rowPosition) {
        return AUIGrid.addTreeRow.call(
          AUIGrid,
          gridId,
          item,
          parentRowId,
          rowPosition
        );
      },
      bind(name, function_) {
        AUIGrid.bind.call(AUIGrid, gridId, name, function_);
      },
      changeColumnLayout(newLayout) {
        return AUIGrid.changeColumnLayout.call(AUIGrid, gridId, newLayout);
      },
      clearGridData() {
        AUIGrid.clearGridData.call(AUIGrid, gridId);
      },
      create(columnLayout, gridProps) {
        AUIGrid.create(gridId, columnLayout, gridProps);
        // this.__setupEvents();
        // this.__setupGlobalReisze();
        return gridId;
      },
      destroy() {
        AUIGrid.destroy.call(AUIGrid, gridId);
      },
      exportToXlsx(exportProps) {
        AUIGrid.exportToXlsx.call(AUIGrid, gridId, exportProps);
      },
      exportToTxt(exportProps) {
        AUIGrid.exportToTxt.call(AUIGrid, gridId, exportProps);
      },
      getOrgValueItem(rowIndex) {
        return AUIGrid.getOrgValueItem.call(AUIGrid, gridId, rowIndex);
      },
      getAddedRowItems() {
        return AUIGrid.getAddedRowItems.call(AUIGrid, gridId);
      },
      getCellValue(rowIndex, dataField) {
        return AUIGrid.getCellValue.call(AUIGrid, gridId, rowIndex, dataField);
      },
      getCheckedRowItems() {
        return AUIGrid.getCheckedRowItems.call(AUIGrid, gridId);
      },
      getColumnItemByDataField(dataField) {
        return AUIGrid.getColumnItemByDataField.call(
          AUIGrid,
          gridId,
          dataField
        );
      },
      getColumnLayout() {
        return AUIGrid.getColumnLayout.call(AUIGrid, gridId);
      },
      getColumnInfoList() {
        return AUIGrid.getColumnInfoList.call(AUIGrid, gridId);
      },
      getEditedRowColumnItems() {
        return AUIGrid.getEditedRowColumnItems.call(AUIGrid, gridId);
      },
      getEditedRowItems() {
        return AUIGrid.getEditedRowItems.call(AUIGrid, gridId);
      },
      getFitColumnSizeList(fitToGrid) {
        return AUIGrid.getFitColumnSizeList.call(AUIGrid, gridId, fitToGrid);
      },
      getGridData() {
        return AUIGrid.getGridData.call(AUIGrid, gridId);
      },
      getOrgGridData() {
        return AUIGrid.getOrgGridData.call(AUIGrid, gridId);
      },
      getRemovedItems(includeAdded) {
        return AUIGrid.getRemovedItems.call(AUIGrid, gridId, includeAdded);
      },
      getRowCount() {
        return AUIGrid.getRowCount.call(AUIGrid, gridId);
      },
      getSelectedIndex() {
        return AUIGrid.getSelectedIndex.call(AUIGrid, gridId);
      },
      getSelectedItems() {
        return AUIGrid.getSelectedItems.call(AUIGrid, gridId);
      },
      getSelectedRows() {
        return AUIGrid.getSelectedRows.call(AUIGrid, gridId);
      },
      getStateCache() {
        return AUIGrid.getStateCache.call(AUIGrid, gridId);
      },
      getRowsByValue(column, values) {
        return AUIGrid.getRowsByValue.call(AUIGrid, gridId, column, values);
      },
      getItemsByValue(column, value) {
        return AUIGrid.getItemsByValue.call(AUIGrid, gridId, column, value);
      },

      setColumnProp(columnIndex, propertyObject) {
        AUIGrid.setColumnProp.call(AUIGrid, columnIndex, propertyObject);
      },
      setColumnPropByDataField(dataField, object) {
        AUIGrid.setColumnPropByDataField.call(
          AUIGrid,
          gridId,
          dataField,
          object
        );
      },
      hideColumnByDataField(dataField) {
        return AUIGrid.hideColumnByDataField.call(AUIGrid, gridId, dataField);
      },
      isAddedById(uid) {
        return AUIGrid.isAddedById.call(AUIGrid, gridId, uid);
      },
      isEditedById(uid) {
        return AUIGrid.isEditedById.call(AUIGrid, gridId, uid);
      },
      isRemovedById(uid) {
        return AUIGrid.isRemovedById.call(AUIGrid, gridId, uid);
      },

      isCheckedRowById(uid) {
        return AUIGrid.isRemovedById.call(AUIGrid, gridId, uid);
      },

      isCreated() {
        return AUIGrid.isCreated.call(AUIGrid, gridId);
      },
      moveRowsToDown() {
        return AUIGrid.moveRowsToDown.call(AUIGrid, gridId);
      },
      moveRowsToUp() {
        return AUIGrid.moveRowsToUp.call(AUIGrid, gridId);
      },
      removeAjaxLoader() {
        AUIGrid.removeAjaxLoader.call(AUIGrid, gridId);
      },
      removeCheckedRows() {
        return AUIGrid.removeCheckedRows.call(AUIGrid, gridId);
      },
      removeRow(rowIndex) {
        return AUIGrid.removeRow.call(AUIGrid, gridId, rowIndex);
      },
      resetUpdatedItems(flag) {
        AUIGrid.resetUpdatedItems.call(AUIGrid, gridId, flag);
      },
      resize(w, h) {
        AUIGrid.resize.call(AUIGrid, gridId, w, h);
      },
      restoreEditedRows(rowIndex) {
        AUIGrid.restoreEditedRows.call(AUIGrid, gridId, rowIndex);
      },
      setCellValue(rowIndex, dataField, value) {
        AUIGrid.setCellValue.call(AUIGrid, gridId, rowIndex, dataField, value);
      },
      setColumnSizeList(value) {
        return AUIGrid.setColumnSizeList.call(AUIGrid, gridId, value);
      },
      setFixedColumnCount(count) {
        AUIGrid.setFixedColumnCount.call(AUIGrid, gridId, count);
      },
      setFixedRowCount(count) {
        AUIGrid.setFixedRowCount.call(AUIGrid, gridId, count);
      },
      setFooter(footerData) {
        return AUIGrid.setFooter.call(AUIGrid, gridId, footerData);
      },
      setGridData(gridData) {
        AUIGrid.setGridData.call(AUIGrid, gridId, gridData);
      },
      setSelectionByIndex(rowIndex, columnIndex) {
        return AUIGrid.setSelectionByIndex.call(
          AUIGrid,
          gridId,
          rowIndex,
          columnIndex
        );
      },
      setColumnOrder(columns) {
        AUIGrid.setColumnOrder.call(AUIGrid, gridId, columns);
      },

      showAjaxLoader() {
        AUIGrid.showAjaxLoader.call(AUIGrid, gridId);
      },
      showColumnByDataField(dataField) {
        return AUIGrid.showColumnByDataField.call(AUIGrid, gridId, dataField);
      },
      unbind(name, function_) {
        AUIGrid.unbind.call(AUIGrid, gridId, name, function_);
      },
      updateRow(item, rowIndex, isMarkEdited) {
        return AUIGrid.updateRow.call(
          AUIGrid,
          gridId,
          item,
          rowIndex,
          isMarkEdited
        );
      },
      validateChangedGridData(requireFields, message) {
        return AUIGrid.validateChangedGridData.call(
          AUIGrid,
          gridId,
          requireFields,
          message
        );
      },
      validateGridData(requireFields, message) {
        return AUIGrid.validateGridData.call(
          AUIGrid,
          gridId,
          requireFields,
          message
        );
      },
      setCheckedRowsByIds(items) {
        return AUIGrid.setCheckedRowsByIds.call(AUIGrid, gridId, items);
      },
      setCheckedRowsByValue(dataField, value) {
        return AUIGrid.setCheckedRowsByValue.call(
          AUIGrid,
          gridId,
          dataField,
          value
        );
      },
      addCheckedRowsByIds(items) {
        return AUIGrid.addCheckedRowsByIds.call(AUIGrid, gridId, items);
      },
      addUncheckedRowsByIds(items) {
        return AUIGrid.addUncheckedRowsByIds.call(AUIGrid, gridId, items);
      },
      addUncheckedRowsByValue(dataField, value) {
        return AUIGrid.addUncheckedRowsByValue.call(
          AUIGrid,
          gridId,
          dataField,
          value
        );
      },
      setAllCheckedRows(checked) {
        return AUIGrid.setAllCheckedRows.call(AUIGrid, gridId, checked);
      },
      setCsvGridData(csvData, isSimpleMode) {
        AUIGrid.setCsvGridData.call(AUIGrid, gridId, csvData, isSimpleMode);
      },
      getDataFieldByColumnIndex(columnIndex) {
        AUIGrid.setCsvGridData.call(AUIGrid, gridId, columnIndex);
      },
    }),
    [gridId]
  );

export default useAuiGridMethods;
