import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../utils/api";
import useAppContext from "../../../utils/Reducers";
import { useAxiosWithAuthorization } from "../../../utils/api";
const SubButtonBar1 = (props) => {
	const title =
		"[" + props.menuInfo.menuNmEng + "] " + props.menuInfo.menuNmKor;
	const {
		store: { userInfo },
	} = useAppContext();
	//사용자메뉴정보 검색 URL
	const SERARCH_MENU_AUTH_URL = "/common/menu/searchMenuAuthInfo";
	const [menuAuth, setMenuAuth] = useState({});

	const [, fetchMenuAuthData] = useAxiosWithAuthorization(
		{
			url: SERARCH_MENU_AUTH_URL,
		},
		{ manual: true }
	);

	const searchMenuAuthInfo = async () => {
		const {
			data: { data },
		} = await fetchMenuAuthData({
			params: {
				userId: userInfo.userId,
				menuId: props.menuInfo.menuId,
			},
		});

		setMenuAuth(data[0]);
	};

	useEffect(() => {
		searchMenuAuthInfo();
	}, []);

	return (
		<>
			<div className="flexible_button_box pt0">
				<div className="title"></div>
				<div className="btnbox sub">
					<ul className="select_db">
						{menuAuth && (
							<>
								{props.isInitButton === true ? (
									<li>
										<button
											type="button"
											className="btn_greystyle refresh"
											onClick={props.handleMasterInit}
										>
											초기화
										</button>
									</li>
								) : (
									<></>
								)}

								{menuAuth.searchAuthYn === "Y" ? (
									<li>
										<button
											type="button"
											className="btn_greystyle sch"
											onClick={props.handleMasterSearch}
										>
											검색
										</button>
									</li>
								) : (
									<></>
								)}

								{menuAuth.newAuthYn === "Y" ? (
									<li>
										<button
											type="button"
											className="btn_greystyle add"
											onClick={props.handleMasterNew}
										>
											추가
										</button>
									</li>
								) : (
									<></>
								)}

								{menuAuth.delAuthYn === "Y" ? (
									<li>
										<button
											type="button"
											className="btn_greystyle del"
											onClick={props.handleMasterDelete}
										>
											삭제
										</button>
									</li>
								) : (
									<></>
								)}
								{menuAuth.saveAuthYn === "Y" ? (
									<li>
										<button
											type="button"
											className="btn_greystyle save"
											onClick={props.handleMasterSave}
										>
											저장
										</button>
									</li>
								) : (
									<></>
								)}
								{menuAuth.excelAuthYn === "Y" ? (
									<li>
										<button
											type="button"
											className="btn_greystyle"
											onClick={props.handleMasterExcel}
										>
											엑셀
										</button>
									</li>
								) : (
									<></>
								)}

								{menuAuth.printAuthYn === "Y" ? (
									<li>
										<button
											type="button"
											className="btn_greystyle"
											onClick={props.handleMasterPrint}
										>
											출력
										</button>
									</li>
								) : (
									<></>
								)}
							</>
						)}
					</ul>
				</div>
			</div>
		</>
	);
};

export default SubButtonBar1;
