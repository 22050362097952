import Axios from "axios";
import makeUseAxios from "axios-hooks";
import qs from "qs";

import useAppContext, { actions } from "./Reducers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//const API_HOST = "http://localhost:7080";
//const API_HOST = "http://tms.yksteel.co.kr:8080";

export const axiosInstance = Axios.create({
  // baseURL: SERVER_HOST,
  paramsSerializer: (parameters) => {
    const newParameters = Object.entries(parameters).reduce(
      (accumulator, [key, value]) => {
        if (Array.isArray(value) && value.length >= 2) {
          accumulator[key + "__in"] = value;
        } else {
          accumulator[key] = value;
        }
        return accumulator;
      },
      {}
    );
    return qs.stringify(newParameters, { arrayFormat: "comma" });
  },
});
const source = Axios.CancelToken.source();

axiosInstance.interceptors.request.use(
  async (config) => {
    // if (config.method === 'get') {
    //   config.headers.common['X-Http-Method'] = 'GET';
    //   config.params = {
    //     ...config.params,
    //     _: Date.now(),
    //   };
    // } else if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(config.method)) {
    //   config.headers.common['X-Http-Method'] = config.method;
    //   config.method = 'POST';
    // }

    config.cancelToken = source.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useAxiosWithAuthorization = (kwargs, options) => {
  const { store, dispatch } = useAppContext();
  const navigate = useNavigate();

  const [state, fetch] = makeUseAxios(
    {
      axios: axiosInstance,
      headers: {
        // NOTE: 사용자 인증
        Authorization: store && store.Authorization,
        "Access-Control-Allow-Credentials": true,
      },
      ...kwargs,
    },
    // 강제로 cache를 끕니다.
    options ? { ...options, useCache: false } : { useCache: false }
  );

  const { error } = state;

  if (error) {
    console.log(error);
    try {
      if (!error.response) {
        // showNotification({
        //   type: 'error',
        //   message: '서버 오류',
        //   description: `서버에 연결할 수 없습니다.`,
        // });
        console.log(error.response);
        //toast.error("서버에 연결할 수 없습니다.");
        console.log("서버에 연결할 수 없습니다.");
      } else if (error.response.status >= 500) {
        // showNotification({
        //   type: 'error',
        //   message: '서버 오류',
        //   description: `서버 오류가 발생했습니다. (500)`,
        // });
        console.log(error.response);
        toast.error("서버 오류가 발생했습니다. (500)", { toastId: kwargs.url });
        console.log("서버 오류가 발생했습니다. (500)");
      } else if (typeof error.response === "object") {
        // const {
        //   data: { detail: errorMessage },
        // } = error.response;
        console.log(error.response);

        if (error.response.data.message_kor === undefined) {
          toast.error(
            <div>
              오류가 발생하였습니다.
              <br />
              관리자에게 문의하여 주세요
            </div>,
            {
              toastId: kwargs.url,
            }
          );
        } else {
          toast.error(
            error.response.data.message_kor +
              " (" +
              error.response.data.code +
              ")",
            { toastId: kwargs.url }
          );
        }

        if ([404, 405].includes(error.response.status)) {
        } else if (error.response.status > 400 && error.response.status < 500) {
          // showNotification({
          //   type: 'error',
          //   message: '인증 오류',
          //   description: `에러) ${errorMessage}`,
          // });
          dispatch(actions.logout());
          navigate("/login", { replace: true });
        }
      }
    } catch {
      console.log("::Api Error::");
    }
  }

  return [state, fetch];
};
