import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./columnLayout";
import noimg from "../../../images/noimg.png";
const ImgShowPop = ({ imgUrl, onResolve }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {}, []);

  const handleClose = (returnData) => {
    onResolve(returnData);
    setShow(false);
  };

  const errorImg = (e) => {
    e.currentTarget.src = noimg;
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>이미지 보기 팝업</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img
              src={imgUrl}
              alt="img"
              onError={errorImg}
              width={"800px"}
              height={"800px"}
              object-fit={"scale-down"}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {"닫기"}
          </Button>
          {/* <Button variant="primary" onClick={() => handleClose(true)}>
            {"확인"}
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalImgShowPop = createModal(ImgShowPop);

export default ImgShowPop;
