import React, { useCallback, useState } from "react";
import moment from "moment";

const SearchBar = (props) => {
  // SerachBar 기본 필드
  const [searchValues, setSearchValues] = useState({
    userId: "",
    useHisCntn: "",
    startDt: moment().format("YYYY-MM-DD"),
    endDt: moment().format("YYYY-MM-DD"),
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    let copyMap = searchValues;
    copyMap[name] = value;
    setSearchValues(copyMap);
    props.setSearchParameters(searchValues);
  });

  return (
    <>
      <div className="searchdata">
        <ul>
          <li className="date">
            <div className="divide50">
              <span className="dot">⦁</span> 기록일자
            </div>
            <div className="flex50">
              <input
                type="date"
                name="startDt"
                onChange={handleChangeField}
                className="datainput"
                value={searchValues.startDt}
              />
              &nbsp;~&nbsp;
              <input
                type="date"
                name="endDt"
                onChange={handleChangeField}
                className="datainput"
                value={searchValues.endDt}
              />
            </div>
          </li>
          <li>
            <div className="divide50">
              <span className="dot">⦁</span> 사용자 ID
            </div>
            <input
              type="text"
              className="datainput"
              name="userId"
              onChange={handleChangeField}
            />
          </li>
          <li>
            <div className="divide50">
              <span className="dot">⦁</span> 로그내용
            </div>
            <input
              type="text"
              className="datainput"
              name="useHisCntn"
              onChange={handleChangeField}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(SearchBar);
