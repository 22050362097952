import React from "react";
import useAppContext from "../Reducers";
import { Navigate, useLocation } from "react-router";

export const DynamicRouteMobile = ({ children }) => {
  const {
    store: { isAuthenticated },
  } = useAppContext();
  let location = useLocation();

  return isAuthenticated ? (
    //<Layout children={children} />
    <div children={children} />
  ) : (
    <Navigate to="/loginm" state={{ from: location }} />
  );
};
