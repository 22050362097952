export const INITIAL_FIELD_RULES = {
  authId: {
    rules: [
      {
        required: true,
        message: "권한아이디는 필수조건입니다.",
      },
      {
        max_length: 10,
        message: "권한아이디는 최대 10글자까지 입력 가능합니다.",
      },
    ],
  },
  authNmKor: {
    rules: [
      {
        required: true,
        message: "권한명은 필수조건입니다.",
      },
      {
        max_length: 100,
        message: "권한명은 최대 50글자까지 입력 가능합니다.",
      },
    ],
  },
  useYn: {
    rules: [
      {
        required: true,
        message: "사용여부 필수조건입니다.",
      },
    ],
  },
};
