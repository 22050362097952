export const columnLayout = (onRowButtonClick) => {
  const gridColumnLayOut = [
    {
      dataField: "statusNm",
      headerText: "승인",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
      renderer: {
        type: "ButtonRenderer",
        labelText: "승인",
        onClick: onRowButtonClick,
        visibleFunction: function (
          rowIndex,
          columnIndex,
          value,
          item,
          dataField
        ) {
          console.log(item);
          console.log(dataField);
          // 행 아이템의 name 이 운송상태가 등록으로 되어 있으면  라면 버튼 표시
          if (item.state.indexOf("완료") >= 0) {
            return false;
          }
          // 행 아이템의 name 이 운송상태가 등록으로 되어 있으면  라면 버튼 표시
          if (item.rVndrCd === "") {
            return false;
          }
          return true;
        },
      },
    },
    {
      dataField: "state",
      headerText: "승인종류",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rSystemCd",
      headerText: "시스템구분코드",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rVndrCd",
      headerText: "공급업체 코드",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rVndrNm",
      headerText: "공급업체명",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rRvndrCd",
      headerText: "실공급업체 코드",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rRvndrNm",
      headerText: "실공급업체명",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lLoadAreaCd",
      headerText: "상차지 코드",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lLoadAreaNm",
      headerText: "상차지명",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lAddr",
      headerText: "상차지주소",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lAddrDesc",
      headerText: "상차지주소상세",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rAddr",
      headerText: "주소",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rAddrDesc",
      headerText: "주소상세",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rLatt",
      headerText: "위도",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rLont",
      headerText: "경도",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rBsnsNo",
      headerText: "사업자번호",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "regDt",
      headerText: "등록일",
      formatString: "yyyy-mm-dd hh:MM:ss",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },

    {
      dataField: "rTelNo",
      headerText: "전화번호",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },

    {
      dataField: "rMngrNm",
      headerText: "관리자",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rMngrTel",
      headerText: "관리자 전화번호",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rStatusCd",
      headerText: "실공급업체승인여부",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rStatusCdNm",
      headerText: "실공급업체승인여부",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "rNote",
      headerText: "비고",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lSystemCd",
      headerText: "시스템구분코드",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lRvndrCd",
      headerText: "실공급업체 코드",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lRvndrNm",
      headerText: "실공급업체명",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lLatt",
      headerText: "위도",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "LLont",
      headerText: "경도",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },

    {
      dataField: "lStatusCd",
      headerText: "상차지승인여부",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lStatusCdNm",
      headerText: "상차지승인여부",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "lNote",
      headerText: "비고",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "spDist",
      headerText: "거리(신평)",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "nsDist",
      headerText: "거리(녹산)",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "gpDist",
      headerText: "거리(구평)",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
  ];
  return gridColumnLayOut;
};
