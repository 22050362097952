export const columnLayout = [
  {
    dataField: "custCd",
    headerText: "custCd",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "custNm",
    headerText: "거래처명",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "carFullNo",
    headerText: "차량번호",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "carNo",
    headerText: "차량번호",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "drvNm",
    headerText: "기사명",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "drvTel",
    headerText: "전화번호",
    visible: true,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
];
