export const INITIAL_FIELD_RULES = {
  menuId: {
    rules: [
      {
        required: true,
        message: "메뉴아이디는 필수조건입니다.",
      },
      {
        max_length: 10,
        message: "이 필드의 글자 수가 10 이하인지 확인하십시오.",
      },
    ],
  },
  menuNmKor: {
    rules: [
      {
        required: true,
        message: "메뉴명은 필수조건입니다.",
      },
    ],
  },
  menuLvl: {
    rules: [
      {
        required: true,
        message: "레벨은 필수조건입니다.",
      },
    ],
  },
  sortSeq: {
    rules: [
      {
        required: true,
        message: "정렬은 필수조건입니다.",
      },
    ],
  },
  useYn: {
    rules: [
      {
        required: true,
        message: "사용여부 필수조건입니다.",
      },
    ],
  },
};
