import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../utils/api";
import useAppContext from "../../../utils/Reducers";
import { useAxiosWithAuthorization } from "../../../utils/api";
import {
  setStorageItem,
  getStorageItem,
} from "../../../hooks/Storage/useLocalStorage";

const ButtonBar = (props) => {
  //const title = props.menuInfo.menuNmKor;
  //  "[" + props.menuInfo.menuNmEng + "] " + props.menuInfo.menuNmKor;
  const {
    store: { userInfo },
  } = useAppContext();
  //사용자메뉴정보 검색 URL
  const SERARCH_MENU_AUTH_URL = "/common/menu/searchMenuAuthInfo";
  const [menuAuth, setMenuAuth] = useState({});

  const [, fetchMenuAuthData] = useAxiosWithAuthorization(
    {
      url: SERARCH_MENU_AUTH_URL,
    },
    { manual: true }
  );

  const [favorite, setFavorite] = useState("");

  const searchMenuAuthInfo = async () => {
    console.log("start searchMenuAuthInfo");

    const {
      data: { data },
    } = await fetchMenuAuthData({
      params: {
        userId: userInfo.userId,
        menuId: props?.menuInfo?.menuId,
      },
    });

    console.log("searchMenuAuthInfo data: ", data);

    setMenuAuth(data[0]);
  };

  useEffect(() => {
    console.log(props?.menuInfo);
    searchMenuAuthInfo();

    //초기 Local Storage에 저장되어 있는 즐겨찾기 메뉴중 현재 메뉴가 있는지 체크
    let favorList = getStorageItem("favorites");
    if (favorList !== undefined) {
      const foundMenu = favorList.find(
        (element) => element.menuId === props?.menuInfo?.menuId
      );

      //현재 메뉴가 있으면 즐겨찾기 상태를 on
      if (foundMenu !== undefined) {
        setFavorite("on");
      }
    }
  }, []);

  const onClickFavorite = () => {
    let favorMenuList = getStorageItem("favorites");
    //console.log(favorMenuList);
    if (favorMenuList === undefined) {
      favorMenuList = [];
    }

    if (favorite === "") {
      setFavorite("on");
      favorMenuList.push(props?.menuInfo);
      setStorageItem("favorites", favorMenuList);
    } else {
      let menuList = favorMenuList.filter(
        (element) => element.menuId !== props?.menuInfo?.menuId
      );
      setFavorite("");
      setStorageItem("favorites", menuList);
    }
  };
  return (
    <>
      <div className="flexible_button_box">
        <div className="title">
          <h2>
            {props?.menuInfo?.menuNmKor}
            <button
              type="button"
              className={"page_favorite " + favorite}
              onClick={onClickFavorite}
            >
              즐겨찾기
            </button>
          </h2>
        </div>
        <div className="btnbox">
          <ul className="select_db">
            {menuAuth && (
              <>
                {props.add2AuthYn === true ? (
                  <li>
                    <button
                      type="button"
                      className={
                        props.add2AuthBtnNm === "배차취소"
                          ? "btn_greystyle1"
                          : "btn_greystyle"
                      }
                      onClick={props.handleMasterAdd2}
                    >
                      {props.add2AuthBtnNm}
                    </button>
                  </li>
                ) : (
                  <></>
                )}
                {props.add1AuthYn === true ? (
                  <li>
                    <button
                      type="button"
                      className={
                        props.add1AuthBtnNm === "배차취소"
                          ? "btn_greystyle1"
                          : "btn_greystyle"
                      }
                      onClick={props.handleMasterAdd1}
                    >
                      {props.add1AuthBtnNm}
                    </button>
                  </li>
                ) : (
                  <></>
                )}
                {props.isInitButton === true ? (
                  <li>
                    <button
                      type="button"
                      className="btn_greystyle refresh"
                      onClick={props.handleMasterInit}
                    >
                      초기화
                    </button>
                  </li>
                ) : (
                  <></>
                )}

                {menuAuth.searchAuthYn === "Y" ? (
                  props.handleMasterSearch !== undefined ? (
                    <li>
                      <button
                        type="button"
                        className="btn_greystyle sch"
                        onClick={props.handleMasterSearch}
                      >
                        검색
                      </button>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {menuAuth.newAuthYn === "Y" ? (
                  props.handleMasterNew !== undefined ? (
                    <li>
                      <button
                        type="button"
                        className="btn_greystyle add"
                        onClick={props.handleMasterNew}
                      >
                        추가
                      </button>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {menuAuth.delAuthYn === "Y" ? (
                  props.handleMasterDelete !== undefined ? (
                    <li>
                      <button
                        type="button"
                        className="btn_greystyle del"
                        onClick={props.handleMasterDelete}
                      >
                        삭제
                      </button>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {menuAuth.saveAuthYn === "Y" ? (
                  props.handleMasterSave !== undefined ? (
                    <li>
                      <button
                        type="button"
                        className="btn_greystyle save"
                        onClick={props.handleMasterSave}
                      >
                        저장
                      </button>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {menuAuth.excelAuthYn === "Y" ? (
                  props.handleMasterExcel !== undefined ? (
                    <li>
                      <button
                        type="button"
                        className="btn_greystyle"
                        onClick={props.handleMasterExcel}
                      >
                        엑셀
                      </button>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {menuAuth.printAuthYn === "Y" ? (
                  props.handleMasterPrint !== undefined ? (
                    <li>
                      <button
                        type="button"
                        className="btn_greystyle"
                        onClick={props.handleMasterPrint}
                      >
                        출력
                      </button>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </>
            )}

            {props.isGridSort === true ? (
              <li>
                <button
                  type="button"
                  className="btn_greystyle"
                  onClick={props.handleGridSorting}
                >
                  화면정렬저장
                </button>
              </li>
            ) : (
              <></>
            )}
            {props.isGridSort === true ? (
              <li>
                <button
                  type="button"
                  className="btn_greystyle"
                  onClick={props.handleGridSortingCancel}
                >
                  화면정렬취소
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ButtonBar;
