import React, { useCallback } from "react";

// SerachBar 기본 필드
const INITIAL_SEARCH_FIELDS = {
	mastCd: "",
	cdNmKor: "",
};

// SearchField Validate Rules
const INITIAL_FIELD_RULES = {
	mastCd: {
		rules: [
			{
				max_length: 4,
				message: "공통코드는 최대 10글자까지 입력 가능합니다.",
			},
		],
	},
	cdNmKor: {
		rules: [
			{
				max_length: 100,
				message: "코드명는 최대 50글자까지 입력 가능합니다.",
			},
		],
	},
};

const SearchBar = (props) => {
	const handleChangeField = useCallback((event) => {
		const { name, value } = event.target;

		INITIAL_SEARCH_FIELDS[name] = value;
		props.setSearchParameters(INITIAL_SEARCH_FIELDS);
	});

	return (
		<>
			<div className="searchdata">
				<ul>
					<li>
						<div className="divide50">
							<span className="dot">⦁</span> 공통코드
						</div>
						<input
							type="text"
							className="datainput"
							name="mastCd"
							onChange={handleChangeField}
						/>
					</li>
					<li>
						<div className="divide50">
							<span className="dot">⦁</span> 공통코드명{" "}
						</div>
						<input
							type="text"
							className="datainput"
							name="cdNmKor"
							onChange={handleChangeField}
						/>
					</li>
				</ul>
			</div>
		</>
	);
};

export default SearchBar;
