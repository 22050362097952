import React, { useState, useEffect, useCallback, useRef } from "react";
import SearchBar from "./SearchBar";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import ButtonBar from "../../../components/Layout/ButtonBar";
import { useAxiosWithAuthorization } from "../../../utils/api";
import { ModalConfirm } from "../../../components/Confirm";
import {
  createAuthColumnData,
  createGroupColumnData,
  createAuthGroupColumnData,
} from "./conlumnLayout";
import { saveGridCheckData } from "../../../utils/validators";
import useAppContext from "../../../utils/Reducers";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";

const AuthGroupMng = (props) => {
  const myGrid = useRef();
  const myGridAuthGroup = useRef();
  const myGridGroup = useRef();

  const {
    store: { userInfo, menuKey },
  } = useAppContext();

  useEffect(() => {
    const auiAuthGrid = myGrid.current;
    const auiAuthGroupGrid = myGridAuthGroup.current;
    const auiGroupGrid = myGridGroup.current;

    auiAuthGrid.resize("100%", "100%");
    auiAuthGroupGrid.resize("100%", 300);
    auiGroupGrid.resize("100%", 250);
  }, [menuKey]);

  //그리드 초기 설정
  useEffect(() => {
    const auiAuthGrid = myGrid.current;

    auiAuthGrid.bind("cellClick", function (event) {
      searchAuthMenuList(event.item.authId);
    });

    handleGroupSearch();
  }, []);

  const [primaryParameters, setPrimaryParameters] = useState({
    schAuthId: "",
    schAuthNm: "",
  });
  //권한정보 검색 URL
  const SERARCH_AUTH_URL = "/common/auth/searchAuthList";
  //권한별메뉴정보 검색 URL
  const SERARCH_AUTH_GROUP_URL = "/common/auth/searchAuthGroupList";
  //권한별메뉴정보 저장 URL
  const SAVE_AUTH_GROUP_URL = "/common/auth/saveAuthGroupList";
  //권한별메뉴정보 삭제 URL
  const DELETE_AUTH_GROUP_URL = "/common/auth/deleteAuthGroupList";
  //메뉴정보 검색 URL
  const SERARCH_GROUP_URL = "/common/group/searchGroupList";

  const [, fetchData] = useAxiosWithAuthorization(
    {
      url: SERARCH_AUTH_URL,
    },
    { manual: true }
  );

  const [groupFieldValues, setGroupFieldValues] = useState({
    schGroupId: "",
    schGroupNm: "",
  });

  const handleGroupSearch = async () => {
    const auiGroupGrid = myGridGroup.current;

    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_GROUP_URL,
      params: groupFieldValues,
    });

    auiGroupGrid.setGridData(data);
  };

  const handleMasterSearch = async () => {
    const auiAuthGrid = myGrid.current;

    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_AUTH_URL,
      params: primaryParameters,
    });
    auiAuthGrid.setGridData(data);
  };

  const searchAuthMenuList = async (authId) => {
    const auiAuthGroupGrid = myGridAuthGroup.current;

    const {
      data: { data },
    } = await fetchData({
      url: SERARCH_AUTH_GROUP_URL,
      params: { schAuthId: authId },
    });

    auiAuthGroupGrid.setGridData(data);
  };

  const handleMasterDelete = async () => {
    const auiAuthGroupGrid = myGridAuthGroup.current;

    auiAuthGroupGrid.removeRow("selectedIndex");
  };
  const handleMasterSave = async () => {
    if (
      await ModalConfirm({
        title: "저장",
        description: "데이터를 저장하시겠습니까?",
      })
    ) {
      const auiAuthGrid = myGrid.current;
      const auiAuthGroupGrid = myGridAuthGroup.current;

      const addItems = auiAuthGroupGrid.getAddedRowItems();
      const editItems = auiAuthGroupGrid.getEditedRowItems();
      const removeItems = auiAuthGroupGrid.getRemovedItems();
      const menuId = menuKey;
      let param = saveGridCheckData(addItems, editItems, removeItems, menuId);

      const {
        data: { data },
      } = await fetchData({
        url: SAVE_AUTH_GROUP_URL,
        data: param,
        method: "POST",
      });

      const authItem = auiAuthGrid.getSelectedItems()[0].item;

      searchAuthMenuList(authItem.authId);
    }
  };

  //권한별메뉴 그리드에 검색한 메뉴 추가
  const handleGroupAuthAdd = () => {
    const auiAuthGrid = myGrid.current;
    const auiAuthGroupGrid = myGridAuthGroup.current;
    const auiGroupGrid = myGridGroup.current;

    const groupItem = auiGroupGrid.getSelectedItems()[0].item;
    console.log(groupItem);
    const authItem = auiAuthGrid.getSelectedItems()[0].item;
    console.log(authItem);

    var rows = auiAuthGroupGrid.getRowsByValue("groupId", groupItem.groupId);
    console.log(rows);
    if (rows.length > 0) {
      ModalConfirm({
        title: "그룹존재",
        description:
          "선택한 그룹은 그리드에 있습니다. 다시 선택하시기 바랍니다. ",
        type: "alert",
      });
    } else {
      groupItem.authId = authItem.authId;
      auiAuthGroupGrid.addRow(groupItem, "last");
    }
  };

  //메뉴검색 항목이 변경될때 사용되는 함수
  const handleGroupChangeField = useCallback((event) => {
    const { name, value } = event.target;

    setGroupFieldValues({ ...groupFieldValues, [name]: value });
  });

  return (
    <>
      <ButtonBar
        menuInfo={props.menuInfo}
        handleMasterSearch={handleMasterSearch}
        handleMasterDelete={handleMasterDelete}
        handleMasterSave={handleMasterSave}
      />
      <SearchBar setSearchParameters={setPrimaryParameters} />

      <div className="grid_wrap col_2">
        <div className="half">
          <AUIGrid
            ref={myGrid}
            columnLayout={createAuthColumnData()}
            gridProps={defaultGridProps}
            height={626}
            width="100%"
          />
        </div>
        <div className="half">
          <div className="row_2">
            <AUIGrid
              ref={myGridAuthGroup}
              columnLayout={createAuthGroupColumnData()}
              gridProps={defaultGridProps}
            />

            <div className="searchdata" style={{ marginTop: "10px" }}>
              <ul className="displays">
                <li style={{ width: "180px" }}>
                  <div style={{ width: "80px" }}>
                    <span className="dot">⦁</span>
                    그룹아이디
                  </div>
                  <input
                    type="text"
                    className="datainput"
                    name="schGroupId"
                    style={{ width: "100px" }}
                    onChange={handleGroupChangeField}
                  />
                </li>
                <li style={{ width: "180px" }}>
                  <div style={{ width: "80px" }}>
                    <span className="dot">⦁</span> 그룹명
                  </div>
                  <input
                    type="text"
                    className="datainput"
                    name="schGroupNm"
                    style={{ width: "100px" }}
                    onChange={handleGroupChangeField}
                  />
                </li>
                <li
                  style={{
                    width: "auto",
                    float: "right",
                    paddingRight: "10px",
                  }}
                >
                  <button
                    type="button"
                    className="btn_greystyle"
                    onClick={handleGroupSearch}
                  >
                    검색
                  </button>
                  <button
                    type="button"
                    className="btn_greystyle"
                    onClick={handleGroupAuthAdd}
                    style={{ marginLeft: "5px" }}
                  >
                    추가
                  </button>
                </li>
              </ul>
            </div>
            <AUIGrid
              ref={myGridGroup}
              columnLayout={createGroupColumnData()}
              gridProps={defaultGridProps}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthGroupMng;
