import {
  getStorageItem,
  setStorageItem,
} from "../hooks/Storage/useLocalStorage";
import React, { createContext, useContext, useReducer } from "react";

const AppStore = {
  isAuthenticated: false, //boolean;
  Authorization: "",
  // NOTE: user 정보
  userInfo: {},
  // NOTE: menu 관련
  menuKey: "",
  topMenuKey: "",
  previousMenuKey: "",
  menuList: [],
  menuActionList: [],
  menuOriginList: [],
  menuPath: "",
  menuInfo: {},
  tabSize: 0,
  // NOTE: 테마관련
  themeMode: "type01", // 'type05' | 'type04' | 'type03' | 'type02' | 'type01';
};

const AppContext = createContext({
  AppStore: {},
  dispatch: () => {},
});

const reducer = (previousState = AppStore, action) => {
  const { type } = action;

  if (type === SET_TOKEN) {
    const { payload: jwtToken } = action;
    const newState = {
      ...previousState,
      Authorization: `Bearer ${jwtToken}`,
      isAuthenticated: true,
    };
    setStorageItem("jwtToken", jwtToken);
    return newState;
  } else if (type === DELETE_TOKEN) {
    const newState = {
      ...previousState,
      Authorization: "",
      isAuthenticated: false,
    };
    setStorageItem("jwtToken", "");
    return newState;
  } else if (type === SET_MENU_KEY) {
    const { payload: menuKey } = action;
    const newState = { ...previousState, menuKey };
    return newState;
  } else if (type === SET_TOPMENU_KEY) {
    const { payload: topMenuKey } = action;
    const newState = { ...previousState, topMenuKey };
    return newState;
  } else if (type === SET_PREVIOUS_MENU_KEY) {
    const { payload: previousMenuKey } = action;
    const newState = { ...previousState, previousMenuKey };
    return newState;
  } else if (type === SET_MENU_INFO) {
    const { payload: menuInfo } = action;
    const newState = { ...previousState, menuInfo: menuInfo };
    return newState;
  } else if (type === SET_MENU_PATH) {
    const { payload: menuPath } = action;
    const newState = { ...previousState, menuPath };
    return newState;
  } else if (type === SET_MENU_LIST) {
    const { payload: menuList } = action;
    const newState = { ...previousState, menuList };
    return newState;
  } else if (type === SET_MENU_ORIGIN_LIST) {
    const { payload: menuOriginList } = action;
    const newState = { ...previousState, menuOriginList };
    return newState;
  } else if (type === GET_MENU_ORIGIN_LIST) {
    const { payload: menuOriginList } = action;
    return menuOriginList;
  } else if (type === SET_MENU_ACTION_LIST) {
    const { payload: menuActionList } = action;
    const newState = { ...previousState, menuActionList };
    return newState;
  } else if (type === GET_MENU_ACTION_LIST) {
    const { payload: menuActionList } = action;
    return menuActionList;
  } else if (type === GET_TAB_SIZE) {
    const { payload: tabSize } = action;
    return tabSize;
  } else if (type === SET_TAB_SIZE) {
    const { payload: tabSize } = action;
    const newState = {
      ...previousState,
      tabSize,
    };
    return newState;
  } else if (type === SET_USER_INFO) {
    const { payload: userInfo } = action;
    const newState = {
      ...previousState,
      userInfo,
    };
    setStorageItem("userInfo", userInfo);
    return newState;
  } else if (type === LOGOUT) {
    const newState = {
      ...previousState,
      isAuthenticated: false, //boolean;
      Authorization: "",
      // NOTE: user 정보
      userInfo: {},
      // NOTE: menu 관련
      menuKey: "",
      topMenuKey: "",
      previousMenuKey: "",
      originMenuList: [],
      menuList: [],
      menuActionList: [],
      menuPath: "",
      menuInfo: {},
    };
    setStorageItem("jwtToken", "");
    setStorageItem("userInfo", "");
    return newState;
  } else if (type === "SET_THEME") {
    const { payload: themeMode } = action;
    setStorageItem("theme", themeMode);
    const newState = {
      ...previousState,
      themeMode,
    };

    return newState;
  }

  return previousState;
};

export const AppProvider = ({ children, AppStore }) => {
  const jwtToken = getStorageItem("jwtToken", "");
  const themeMode = getStorageItem("theme", "type01");
  const userInfo = getStorageItem("userInfo", "");

  const [store, dispatch] = useReducer(reducer, {
    ...AppStore,
    isAuthenticated: jwtToken.length > 0,
    Authorization: jwtToken.length > 0 ? `Bearer ${jwtToken}` : "",
    themeMode,
    menuList: [],
    menuOriginList: [],
    menuActionList: [],
    userInfo,
  });

  return (
    <AppContext.Provider value={{ store, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default function useAppContext() {
  return useContext(AppContext);
}

// Actions
const SET_TOKEN = "APP/SET_TOKEN";
const DELETE_TOKEN = "APP/DELETE_TOKEN";

// Menu
const SET_MENU_KEY = "APP/SET_MENU_KEY";
const SET_TOPMENU_KEY = "APP/SET_TOPMENU_KEY";

const SET_MENU_INFO = "APP/SET_MENU_INFO";
const SET_MENU_PATH = "APP/SET_MENU_PATH";
const SET_MENU_LIST = "APP/SET_MENU_LIST";
const SET_MENU_ORIGIN_LIST = "APP/SET_MENU_ORIGIN_LIST";
const GET_MENU_ORIGIN_LIST = "APP/GET_MENU_ORIGIN_LIST";

const SET_MENU_ACTION_LIST = "APP/SET_MENU_ACTION_LIST";
const GET_MENU_ACTION_LIST = "APP/GET_MENU_ACTION_LIST";

const SET_PREVIOUS_MENU_KEY = "APP/SET_PREVIOUS_MENU_KEY";

// 사용자 정보
const SET_USER_INFO = "APP/SET_USER_INFO";
// 탭 사이즈
const GET_TAB_SIZE = "APP/GET_TAB_SIZE";
const SET_TAB_SIZE = "APP/SET_TAB_SIZE";
const LOGOUT = "APP/LOGOUT";
const SET_THEME = "APP/SET_THEME";

// Action Creators
export const actions = {
  setToken: (token) => ({ type: SET_TOKEN, payload: token }),
  deleteToken: () => ({ type: DELETE_TOKEN }),

  setMenuKey: (menuKey) => ({ type: SET_MENU_KEY, payload: menuKey }),

  setTopMenuKey: (topMenuKey) => ({
    type: SET_TOPMENU_KEY,
    payload: topMenuKey,
  }),

  setPreviousMenuKey: (previousMenuKey) => ({
    type: SET_PREVIOUS_MENU_KEY,
    payload: previousMenuKey,
  }),

  setMenu: (menuInfo) => ({ type: SET_MENU_INFO, payload: menuInfo }),
  setMenuPath: (menuPath) => ({
    type: SET_MENU_PATH,
    payload: menuPath,
  }),
  setMenuOriginList: (menuOriginList) => ({
    type: SET_MENU_ORIGIN_LIST,
    payload: menuOriginList,
  }),
  setMenuList: (menuList) => ({ type: SET_MENU_LIST, payload: menuList }),
  setMenuActionList: (menuList) => ({
    type: SET_MENU_ACTION_LIST,
    payload: menuList,
  }),

  // 사용자 정보
  setUserInfo: (userInfo) => ({
    type: SET_USER_INFO,
    payload: userInfo,
  }),

  // 탭사이즈 정보
  setTabSize: (tabSize) => ({
    type: SET_TAB_SIZE,
    payload: tabSize,
  }),

  logout: () => ({ type: LOGOUT }),

  // 테마
  setTheme: (themeMode) => ({
    type: SET_THEME,
    payload: themeMode,
  }),
  getMenuActionList: () => ({
    type: GET_MENU_ACTION_LIST,
  }),
};
