export const INITIAL_FIELD_RULES = {
  groupId: {
    rules: [
      {
        required: true,
        message: "그룹아이디는 필수조건입니다.",
      },
    ],
  },

  groupNm: {
    rules: [
      {
        required: true,
        message: "그룹명은 필수조건입니다.",
      },
    ],
  },

  useYn: {
    rules: [
      {
        required: true,
        message: "사용여부 필수조건입니다.",
      },
    ],
  },
};
