import { Navbar, Nav, Container, Button } from "react-bootstrap";
import hkslogo from "../../../../images/color_logo.png";
import ham1 from "../../../../images/ham1.png";
import hkslogo_o from "../../../../images/color_logo.png";

import useAppContext, { actions } from "../../../../utils/Reducers";

const TopMenuBar = (props) => {
  const {
    store: { topMenuKey, menuList, menuOriginList, menuInfo, userInfo },
    dispatch,
  } = useAppContext();

  const handleClick = (menuId) => {
    dispatch(actions.setTopMenuKey(menuId));
  };

  if (topMenuKey === undefined) {
    dispatch(actions.setTopMenuKey("0000000100"));
  }

  const handleLogout = (e) => {
    dispatch(actions.logout());
  };

  //_this : 적용시킬 element
  //_class : 적용시킬 class
  //_type : 선택 타입
  function menuTab(_this, _class) {
    selectClass(_this, _class);
    toggleClass("menuList", "favorite");
  }

  //_index : 인덱스
  //_contents : 콘텐츠 id
  //_this : 적용시킬 element
  //_class : 적용시킬 class

  function selectTab(_index, _contents, _this, _class, _type) {
    var mobileTab = document.getElementById("mMenuTabs");
    var pcTab = document.getElementById("pcMenuTabs");

    selectClass(_this, _class);
    //pc또는모바일도 같이 활성화한다.
    if (_type == "pc") {
      selectClass(mobileTab.children[_index], _class);
    } else {
      selectClass(pcTab.children[_index], _class);
    }

    var contents = document.getElementById(_contents);
    selectClass(contents.children[_index], _class);
  }

  //_this : 적용시킬 element
  //_class : 적용시킬 class
  //_toggle : 토글여부
  function selectClass(_this, _class, _toggle) {
    if (_toggle) {
      if (hasClass(_this, "on") === false) {
        addClass(_this, "on");
      } else {
        removeClass(_this, "on");
      }
    } else {
      if (hasClass(_this, "on") === false) {
        addClass(_this, "on");
      }
    }

    var sb = siblings(_this);
    for (var i = 0; i < sb.length; i++) {
      removeClass(sb[i], "on");
    }
  }

  //_target : 적용시킬 element
  //_class : 적용시킬 class
  function toggleClass(_target, _class) {
    var menu = document.getElementById(_target);
    if (hasClass(menu, _class)) {
      removeClass(menu, _class);
    } else {
      addClass(menu, _class);
    }
  }

  function addClass(element, className) {
    element.className += " " + className;
  }
  function removeClass(element, className) {
    var check = new RegExp("(\\s|^)" + className + "(\\s|$)");
    element.className = element.className.replace(check, " ").trim();
  }

  function hasClass(element, className) {
    if (element.className.indexOf(className) > -1) {
      return true;
    } else {
      return false;
    }
  }

  function siblings(element) {
    var children = element.parentElement.children;
    var tempArr = [];

    for (var i = 0; i < children.length; i++) {
      tempArr.push(children[i]);
    }

    return tempArr.filter(function (e) {
      return e != element;
    });
  }

  return (
    <>
      <div className="menulogo">
        <img src={hkslogo} />
        <div className="box_m">
          <div
            className="ham"
            onClick={(event) => toggleClass("leftmenu", "open")}
          >
            <img src={ham1} />
          </div>
          <div className="mobilelogo">
            <img src={hkslogo_o} />
          </div>
          <div className="select_big_state">
            <p>기준관리</p>
          </div>
        </div>
      </div>
      <div className="box_bigmenu">
        <ul className="select_big" id="pcMenuTabs">
          {menuOriginList && //JSON.stringify(menuData)
            menuOriginList.map((topMenu) =>
              topMenu.menuLvl === 1 ? (
                <li
                  className={topMenu.menuId === topMenuKey ? "on" : ""}
                  key={topMenu.menuId}
                  onClick={(event) => handleClick(topMenu.menuId)}
                >
                  <p>{topMenu.menuNmKor}</p>
                </li>
              ) : (
                ""
              )
            )}
        </ul>

        <ul className="select_user">
          <li>
            <div className="select_big_controller">
              <div className="left">&#10094;</div>
              <div className="right">&#10095;</div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TopMenuBar;
