import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../utils/api";
import useAppContext, { actions } from "../../../utils/Reducers";
import useFieldValues from "../../../utils/useFieldValues";
import styled, { createGlobalStyle } from "styled-components";
import loginLogo from "../../../images/login_logo.png";
import inputIdIcon from "../../../images/input_id.png";
import inputPwIcon from "../../../images/input_pw.png";
import bottomBgIcon from "../../../images/bottom_bg.png";

const Login = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const LS_KEY_ID = "LS_KEY_ID";
  const LS_KEY_PW = "LS_KEY_PW";
  const AUTO_LOGIN_FLAG = "AUTO_LOGIN_FLAG";
  const AUTO_LOGIN_FLAG_2 = "AUTO_LOGIN_FLAG_2";
  const LS_KEY_SAVE_ID_FLAG = "LS_KEY_SAVE_ID_FLAG";
  const [saveIDFlag, setSaveIDFlag] = useState(false);
  const [autoLogin, setAutoLogin] = useState(
    localStorage.getItem("AUTO_LOGIN_FLAG") === "true"
  );
  const [id_pw_save, setIdPwSave] = useState(
    localStorage.getItem("LS_KEY_SAVE_ID_FLAG") === "true"
  );

  const INITIAL_LOGIN_FIELDS = {
    userId: "",
    password: "",
  };
  const [loginFields] = useFieldValues(INITIAL_LOGIN_FIELDS);
  const LOGIN_APP_URL = "/accounts/login";

  useEffect(() => {
    let idFlag = JSON.parse(localStorage.getItem(LS_KEY_SAVE_ID_FLAG));
    setSaveIDFlag(idFlag || false);

    const dataId = localStorage.getItem(LS_KEY_ID) || "";
    const dataPw = localStorage.getItem(LS_KEY_PW) || "";

    if (idFlag) {
      setUserId(dataId);
      setPassword(dataPw);
    }
  }, [id_pw_save]);

  useEffect(() => {
    if (localStorage.getItem("AUTO_LOGIN_FLAG_2") === "true") {
      if (autoLogin && userId && password) {
        submit();
      }
    }
  }, [autoLogin, userId, password]);

  const { dispatch } = useAppContext();
  const submit = async () => {
    loginFields["userId"] = userId;
    loginFields["password"] = password;

    if (!autoLogin) {
      if (userId === "") {
        alert("아이디를 입력하세요");
        return;
      }

      if (password === "") {
        alert("패스워드를 입력하세요");
        return;
      }
    }

    try {
      const response = await axiosInstance.post(LOGIN_APP_URL, loginFields);

      const {
        data: { token, userInfo, code, result },
      } = response;
      console.log(code);
      console.log(result);
      if (code === "400") {
        alert(result);
        return;
      } else {
        dispatch(actions.setToken(token));
        dispatch(actions.setUserInfo(userInfo));

        if (true /* login success */) {
          if (saveIDFlag) localStorage.setItem(LS_KEY_ID, userId);
          if (saveIDFlag) localStorage.setItem(LS_KEY_PW, password);
          if (autoLogin) localStorage.setItem(AUTO_LOGIN_FLAG, autoLogin);
          if (autoLogin) localStorage.setItem(AUTO_LOGIN_FLAG_2, "true");
        }

        navigate("/InHouseCar", { replace: true });
      }
    } catch (error) {
      if (!autoLogin) {
        if (error.response) {
          const { data: errors } = error.response;
          const _responseErrors = [];
          errors.messages &&
            errors.messages.map(({ message }) => {
              Object.entries(message).map(([key, errorMessage]) => {
                _responseErrors.push(errorMessage);
              });
            });

          for (const _responseError of _responseErrors.reverse()) {
            // eslint-disable-next-line no-restricted-globals
            confirm(_responseError);
            return;
          }
        }
      }
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const handleSaveIDFlag = (event) => {
    if (!autoLogin) {
      const isChecked = event.target.checked;
      setSaveIDFlag(isChecked);
      localStorage.setItem(LS_KEY_SAVE_ID_FLAG, isChecked);
    }
  };

  const handleAutoLogin = (event) => {
    const isChecked = event.target.checked;
    setAutoLogin(isChecked);
    localStorage.setItem(AUTO_LOGIN_FLAG, isChecked);
    if (isChecked) {
      setSaveIDFlag(true);
      localStorage.setItem(LS_KEY_SAVE_ID_FLAG, "true");
    }
  };

  return (
    <>
      <GlobalStyle />
      <MainWrapper>
        <LoginContainer>
          <Content>
            <Logo>
              <img src={loginLogo} alt="HKS Korea Steel Logo" />
            </Logo>
            <Title>사내차량조회</Title>

            <InputBox>
              <img src={inputIdIcon} alt="Car Icon" />
              <input
                type="text"
                placeholder="아이디"
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
              />
            </InputBox>

            <InputBox>
              <img src={inputPwIcon} alt="Lock Icon" />
              <input
                type="password"
                placeholder="비밀번호"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyPress={onKeyPress}
              />
            </InputBox>

            <LoginButton type="button" onClick={submit}>
              로그인
            </LoginButton>
            <LoginOptions>
              <label>
                <input
                  type="checkbox"
                  id="id_save"
                  value={password}
                  checked={saveIDFlag}
                  onChange={handleSaveIDFlag}
                />
                ID/PW 저장
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={autoLogin}
                  onChange={handleAutoLogin}
                />
                자동로그인
              </label>
            </LoginOptions>
          </Content>
        </LoginContainer>

        <BottomSection>
          <BottomIcons></BottomIcons>
        </BottomSection>

        <FooterBox>FOR THE BEST STEEL COMPANY</FooterBox>
      </MainWrapper>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Pretendard', sans-serif;
    background-color: white;
    margin: 0;
    padding: 0;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`;

const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 32px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    max-width: 600px;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.div`
  img {
    max-width: 150px;
  }
`;

const Title = styled.h1`
  font-size: 35px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 100px;
`;

const InputBox = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  input {
    width: 100%;
    padding: 12px 12px 10px 48px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    height: 50px;
    font-weight: 500;
    outline: none;
    box-sizing: border-box;
    color: #333;

    &::placeholder {
      color: #cfcfd3;
    }
  }

  img {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  height: 55px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-sizing: border-box;

  &:hover {
    background-color: #ff7043;
  }

  &:active {
    background-color: #e64a19;
  }
`;

const LoginOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;

  label {
    font-size: 16px;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  height: 160px;
  background-color: #eeeff5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

const BottomIcons = styled.div`
  width: 190px;
  height: 40px;
  background-image: url(${bottomBgIcon});
  background-size: 190px 40px;
`;

const FooterBox = styled.div`
  width: 300px;
  background: linear-gradient(to bottom, #f8623a, #cb401b); /* 그라데이션 */
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  border-radius: 3px 3px 0 0;

  @media (min-width: 768px) {
    width: 400px;
  }
`;

export default Login;
