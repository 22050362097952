export const Close = ({ width = 16, height = 16, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="그룹_234" data-name="그룹 234" transform="translate(-433 -55)">
      <circle
        id="타원_1"
        data-name="타원 1"
        cx="8"
        cy="8"
        r="8"
        transform="translate(433 55)"
        fill="#bdc6d4"
      />
      <line
        id="선_112"
        data-name="선 112"
        x1="6"
        y2="6"
        transform="translate(438 60)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      />
      <line
        id="선_113"
        data-name="선 113"
        x2="6"
        y2="6"
        transform="translate(438 60)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
);
