export const INITIAL_FIELD_RULES = {
  rVndrCd: {
    rules: [
      {
        required: true,
        message: "공급업체코드는 필수조건입니다.",
      },
      {
        max_length: 10,
        message: "공급업체코드의 글자 수가 10 이상인지 확인하십시오.",
      },
    ],
  },
  rRvndrNm: {
    rules: [
      {
        required: true,
        message: "실공급업체명은 필수조건입니다.",
      },
    ],
  },
  rAddr: {
    rules: [
      {
        required: true,
        message: "실공급업체 주소1은 필수조건입니다.",
      },
    ],
  },
  rBsnsNo: {
    rules: [
      {
        required: true,
        message: "사업자번호는 필수조건입니다.",
      },
      {
        min_length: 12,
        message: "사업자번호의 글자 수가 12자리 이상인지 확인하십시오.",
      },
    ],
  },
  rTelNo: {
    rules: [
      {
        required: true,
        message: "전화번호는 필수조건입니다.",
      },
      {
        min_length: 12,
        message: "전화번호의 글자 수가 12자리 이상인지 확인하십시오.",
      },
    ],
  },
  rMngrNm: {
    rules: [
      {
        required: true,
        message: "관리자명은 필수조건입니다.",
      },
    ],
  },
  rMngrTel: {
    rules: [
      {
        required: true,
        message: "관리자전화번호는 필수조건입니다.",
      },
      {
        min_length: 12,
        message: "관리자전화번호의 글자 수가 12자리 이상인지 확인하십시오.",
      },
    ],
  },
  rStatusCd: {
    rules: [
      {
        required: true,
        message: "실공급업체 승인여부는 필수조건입니다.",
      },
    ],
  },
  lLoadAreaNm: {
    rules: [
      {
        required: true,
        message: "상차지명은 필수조건입니다.",
      },
    ],
  },
  lAddr: {
    rules: [
      {
        required: true,
        message: "상차지 주소1은 필수조건입니다.",
      },
    ],
  },
  lStatusCd: {
    rules: [
      {
        required: true,
        message: "상차지 승인여부는 필수조건입니다.",
      },
    ],
  },
};
