import { useCallback, useState } from "react";

function useFieldValues(initialFieldValues, props) {
  const [fieldValues, setFieldValues] = useState(initialFieldValues || {});

  const handleChangeField = useCallback(
    (
      event,
      customEvent,
      customName,
      boolean = true,
      previous,
      boolean2 = false
    ) => {
      if (customEvent) {
        setFieldValues((previousFieldValues) => ({
          ...previousFieldValues,
          [event?.target?.name]: event?.target?.value,
          [`_${event?.target?.name}`]: customEvent,
          ...handleFieldValues(
            boolean,
            boolean2,
            previousFieldValues,
            previous
          ),
        }));
      } else {
        setFieldValues((previousFieldValues) => ({
          ...previousFieldValues,
          [event?.target?.name]: event?.target?.value,
          ...handleFieldValues(
            boolean,
            boolean2,
            previousFieldValues,
            previous
          ),
        }));
      }
    },
    []
  );

  const handleFieldValues = (
    boolean,
    boolean2,
    previousFieldValues,
    previous
  ) => {
    return {
      _boolean: boolean,
      _boolean2: boolean2,
      _previous: {
        ...(previousFieldValues &&
          Object.fromEntries(
            Object.entries(previous || previousFieldValues).filter(
              ([code]) => code !== "_previous"
            )
          )),
      },
    };
  };

  const initFieldValues = useCallback(() => {
    const initFields = Object.keys(fieldValues).map((key) => {
      return [key, null];
    });
    setFieldValues(Object.fromEntries(initFields));
  }, []);

  return [fieldValues, handleChangeField, initFieldValues];
}

export default useFieldValues;
