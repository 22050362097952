import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { useAxiosWithAuthorization } from "../../../utils/api";
import AUIGrid from "../../../lib/AuiGrid/AUIGridReact";
import { defaultGridProps } from "../../../components/AuiGrid/gridLayout";
import { columnLayout } from "./columnLayout";

const DeptPop = ({
  callbackFunc,
  searchTxt,
  autoSelect,
  failCallbackFunc,
  onResolve,
}) => {
  const [show, setShow] = useState(true);
  const myGrid = useRef();
  useEffect(() => {
    const auiGrid = myGrid.current;
    auiGrid.resize("100%", "100%");

    auiGrid.bind("cellDoubleClick", function (event) {
      gridCellDBClick(event.item);
    });

    //if (searchTxt !== undefined && searchTxt !== "") {
    searchItem();
    //}
  }, []);

  const gridCellDBClick = (item) => {
    onResolve(true); //왠진 모르지만 이거 안해주면 에러남..
    callbackFunc(item);
    setShow(false);
  };

  const [searchCnt, setSearchCnt] = useState(1);

  const handleClose = (returnData) => {
    if (returnData) {
      const auiGrid = myGrid.current;
      let selItem = auiGrid.getSelectedItems()[0];
      if (selItem) {
        onResolve(returnData); //왠진 모르지만 이거 안해주면 에러남..
        callbackFunc(selItem.item);
      }
    } else {
      onResolve(returnData);
      if (failCallbackFunc !== undefined) {
        failCallbackFunc();
      }
    }

    setShow(false);
  };

  const SEARCH_CUST_URL = "/common/code/searchERPCode";
  const [, fetchCustData] = useAxiosWithAuthorization(
    {
      url: SEARCH_CUST_URL,
    },
    { manual: true }
  );

  const [INITIAL_SEARCH_FIELDS, setSearchFields] = useState({
    mastCd: "DEPT", //팝업 파라메터로 받은것
    searchTxt: searchTxt, //품번/품명 검색어
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;
    //INITIAL_SEARCH_FIELDS[name] = value;
    setSearchFields({ ...INITIAL_SEARCH_FIELDS, [name]: value });
  });

  const searchItem = async () => {
    const auiGrid = myGrid.current;
    auiGrid.showAjaxLoader();
    const {
      data: { data },
    } = await fetchCustData({
      url: SEARCH_CUST_URL,
      params: {
        mastCd: INITIAL_SEARCH_FIELDS.mastCd,
        searchTxt: INITIAL_SEARCH_FIELDS.searchTxt,
      },
    });
    auiGrid.setGridData(data);
    //setFieldValues(initItem);
    auiGrid.removeAjaxLoader();

    if (data.length > 0) {
      const auiGrid = myGrid.current;
      auiGrid.setSelectionByIndex(0, 0);
    }
    setSearchCnt(searchCnt + 1);
    if (
      searchTxt !== undefined &&
      data.length === 1 &&
      autoSelect === true &&
      searchCnt === 1
    ) {
      handleClose(true);
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      searchItem();
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>부서 검색 팝업</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="searchdata">
              <ul>
                <li>
                  <div className="divide50">
                    <font color="grey">⦁</font> 검색어
                  </div>
                  <div className="ip_textandbutton">
                    <div>
                      <input
                        type="text"
                        name="searchTxt"
                        disabled={false}
                        onChange={handleChangeField}
                        className="ip1"
                        value={INITIAL_SEARCH_FIELDS.searchTxt}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={searchItem}
                        className="btn_ip"
                      >
                        검색
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="databox">
                <div style={{ width: "100%", height: "100%" }}>
                  <AUIGrid
                    ref={myGrid}
                    columnLayout={columnLayout}
                    gridProps={defaultGridProps}
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {"닫기"}
          </Button>
          <Button variant="primary" onClick={() => handleClose(true)}>
            {"확인"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ModalDeptSearchPop = createModal(DeptPop);

export default DeptPop;
