import React, { useCallback, useState } from "react";
import SelectBox from "../../../components/SelectBox";
import useAppContext from "../../../utils/Reducers";

const SearchBar = (props) => {
	// SerachBar 기본 필드
	const INITIAL_SEARCH_FIELDS = {
		userId: "",
		nmKor: "",
	};

	const handleChangeField = useCallback((event) => {
		const { name, value } = event.target;

		INITIAL_SEARCH_FIELDS[name] = value;
		console.log(INITIAL_SEARCH_FIELDS);
		props.setSearchParameters(INITIAL_SEARCH_FIELDS);
	});

	return (
		<>
			<div className="searchdata">
				<ul>
					<li>
						<div className="divide50">
							<span className="dot">⦁</span> 사용자아이디
						</div>
						<input
							type="text"
							className="datainput"
							name="userId"
							onChange={handleChangeField}
						/>
					</li>
					<li>
						<div className="divide50">
							<span className="dot">⦁</span> 사용자명
						</div>
						<input
							type="text"
							className="datainput"
							name="nmKor"
							onChange={handleChangeField}
						/>
					</li>
				</ul>
			</div>
		</>
	);
};

export default React.memo(SearchBar);
