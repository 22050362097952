export const columnLayout = (searchBoardInfo) => {
  const gridColumnLayOut = [
    {
      dataField: "boardId",
      headerText: "게시판아이디",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "boardTypeCd",
      headerText: "게시판구분",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "boardTypeCdNm",
      headerText: "게시판구분",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "boardTitle",
      headerText: "제목",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
      renderer: {
        type: "LinkRenderer",
        baseUrl: "javascript", // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          searchBoardInfo(item);
        },
      },
    },
    {
      dataField: "boardContent",
      headerText: "내용",

      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "regId",
      headerText: "등록자아이디",
      visible: false,
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "regNm",
      headerText: "등록자명",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "regDt",
      headerText: "등록일자",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "popupYn",
      headerText: "팝업여부",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
      list: [
        { code: "Y", value: "Y" },
        { code: "N", value: "N" },
      ], //key-value Object 로 구성된 리스트
      keyField: "code", // key 에 해당되는 필드명
      valueField: "value", // value 에 해당되는 필드명
    },
    {
      dataField: "popupStartDt",
      headerText: "팝업게시일자",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
    {
      dataField: "popupEndDt",
      headerText: "팝업종료일자",
      filter: {
        showIcon: true,
        useExMenu: true,
      },
    },
  ];
  return gridColumnLayOut;
};
