import React, { useCallback, useState } from "react";
import SelectBox from "../../../components/SelectBox";
import useAppContext from "../../../utils/Reducers";

const SearchBar = (props) => {
  const getTodayStr = () => {
    let today = new Date();
    let todayStr = today.getFullYear();
    todayStr +=
      today.getMonth() + 1 < 10
        ? "-0" + (today.getMonth() + 1)
        : "-" + (today.getMonth() + 1);
    todayStr +=
      today.getDate() + 1 < 10 ? "-0" + today.getDate() : "-" + today.getDate();
    return todayStr;
  };

  // SerachBar 기본 필드
  const [searchValues, setSearchValues] = useState({
    dt: getTodayStr(), //입차예정일
    carNo: "", //차량번호
  });

  const handleChangeField = useCallback((event) => {
    const { name, value } = event.target;

    console.log(name, value);

    let copyMap = searchValues;
    copyMap[name] = value;
    setSearchValues(copyMap);
    setSearchValues({ ...searchValues, [name]: value });
    props.setSearchParameters(searchValues);

    //console.log(searchValues);
  });

  return (
    <>
      <div className="searchdata">
        <ul>
          <li>
            <div className="divide50">
              <span className="dot">⦁</span> 입차예정일
            </div>
            <input
              type="date"
              name="dt"
              onChange={handleChangeField}
              className="datainput"
              value={searchValues.dt}
            />
          </li>
          <li>
            <div className="divide50">
              <span className="dot">⦁</span> 차량번호
            </div>
            <input
              type="text"
              className="datainput"
              name="carNo"
              onChange={handleChangeField}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(SearchBar);
