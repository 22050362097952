export const columnLayout = [
  {
    dataField: "systemCd",
    headerText: "시스템구분코드",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "vndrCd",
    headerText: "공급업체 코드",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "vndrNm",
    headerText: "공급업체명",
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "addr",
    headerText: "주소",
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "addrDesc",
    headerText: "주소상세",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "latt",
    headerText: "위도",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "lont",
    headerText: "경도",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "bsnsNo",
    headerText: "사업자번호",
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "telNo",
    headerText: "전화번호",
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },

  {
    dataField: "mngrNm",
    headerText: "관리자",
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "mngrTel",
    headerText: "관리자 전화번호",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "vndrTypeCd",
    headerText: "업체 유형",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "vndrTypeCdNm",
    headerText: "업체 유형",
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "profNeedYn",
    headerText: "증빙필요여부 구분",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
  {
    dataField: "useYn",
    headerText: "사용여부",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
    editRenderer: {
      type: "ComboBoxRenderer",
      autoCompleteMode: true, // 자동완성 모드 설정
      matchFromFirst: false, // 처음부터 매치가 아닌 단순 포함되는 자동완성
      list: [
        { code: "Y", value: "사용" },
        { code: "N", value: "미사용" },
      ], //key-value Object 로 구성된 리스트
      keyField: "code", // key 에 해당되는 필드명
      valueField: "value", // value 에 해당되는 필드명
    },
  },
  {
    dataField: "note",
    headerText: "비고",
    visible: false,
    filter: {
      showIcon: true,
      useExMenu: true,
    },
  },
];
